<template>
  <div id="create-user-form-container">
    <ui-card id="card-container" v-shadow.transition="[2, 8]">
      <h3>{{ $t('myAccount.create') }}</h3>
      <ui-form type="|" item-margin-bottom="20" action-align="right">
        <template #default="{ itemClass, subitemClass, actionClass }">
          <ui-form-field :class="itemClass">
            <label>{{ $t('myAccount.currency') }}</label>
            <ui-select fullwidth v-model="preferredCurrency" :options="currencyOptions"></ui-select>
          </ui-form-field>
          <ui-form-field :class="itemClass">
            <ui-textfield input-type="email" outlined fullwidth v-model="formData.email"
              helper-text-id="email-helper-text" :disabled="formLoading">
              {{ $t('myAccount.email') }}
              <template #before>
                <ui-textfield-icon>email</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="email-helper-text" v-model:validMsg="validMsg.email"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="itemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-helper-text"
              v-model="formData.password" :disabled="formLoading">
              {{ $t('myAccount.password') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-helper-text" v-model:validMsg="validMsg.password"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="itemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-confirmar-helper-text"
              v-model="formData.passwordConfirmar" :disabled="formLoading" @keyup.enter="createUser">
              {{ $t('myAccount.passwordConfirm') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-confirmar-helper-text" v-model:validMsg="validMsg.passwordConfirmar">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="itemClass" class="terms">
            <label> {{ $t('myAccount.createDisclosure1') }}
              <router-link to="/aviso-privacidad"> {{ $t('myAccount.createDisclosure2') }}</router-link>
            </label>
          </ui-form-field>
          <div :class="subitemClass">
            <ui-form-field>
              <ui-checkbox v-model="formData.acceptedTerms" input-id="acceptedTerms"></ui-checkbox>
              <label for="acceptedTerms">{{ $t('myAccount.createDisclosure3') }}
                <router-link to="/condiciones-uso">{{ $t('myAccount.createDisclosure4') }}</router-link>
              </label>
            </ui-form-field>
          </div>
          <ui-form-field :class="actionClass">
            <ui-button raised native-type="submit" @click.prevent="validateAndOpenConfirmDialog"
              :disabled="formLoading || !formData.acceptedTerms">{{ $t('myAccount.createUserButton') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">person_add</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
      <error-component v-show="errorCreate" :message="errorMessage" />
    </ui-card>
  </div>
  <ui-dialog v-model="openConfirmCreate" fullscreen>
    <ui-dialog-title> {{ $t('alerts.confirmation') }} </ui-dialog-title>
    <ui-dialog-content>
      <p>
        {{ $t('myAccount.createUserConfirmation1') }}
        <br />
        <strong> {{ formData.email }}</strong>
        <br />
        <strong>NOTA: </strong>{{ $t('myAccount.createUserConfirmation2') }}
      </p>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click.prevent="createUser">{{ $t('alerts.confirmButton') }}</ui-button>
    </ui-dialog-actions>
  </ui-dialog>
</template>
<script>
import { useValidator } from "balm-ui";
import { useMainStore } from "@/stores/main";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import currencies from "@/utils/currencies";
import userApi from "@/api/UserApi";
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';
import i18n from "@/i18n";
import { analytics, logEvent } from "@/utils/firebase"

const validations = [
  {
    key: "email",
    label: i18n.global.t('myAccount.email'),
    validator: "required, tdEmailValidator",
  },
  {
    key: "password",
    label: i18n.global.t('myAccount.password'),
    validator: "tdPassValidator",
  },
  {
    key: "passwordConfirmar",
    label: i18n.global.t('myAccount.passwordConfirm'),
    validator: "repasswordRule",
    repasswordRule: {
      validate(value, data) {
        if (value !== data.password) return false;
        return true;
      },
      message: i18n.global.t('errors.mismatchPassword'),
    },
  },
];

export default {
  name: "CreateUserForm",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      balmUI: useValidator(),
      validations,
      validMsg: {},
      formData: {
        email: "",
        password: "",
        passwordConfirmar: "",
        acceptedTerms: false,
      },
      currencyOptions: [...currencies.getSupportedCurrencies()],
      preferredCurrency: 0,
      openConfirmCreate: false,
      formLoading: false,
      errorCreate: false,
      errorMessage: "",
    };
  },
  mounted() {
    const mainStore = useMainStore();
    this.preferredCurrency = mainStore.defaultLanguage === 'es' ? 0 : 1
  },
  methods: {
    validateAndOpenConfirmDialog() {
      let result = this.balmUI.validate(this.formData);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {
        this.openConfirmCreate = true;
      }
    },
    async createUser() {

      this.openConfirmCreate = false;

      window.grecaptcha.enterprise.ready(async () => {

        try {

          this.formLoading = true;
          this.errorCreate = false;

          const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.CREATE_USER });
          await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.CREATE_USER, token, process.env.VUE_APP_RECAPTCHA_KEY)

          const userData = await userApi.create({
            preferredCurrency: this.preferredCurrency === 0 ? 'mxn' : 'usd',
            correo: this.formData.email.trim().toLowerCase(),
            password: this.formData.password,
          });

          logEvent(analytics, 'sign_up', {
            type: 'Ticket Dancer'
          })

          this.formLoading = false;
          this.resetForm();

          this.$alert({
            title: this.$t('alerts.important'),
            message: this.$t('myAccount.createUserNotification', [userData.correo]),
            state: "info",
          });
        } catch (error) {
          //console.log(error);
          this.formLoading = false;
          this.errorMessage = error.mensaje;
          this.errorCreate = true;
        }
      });
    },
    resetForm() {
      this.formData.email = "";
      this.formData.password = "";
      this.formData.passwordConfirmar = "";
      this.formData.acceptedTerms = false;
    },
  },
};
</script>
<style scoped>
h2 {
  text-align: center;
  margin-top: 2em;
}

h3 {
  text-align: center;
  margin-bottom: 2em;
}

#create-user-form-container {
  max-width: 600px;
  margin: auto;
}

.terms {
  text-align: justify;
}

#card-container {
  padding: 2em;
  margin-top: 3em;
}
</style>