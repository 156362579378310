<template>
    <div>
        <ui-card elevated class="main-card">
            <ui-card-content class="main-card-content" @click.prevent="goToEventDetail">
                <img :src="withEvent.urlPrincipalImage" />
                <h1> {{ withEvent.name }} </h1>
                <p v-html="withEvent.description">
                </p>
                <label v-show="withEvent.msiEnabled" class="msi">
                    {{ $t('plans.msiAll') }}
                </label>
            </ui-card-content>
            <ui-card-actions class="main-card-actions">
                <ui-button raised class="main-card-button" id="registry-button" @click.prevent="goToSelectRegisterType">
                    {{ $t('registries.selectRegistriesButton') }}
                    <template #after>
                        <ui-icon>request_quote</ui-icon>
                    </template>
                </ui-button>
                <ui-button raised class="main-card-button" @click.prevent="goToSelectTicket">
                    {{ $t('events.selectTicketsButton') }}
                    <template #after>
                        <ui-icon>confirmation_number</ui-icon>
                    </template>
                </ui-button>
            </ui-card-actions>
        </ui-card>
    </div>
</template>

<script>
export default {
    name: "TdMainEvent",
    props: {
        withEvent: Object
    },
    methods: {
        goToEventDetail() {
            //console.log(this.withEvent.id)
            this.$router.push({
                name: 'eventDetail',
                params: {
                    eventId: this.withEvent.id
                }
            });
        },
        goToSelectTicket() {
            this.$router.push({
                name: 'selectTicket',
                params: {
                    eventId: this.withEvent.id
                }
            });
        },
        goToSelectRegisterType() {
            this.$router.push({
                name: 'selectRegisterType',
                params: {
                    eventId: this.withEvent.id
                }
            });
        }
    }
}
</script>

<style scoped>
.main-card {
    width: 100%;
    height: auto;
}

.main-card-content {
    max-height: 750px;
    padding: 4px;
}

.main-card-content img {
    width: auto;
    max-width: 100%;
    max-height: 400px;
    height: auto;
    margin: 0 auto;
    display: block;
}

.main-card-content p {
    max-height: 110px;
    text-align: justify;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-card-actions {
    justify-content: flex-end;
}

.main-card-button {
    margin: 4px 12px;
    height: 48px;
    font-size: medium;
}

#registry-button {
    background: #e5a946;
}

.msi {
    font-size: medium;
    font-weight: bold;
    text-align: right;
    margin-right: 10px;
    color: #3156d5;
}
</style>