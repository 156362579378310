<template>
    <h1>{{ $t('purchases.title') }}</h1>
    <div class="container">
        <div class="selection-container">
            <ui-segmented-buttons class="selection-item" v-model="selectedEventOption" :items="eventsOptions" single-select>
            </ui-segmented-buttons>
            <ui-segmented-buttons class="selection-item" v-model="selectedType" :items="paymentTypes" single-select>
            </ui-segmented-buttons>
        </div>
        <ui-progress v-show="purchasesLoading" indeterminate active></ui-progress>
        <purchase-item v-for="item in paginatedPayments" :key="item.id" class="item" :purchase="item"></purchase-item>
        <ui-pagination v-model="page" :page-size="itemsPerPage" :total="total" show-total></ui-pagination>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </div>
</template>
<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useMainStore } from '@/stores/main';
import paymentsApi from '@/api/PaymentsApi.js'
import ErrorComponent from '@/components/common/ErrorComponent.vue'
import PurchaseItem from "@/components/purchases/PurchaseItem.vue";

export default {
    name: "PurchasesView",
    components: {
        ErrorComponent, PurchaseItem
    },
    async mounted() {
        const mainStore = useMainStore()
        mainStore.$patch({ pageTitle: this.$t('titles.purchases') })

        await this.loadPurchases();
    },
    data() {
        return {
            purchasesLoading: false,
            eventsOptions: [
                {
                    icon: "first_page",
                    text: this.$t('purchases.past'),
                },
                {
                    icon: "last_page",
                    text: this.$t('purchases.future'),
                }
            ],
            paymentTypes: [
                {
                    icon: 'confirmation_number',
                    text: this.$t('purchases.ticketsOption')
                },
                {
                    icon: 'request_quote',
                    text: this.$t('purchases.registriesOption'),
                },
            ],
            selectedEventOption: 1,
            selectedType: 0,
            filteredPurchases: [],
            page: 1,
            itemsPerPage: 10,
            errorLoad: false,
            errorMessage: ""
        }
    },
    watch: {
        selectedEventOption() {
            this.loadPurchases();
        },
        selectedType() {
            this.loadPurchases();
        }
    },
    computed: {
        ...mapState(useUserStore, ['userLogged']),
        paginatedPayments() {
            const start = (this.page - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            //console.log("start: " + start + " end: " + end);
            return this.filteredPurchases.slice(start, end);
        },
        total() {
            return this.filteredPurchases.length;
        },
    },
    methods: {
        async loadPurchases() {
            try {

                this.purchasesLoading = true;

                switch (this.selectedEventOption) {

                    case 0: {

                       if (this.selectedType === 0) {
                            const pastTickets = await paymentsApi.getPastPayments(this.userLogged.token);
                            this.filteredPurchases = [...pastTickets]
                        } else if (this.selectedType === 1) {
                            const pastRegistries = await paymentsApi.getPastRegistryPayments(this.userLogged.token);
                            this.filteredPurchases = [...pastRegistries]
                        }

                        break;
                    }

                    case 1: {

                       if (this.selectedType === 0) {
                            const futureTickets = await paymentsApi.getFuturePayments(this.userLogged.token);
                            this.filteredPurchases = [...futureTickets]
                        } else if (this.selectedType === 1) {
                            const futureRegistries = await paymentsApi.getFutureRegistryPayments(this.userLogged.token);
                            this.filteredPurchases = [...futureRegistries]
                        }

                        break;
                    }
                }

                this.filteredPurchases.sort((purchaseA, purchaseB) => new Date(purchaseB.paymentDate) - new Date(purchaseA.paymentDate))
                this.page = 1;
                this.purchasesLoading = false;

            } catch (error) {
                this.errorLoad = true;
                this.purchasesLoading = false;
                this.errorMessage = error.mensaje;
            }
        }
    }
}
</script>

<style scoped>
h1 {
    text-align: center;
}

.container {
    width: 70%;
    margin: 0 auto;
    background: white;
    padding: 20px;
}

.selection-container {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.selection-item {
    padding: 10px;
    margin: 10px;
    display: inline;
}

.item {
    margin: 10px;
}
</style>