<template>
    <h1>{{ $t('bankTransfers.title') }}</h1>
    <div class="container">
        <div class="selection-container">
            <ui-segmented-buttons class="selection-item" v-model="selectedTransferStatus" :items="transferOptions"
                single-select>
            </ui-segmented-buttons>
            <ui-segmented-buttons class="selection-item" v-model="selectedType" :items="paymentTypes" single-select>
            </ui-segmented-buttons>
        </div>
        <ui-progress v-show="loadingTransfers" indeterminate active></ui-progress>
        <template v-if="totalRows > 0">
            <bank-transfer-item v-for="item in bankTransfers" :key="item.orderId" class="item"
                :bank-transfer="item"></bank-transfer-item>
            <ui-pagination v-model="page" :page-size="itemsPerPage" :total="totalRows" show-total></ui-pagination>
        </template>
        <template v-else>
            <ui-divider></ui-divider>
            <p style="text-align: center;">
                {{ $t('common.noData') }}
            </p>
        </template>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </div>
</template>

<script>

import { useMainStore } from '@/stores/main';
import bankTransfersApi from '@/api/BankTransfersApi.js'
import BankTransferItem from '@/components/transfers/BankTransferItem.vue'
import ErrorComponent from '@/components/common/ErrorComponent.vue'

export default {
    name: "BankTransfersView",
    components: {
        ErrorComponent, BankTransferItem
    },
    async mounted() {
        const mainStore = useMainStore()
        mainStore.$patch({ pageTitle: this.$t('bankTransfers.title') })
        await this.loadTransfers();
    },
    data() {
        return {
            transferOptions: [
                {
                    icon: "check_circle",
                    text: this.$t('bankTransfers.completed'),
                },
                {
                    icon: "incomplete_circle",
                    text: this.$t('bankTransfers.incomplete'),
                },
            ],
            paymentTypes: [
                {
                    icon: 'confirmation_number',
                    text: this.$t('purchases.ticketsOption')
                },
                {
                    icon: 'request_quote',
                    text: this.$t('purchases.registriesOption'),
                },
            ],
            selectedTransferStatus: 1,
            selectedType: 0,
            bankTransfers: [],
            page: 1,
            itemsPerPage: 10,
            totalRows: 0,
            loadingTransfers: false,
            disablePageWatcher: false,
            errorLoad: false,
            errorMessage: ""
        }
    },
    watch: {
        selectedTransferStatus() {
            //console.log('selectedTransferStatus')
            this.disablePageWatcher = true;
            this.page = 1
            this.loadTransfers();
        },
        selectedType() {
            //console.log('selectedType')
            this.disablePageWatcher = true
            this.page = 1
            this.loadTransfers();
        },
        page() {
            if (!this.disablePageWatcher) {
                this.loadTransfers();
            }
        }
    },
    methods: {
        async loadTransfers() {

            try {

                this.loadingTransfers = true
                const pageForApi = this.page - 1

                switch (this.selectedTransferStatus) {

                    case 0: {

                        switch (this.selectedType) {
                            case 0: {
                                const paymentTransfersData = await bankTransfersApi.getPaymentBankTransfers(true, pageForApi, this.itemsPerPage);
                                this.bankTransfers = [...paymentTransfersData.data]
                                this.totalRows = paymentTransfersData.totalRows
                                break;

                            }

                            case 1: {
                                const registryPaymentTransfersData = await bankTransfersApi.getRegistryPaymentBankTransfers(true, pageForApi, this.itemsPerPage);
                                this.bankTransfers = [...registryPaymentTransfersData.data]
                                this.totalRows = registryPaymentTransfersData.totalRows
                                break;
                            }
                        }

                        break;
                    }

                    case 1: {
                        switch (this.selectedType) {
                            case 0: {
                                const paymentTransfersData = await bankTransfersApi.getPaymentBankTransfers(false, pageForApi, this.itemsPerPage);
                                this.bankTransfers = [...paymentTransfersData.data]
                                this.totalRows = paymentTransfersData.totalRows
                                break;

                            }

                            case 1: {
                                const registryPaymentTransfersData = await bankTransfersApi.getRegistryPaymentBankTransfers(false, pageForApi, this.itemsPerPage);
                                this.bankTransfers = [...registryPaymentTransfersData.data]
                                this.totalRows = registryPaymentTransfersData.totalRows
                                break;
                            }
                        }
                        break;
                    }
                }

                this.loadingTransfers = false
                this.disablePageWatcher = false
            }

            catch (error) {
                this.errorLoad = true;
                this.loadingTransfers = false;
                this.errorMessage = error.mensaje;
            }

        }
    }
}
</script>

<style scoped>
h1 {
    text-align: center;
}

.container {
    width: 70%;
    margin: 0 auto;
    background: white;
    padding: 20px;
}

.selection-container {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.selection-item {
    padding: 10px;
    margin: 10px;
    display: inline;
}

.item {
    margin: 10px;
}
</style>