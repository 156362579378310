<template>
    <div class="methods-container">
        <ui-progress class="progress" v-show="loading" indeterminate active></ui-progress>
        <h3> {{ $t('shoppingCart.selectPaymentMethod') }} </h3>
        <div v-show="isTicket && !methodsLoading" class="coupon-container">
            <ui-icon-button v-show="withCoupon" :disabled="loading" icon="highlight_off" class="cancel-coupon"
                @click.prevent="cancelCouponAndUpdate"></ui-icon-button>
            <ui-textfield :disabled="withCoupon || loading" v-model="coupoName" :class="couponNameClass">
                <template #before>
                    <ui-textfield-icon>redeem</ui-textfield-icon>
                </template>
                {{ $t('shoppingCart.couponName') }}
            </ui-textfield>
            <ui-button outlined :disabled="disableEnter || loading" class="coupon-redeem"
                @click.prevent="redeemCouponAndUpdate">
                {{ $t('shoppingCart.redeem') }}
            </ui-button>
        </div>
        <div style="margin: 15px 0;">
            <ui-button outlined @click="loadCardElement"> {{ $t('shoppingCart.addCard') }}
                <template #after="{ iconClass }">
                    <ui-icon :class="iconClass">add</ui-icon>
                </template>
            </ui-button>
            <br>
            <label id="addCardDisclaimer">{{ $t('shoppingCart.addCardDisclaimer') }}</label>
        </div>
        <ui-form>
            <template v-if="hasSavedCards">
                <label id="cardsSavedTitle"> {{ $t('shoppingCart.savedCards') }}</label>
                <ui-form-field v-for="(card) in existingCards" :key="card.cardId">
                    <ui-radio v-model="selectedMethod" :value="card.cardId"></ui-radio>
                    <ui-icon size="24" outlined>payment</ui-icon>
                    <div class="card-item">****{{ card.cardNumber }} ({{ card.expDate }}, {{ card.brand }})</div>
                    <ui-icon-button icon="delete" @click="deleteCard(card.cardId)"></ui-icon-button>
                </ui-form-field>
            </template>
            <ui-form-field v-show="loadingPlans">
                <label class="loading">{{ $t('plans.loading') }}</label>
            </ui-form-field>
            <ui-form-field>
                <ui-radio v-model="selectedMethod" value="add"></ui-radio>
                <label class="card-item">{{ $t('shoppingCart.addPaymentMethod') }}</label>
            </ui-form-field>
        </ui-form>
        <div id="payment-form" v-show="selectedMethod === 'add'">
            <div id="payment-element">
                <!-- Elements will create form elements here -->
            </div>
        </div>
        <div v-show="cardWithPlans">
            <h5>
                {{ $t('plans.plansAvailable') }}
            </h5>
            <label v-show="msiEnabled" class="msi">
                {{ $t('plans.msiAvailable') }}
            </label>
            <ui-select fullwidth v-model="selectedPlan" :options="plansDropdownOptions"> {{
                $t('plans.selectPlan') }}
            </ui-select>
        </div>
        <ui-button raised v-show="!methodsLoading" :disabled="loading" icon="lock" class="pay"
            @click.prevent="validateAndPay"> {{
                $t('shoppingCart.payNow') }}
        </ui-button>
        <div id="error-message">
            <!-- Display error message to your customers here -->
        </div>
        <ui-progress class="progress" v-show="loading" indeterminate active></ui-progress>
        <error-component v-show="errorLoad || errorCoupon" :message="errorMessage" />
        <ui-alert v-show="withCoupon" state="success" class="success-coupon-applied"> {{
            $t('shoppingCart.redeemSuccessful') }} </ui-alert>
    </div>
    <!-- New Card dialog-->
    <ui-dialog :closable="false" v-model="openNewCard" @confirm="saveCustomerCard">
        <ui-dialog-title> {{ $t('shoppingCart.addCard') }}</ui-dialog-title>
        <ui-dialog-content>
            <div id="card-details">
                <ui-textfield style="margin-top: 15px;" outlined fullwidth v-model="cardholderName">
                    {{ $t('shoppingCart.cardholder') }}
                </ui-textfield>
                <br>
                <!-- placeholder for Elements -->
                <form id="card-payment-form">
                    <div id="card-element"></div>
                </form>
            </div>
            <ui-progress class="progress" v-show="loadingNewCard" indeterminate active></ui-progress>
            <error-component v-show="errorNewCard" :message="errorMessageNewCard" />
        </ui-dialog-content>
        <ui-dialog-actions></ui-dialog-actions>
    </ui-dialog>
</template>

<script>

import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useMainStore } from '@/stores/main';
import { helpers } from 'balm-ui';
import paymentsApi from '@/api/PaymentsApi';
import numberUtils from '@/utils/numberUtils';
import ErrorComponent from '../common/ErrorComponent.vue';
import { analytics, logEvent } from "@/utils/firebase"

var stripe;
var elements;
var stripeCardElementsObject;
var cardElement;

export default {
    name: 'PaymentMethods',
    components: {
        ErrorComponent
    },
    props: {
        isTicket: Boolean,
        eventId: String,
        orderId: String,
        totalAmount: Number,
        withCoupon: Boolean,
        appliedCouponName: String,
        registerTypeId: String,
        participantsCount: Number,
        fullRegisterTypeName: String,
        currency: String,
        tdAccount: String,
        connectAccountId: String,
        msiEnabled: Boolean
    },
    data() {
        return {
            selectedMethod: 'add',
            methodsLoading: false,
            existingCards: [],
            availablePlans: [],
            plansDropdownOptions: [],
            selectedPlan: null,
            loading: false,
            errorLoad: false,
            errorCoupon: false,
            errorMessage: "",
            coupoName: this.appliedCouponName,
            origin: window.location.origin,
            returnUrl: "",
            loadingPlans: false,
            openNewCard: false,
            cardholderName: "",
            loadingNewCard: false,
            errorNewCard: false,
            errorMessageNewCard: "",
            newCardClientSecret: ""
        }
    },
    async mounted() {
        //console.log("onMounted")
        if (this.userLogged) {
            //console.log("isTicket: " + this.isTicket)
            if (this.isTicket) {
                await this.loadPaymentSheet();
            } else {
                await this.loadRegistryPaymentSheet();
            }
        }
    },
    computed: {
        ...mapState(useUserStore, ['userLogged', 'shoppingCart', 'shoppingCartPaymentIntentId', 'registryPaymentIntentId', 'registryFormData']),
        ...mapState(useMainStore, ['defaultLanguage']),
        stripe() {
            if (this.tdAccount === 'mx') {
                if (helpers.isEmpty(this.connectAccountId)) {
                    return stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY_MX)
                } else {
                    return stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY_MX, {
                        stripeAccount: this.connectAccountId
                    })
                }
            } else if (this.tdAccount === 'us') {
                if (helpers.isEmpty(this.connectAccountId)) {
                    return stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY_US)
                } else {
                    return stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY_US, {
                        stripeAccount: this.connectAccountId
                    })
                }
            } else {
                return undefined;
            }
        },
        hasSavedCards() {
            return !helpers.isEmpty(this.existingCards);
        },
        couponNameClass() {
            return this.withCoupon ? 'coupon-name-with-coupon' : 'coupon-name-empty'
        },
        total() {
            return numberUtils.getCurrencyFromNumber(this.totalAmount, this.currency)
        },
        disableEnter() {
            return this.withCoupon ? true : helpers.isEmpty(this.coupoName)
        },
        cardWithPlans() {
            return !helpers.isEmpty(this.availablePlans)
        },
        canPay() {
            if (this.isTicket) {

                if (this.shoppingCart.isMandatoryName) {

                    if (helpers.isEmpty(this.shoppingCart.names)) {
                        return false;
                    }
                    else {
                        const names = [...this.shoppingCart.names.split(',')]
                        if (names.length !== this.shoppingCart.numNamesRequired) {
                            return false;
                        }
                        return true;
                    }
                }

                return true;

            } else {

                if (this.registryFormData && this.registryFormData.registryModels) {

                    for (var index = 0; index < this.participantsCount; index++) {

                        if (helpers.isEmpty(this.registryFormData.registryModels[index].participantName)) {
                            return false;
                        }
                    }

                    return true;

                } else {
                    return false
                }
            }
        }
    },
    watch: {
        async selectedMethod(newValue) {
            if (newValue === 'add') {
                this.availablePlans = []
                this.selectedPlan = null
                this.loadingPlans = false
            } else {
                this.loadingPlans = true
                await this.getPlansForCard();
                this.loadingPlans = false
            }
        }
    },
    methods: {
        ...mapActions(useUserStore, ['redeemCoupon', 'cancelRedeemCoupon', 'checkSubmissionShoppingCart', 'submitRegistryForm']),
        getFormattedAmount(amount) {
            return numberUtils.getCurrencyFromNumber(amount, this.currency)
        },
        async loadPaymentSheet() {
            this.methodsLoading = true;

            try {
                const paymentSheetData = await paymentsApi.requestPaymentSheet(this.userLogged.token)
                this.methodsLoading = false;

                const store = useUserStore()
                store.$patch({ shoppingCartPaymentIntentId: paymentSheetData.paymentIntentId })

                const options = {
                    clientSecret: paymentSheetData.paymentIntentIdClientSecret,
                    // Fully customizable with appearance API.
                    appearance: {
                        theme: 'flat',
                        variables: {
                            colorPrimary: '#652d66',
                            colorBackground: '#eee',
                            colorText: '#000',
                            colorDanger: '#df1b41',
                            fontFamily: 'Nunito, sans-serif',
                            spacingUnit: '4px',
                            borderRadius: '4px',
                        }
                    },
                    locale: this.defaultLanguage,
                    fonts: [{
                        cssSrc: 'https://fonts.googleapis.com/css?family=Nunito'
                    }]
                };
                // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained before
                elements = this.stripe.elements(options);
                // Create and mount the Payment Element
                const paymentElement = elements.create('payment', {
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'link', 'oxxo', 'customer_balance', 'card'],
                    defaultValues: {
                        billingDetails: {
                            email: this.userLogged.correo
                        }
                    }
                });
                paymentElement.mount('#payment-element');

                // For Shopping Cart, as not always the Save is mandatory, we call the Submission immediately after getting the PaymentIntentId
                await this.checkSubmissionShoppingCart({
                    orderId: this.orderId,
                    paymentIntentId: this.shoppingCartPaymentIntentId
                })
                if (helpers.isEmpty(this.connectAccountId)) {
                    this.returnUrl = `${this.origin}/check-payment-status?type=TICKET&event_id=${this.eventId}&order_id=${this.orderId}`
                } else {
                    this.returnUrl = `${this.origin}/check-payment-status?type=TICKET&event_id=${this.eventId}&order_id=${this.orderId}&connect_account_id=${this.connectAccountId}`
                }

                await this.getCustomerCards();

                this.errorLoad = false;

            } catch (error) {
                this.methodsLoading = false;
                this.errorLoad = true;
                this.methodsLoading = false;
                this.errorMessage = error.mensaje;
            }
        },
        async loadRegistryPaymentSheet() {
            this.methodsLoading = true;
            this.errorLoad = false;

            try {
                const paymentSheetData = await paymentsApi.requestRegistryPaymentSheet(this.userLogged.token, this.registerTypeId, this.participantsCount)
                this.methodsLoading = false;

                const store = useUserStore()
                store.$patch({ registryPaymentIntentId: paymentSheetData.paymentIntentId })

                if (helpers.isEmpty(this.connectAccountId)) {
                    this.returnUrl = `${this.origin}/check-payment-status?type=REGISTRY&event_id=${this.eventId}`
                } else {
                    this.returnUrl = `${this.origin}/check-payment-status?type=REGISTRY&event_id=${this.eventId}&connect_account_id=${this.connectAccountId}`
                }

                const options = {
                    clientSecret: paymentSheetData.paymentIntentIdClientSecret,
                    // Fully customizable with appearance API.
                    appearance: {
                        theme: 'flat',
                        variables: {
                            colorPrimary: '#652d66',
                            colorBackground: '#eee',
                            colorText: '#000',
                            colorDanger: '#df1b41',
                            fontFamily: 'Nunito, sans-serif',
                            spacingUnit: '4px',
                            borderRadius: '4px',
                        }
                    },
                    locale: this.defaultLanguage,
                    fonts: [{
                        cssSrc: 'https://fonts.googleapis.com/css?family=Nunito'
                    }]
                };
                // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained before
                elements = this.stripe.elements(options);
                // Create and mount the Payment Element
                const paymentElement = elements.create('payment', {
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'link', 'oxxo', 'customer_balance', 'card'],
                    defaultValues: {
                        billingDetails: {
                            email: this.userLogged.correo
                        }
                    }
                });
                paymentElement.mount('#payment-element');

                await this.getCustomerCards();

                this.errorLoad = false;

            } catch (error) {
                this.methodsLoading = false;
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
            }
        },
        async redeemCouponAndUpdate() {

            this.loading = true
            this.errorCoupon = false;
            try {
                await this.redeemCoupon({
                    orderId: this.orderId,
                    cardPaymentIntentId: this.shoppingCartPaymentIntentId,
                    couponName: this.coupoName
                });

                if (this.selectedMethod !== 'add') {
                    this.loadingPlans = true
                    await this.getPlansForCard();
                    this.loadingPlans = false
                }
                this.loading = false
            }
            catch (error) {
                this.loading = false;
                this.errorMessage = error.mensaje;
                this.errorCoupon = true;
            }
        },
        async cancelCouponAndUpdate() {
            this.loading = true
            this.errorCoupon = false;
            try {
                await this.cancelRedeemCoupon({
                    orderId: this.orderId,
                    cardPaymentIntentId: this.shoppingCartPaymentIntentId,
                    couponName: this.coupoName
                });
                if (this.selectedMethod !== 'add') {
                    this.loadingPlans = true
                    await this.getPlansForCard();
                    this.loadingPlans = false
                }
                this.loading = false
                this.coupoName = ""
                this.$toast(this.$t('shoppingCart.removeCouponSuccessful'))
            }
            catch (error) {
                this.loading = false;
                this.errorMessage = error.mensaje;
                this.errorCoupon = true;
            }
        },
        async loadCardElement() {

            this.loading = true;
            this.errorLoad = false;
            this.newCardClientSecret = "";

            try {

                this.newCardClientSecret = await paymentsApi.requestSetupIntent(this.userLogged.token, this.connectAccountId);

                stripeCardElementsObject = this.stripe.elements({
                    locale: this.defaultLanguage,
                    fonts: [{
                        cssSrc: 'https://fonts.googleapis.com/css?family=Nunito'
                    }]
                });
                cardElement = stripeCardElementsObject.create('card', {
                    style: {
                        base: {
                            iconColor: '#652d66',
                            color: '#000',
                            fontWeight: '300',
                            fontFamily: 'Nunito, sans-serif',
                            fontSmoothing: 'antialiased'
                        },
                    },
                });
                cardElement.mount('#card-element');

                this.openNewCard = true;
                this.loading = false;

            } catch (error) {
                this.loading = false;
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
            }
        },
        async saveCustomerCard(result) {

            if (result) {
                //console.log(this.cardholderName);

                this.loadingNewCard = true;
                this.errorNewCard = false;

                const { error } = await this.stripe.confirmCardSetup(
                    this.newCardClientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: { name: this.cardholderName }
                    }
                }
                );

                this.loadingNewCard = false;

                //console.log(cardElement);
                //console.log(error);

                if (error) {
                    this.errorNewCard = true;
                    this.errorMessageNewCard = error;
                } else {
                    await this.getCustomerCards();
                    this.openNewCard = false
                }
            } else {
                this.openNewCard = false
            }
        },
        async getCustomerCards() {

            if (this.isTicket) {
                this.existingCards = await paymentsApi.getCards(this.userLogged.token, this.shoppingCartPaymentIntentId, this.connectAccountId);
            } else {
                this.existingCards = await paymentsApi.getCards(this.userLogged.token, this.registryPaymentIntentId, this.connectAccountId);
            }

            if (!helpers.isEmpty(this.existingCards)) {
                this.selectedMethod = this.existingCards[0].cardId;
            } else {
                this.selectedMethod = 'add'
            }

        },
        async getPlansForCard() {

            var plans;

            if (this.isTicket) {

                plans = await paymentsApi.getPlansForCard(this.userLogged.token, this.shoppingCartPaymentIntentId, this.selectedMethod, this.connectAccountId);

            } else {
                plans = await paymentsApi.getPlansForCard(this.userLogged.token, this.registryPaymentIntentId, this.selectedMethod, this.connectAccountId);
            }

            //console.log(plans)
            this.availablePlans = []
            this.plansDropdownOptions = []
            this.selectedPlan = null

            if (!helpers.isEmpty(plans) && !helpers.isEmpty(plans.installmentPlans)) {

                this.availablePlans = [...plans.installmentPlans]

                this.plansDropdownOptions[0] = {
                    value: -1,
                    label: this.$t('plans.none', [this.getFormattedAmount(this.totalAmount)])
                }
                plans.installmentPlans.forEach((plan, idx) => {

                    if (plan.interval === 'month' && plan.type === 'fixed_count') {

                        this.plansDropdownOptions[idx + 1] = {
                            value: idx,
                            label: this.$t('plans.multiple', [plan.count, this.getFormattedAmount(plan.amount)])
                        }
                    }

                });

                this.selectedPlan = -1

                //console.log(this.plansDropdownOptions)
                //console.log(this.availablePlans)
            }

        },
        async deleteCard(paymentMethodId) {

            this.loading = true;
            this.errorLoad = false;

            try {

                await paymentsApi.deleteCard(this.userLogged.token, paymentMethodId, this.connectAccountId)
                await this.getCustomerCards();

                this.loading = false;

            } catch (error) {

                this.loading = false;
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
            }
        },
        async validateAndPay() {

            if (this.canPay) {

                if (this.selectedMethod === 'add') {

                    try {

                        this.loading = true

                        const { error } = await stripe.confirmPayment({
                            //`Elements` instance that was used to create the Payment Element
                            elements,
                            confirmParams: {
                                return_url: this.returnUrl,
                            },
                        });

                        if (this.isTicket) {
                            logEvent(analytics, 'pay_shopping_cart', {
                                event_name: this.tdEvent.name,
                                ticket_name: this.shoppingCart.ticketName,
                                payment_type: 'web'
                            })
                        } else {
                            logEvent(analytics, 'pay_register', {
                                event_name: this.tdEvent.name,
                                register: this.fullRegisterTypeName,
                                payment_type: 'web'
                            })
                        }

                        this.loading = false;

                        if (error) {
                            this.errorLoad = true;
                            this.errorMessage = error.message;

                            if (this.isTicket) {
                                logEvent(analytics, 'payment_failed_shopping_cart', {
                                    event_name: this.tdEvent.name,
                                    ticket_name: this.shoppingCart.ticketName,
                                    payment_type: 'web'
                                })
                            } else {
                                logEvent(analytics, 'payment_failed_register', {
                                    event_name: this.tdEvent.name,
                                    register: this.fullRegisterTypeName,
                                    payment_type: 'web'
                                })
                            }
                        }

                    } catch (error) {
                        this.loading = false;
                        this.errorLoad = true;
                        this.errorMessage = error.mensaje;
                    }
                } else {
                    //console.log("Confirm payment in Server with:")
                    //console.log("selectedMethod: " + this.selectedMethod)
                    //console.log("selectedPlanIndex: " + this.selectedPlan)
                    //console.log(this.availablePlans[this.selectedPlan])

                    this.loading = true

                    const cardObject = {
                        card: {
                            cardId: this.selectedMethod
                        },
                        paymentIntentId: this.isTicket ? this.shoppingCartPaymentIntentId : this.registryPaymentIntentId,
                        returnUrl: this.returnUrl,
                        installmentPlans: (this.selectedPlan == null || this.selectedPlan === -1) ? null : [{
                            count: this.availablePlans[this.selectedPlan].count
                        }]
                    }

                    //console.log(this.selectedPlan)
                    //console.log(this.connectAccountId)
                    //console.log(cardObject)

                    try {

                        const paymentIntentConfirmResponse = await paymentsApi.confirmPaymentIntent(this.userLogged.token, this.connectAccountId, cardObject);

                        this.returnUrl += "&payment_intent=" + paymentIntentConfirmResponse.paymentIntentId + "&payment_intent_client_secret=" + paymentIntentConfirmResponse.clientSecret

                        this.loading = false

                        window.location.replace(this.returnUrl);

                    } catch (error) {
                        this.loading = false;
                        this.errorMessage = error.mensaje;
                        this.errorLoad = true;
                    }

                }

            } else if (this.isTicket) {
                this.$alert({
                    message: this.$t('errors.noTicketNames'),
                    state: 'info',
                });

            } else {
                this.$alert({
                    message: this.$t('errors.noRegistryNames'),
                    state: 'info',
                });
            }
        },
    }
}

</script>

<style scoped>
h3 {
    text-align: center;
}

.methods-container {
    padding: 20px;
    background: white;
}

.coupon-container {
    margin-top: 20px;
    height: 80px;
    width: 100%;
}

.cancel-coupon {
    float: left;
    margin-top: 5px;
    margin-right: 5px;
    width: 11%;
}

.coupon-name-with-coupon {
    float: left;
    width: 56%;
}

.coupon-name-empty {
    float: left;
    width: 67%;
}

.success-coupon-applied {
    margin-top: 20px;
}

button.coupon-redeem {
    float: right;
    width: 30%;
    height: 56px;
}

#addCardDisclaimer {
    font-size: small;
    color: #652d66;
}

#cardsSavedTitle {
    font-weight: bold;
    font-size: small;
}

#card-details {
    width: 400px;
    height: 120px;
}


button.pay {
    color: white;
    background-color: #e5a946;
    font-weight: bolder;
    font-size: large;
    margin-top: 20px;
    width: 100%;
    height: 60px;
}

.progress {
    margin-top: 20px;
}

.existing-cards-form {
    margin-top: 20px;
    width: 100%;
}

.card-item {
    width: 250px;
    margin: 4px;
    font-size: medium;
}

.msi {
    font-size: medium;
    font-weight: bold;
    color: #3156d5;
}

.loading {
    width: 250px;
    margin: 4px;
    font-size: small;
    color: #3156d5;
    font-style: italic;
}
</style>