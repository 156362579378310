import { URLS, LS_KEYS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";

export default {

    async login(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.USER_LOGIN, {
            method: "POST",
            body: JSON.stringify({
                correo: userModel.email,
                password: userModel.password,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async signInWithGoogle(externalSignInModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GOOGLE_USER_SIGNIN, {
            method: "POST",
            body: JSON.stringify({
                idToken: externalSignInModel.idToken,
                language: externalSignInModel.language,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async signInWithApple(appleAuthorizationModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.APPLE_USER_SIGNIN, {
            method: "POST",
            body: JSON.stringify(appleAuthorizationModel),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    logout() {
        localStorage.removeItem(LS_KEYS.USER_LOGIN_KEY);
    },

    async create(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.USER_CREATE, {
            method: "POST",
            body: JSON.stringify({
                preferredCurrency: userModel.preferredCurrency,
                correo: userModel.correo,
                password: userModel.password
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async resendValidationEmail(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.RESEND_VALIDATION_EMAIL, {
            method: "POST",
            body: JSON.stringify({
                correo: userModel.email,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async requestForgotCode(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REQUEST_FORGOT_CODE, {
            method: "POST",
            body: JSON.stringify({
                correo: userModel.email,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async validateForgotCode(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.VALIDATE_FORGOT_CODE, {
            method: "POST",
            body: JSON.stringify({
                user: userModel.email,
                token: userModel.codigo
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async updateUserPassword(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_USER_PASSWORD, {
            method: "POST",
            body: JSON.stringify({
                resetToken: userModel.token,
                newPassword: userModel.newPassword,
                user: userModel.email
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getUserData(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_USER_DATA, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async checkPassword(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CHECK_PASSWORD, {
            method: "POST",
            body: JSON.stringify({
                correo: payload.correo,
                password: payload.password,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return true;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async updateUserData(payload) {

        let capcha = await commonsApi.getCaptcha();

        //console.log(payload);

        let response = await fetch(URLS.UPDATE_USER_DATA, {
            method: "POST",
            body: JSON.stringify({
                preferredCurrency: payload.preferredCurrency,
                correo: payload.correo,
                nombre: payload.nombre,
                password: payload.password,
                apellidos: payload.apellidos,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            //console.log(userData);
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async deleteUser(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DELETE_USER, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        })

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async checkShoppingCart(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CHECK_SHOPPING_CART, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        })

        if (response.status === 204) { // NO CONTENT: Success with no shopping cart
            //localStorage.removeItem(LS_KEYS.USER_SHOPPING_CART_KEY);
            return null;
        }
        else if (response.status === 200) { // OK: Success with shopping cart
            let shoppingCart = await response.json();
            //localStorage.setItem(LS_KEYS.USER_SHOPPING_CART_KEY, JSON.stringify(shoppingCart));
            return shoppingCart;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }
}