<template>
  <ui-card class="card-container" v-shadow.transition="[2, 8]">
    <ui-form type="|" item-margin-bottom="20" action-align="center">
      <template #default="{ itemClass, actionClass }">
        <ui-form-field :class="itemClass">
          <h2>{{ $t('forgoPassword.title1') }}</h2>
        </ui-form-field>
        <ui-form-field :class="itemClass">
          <label>{{ $t('forgoPassword.instructions1') }}</label>
        </ui-form-field>
        <ui-form-field :class="itemClass">
          <ui-textfield input-type="email" outlined fullwidth v-model="formData.forgotemail"
            helper-text-id="email-forgot-helper-text" :disabled="formLoading || isCodeValidated">
            {{ $t('myAccount.email') }}
            <template #before>
              <ui-textfield-icon>email</ui-textfield-icon>
            </template>
          </ui-textfield>
          <ui-textfield-helper id="email-forgot-helper-text" v-model:validMsg="validMsg.forgotemail">
          </ui-textfield-helper>
        </ui-form-field>
        <ui-form-field :class="actionClass">
          <ui-button raised native-type="submit" @click.prevent="sendCode"
            :disabled="formLoading || !isCodeEmpty || isCodeValidated">{{ $t('forgoPassword.instructions1button') }}
            <template #after="{ iconClass }">
              <ui-icon :class="iconClass">send</ui-icon>
            </template>
          </ui-button>
        </ui-form-field>

        <ui-form-field :class="itemClass">
          <h2>{{ $t('forgoPassword.title2') }}</h2>
        </ui-form-field>
        <ui-form-field :class="itemClass">
          <label>{{ $t('forgoPassword.instructions2') }}</label>
        </ui-form-field>
        <ui-form-field :class="itemClass">
          <ui-textfield input-type="password" outlined fullwidth v-model="formData.forgotcode"
            :disabled="formLoading || isCodeValidated">
            {{ $t('forgoPassword.instructions2TF') }}
            <template #before>
              <ui-textfield-icon>vpn_key</ui-textfield-icon>
            </template>
          </ui-textfield>
        </ui-form-field>
        <ui-form-field :class="actionClass">
          <ui-button raised native-type="submit" @click.prevent="validateCode"
            :disabled="formLoading || isCodeEmpty || isCodeValidated"> {{ $t('forgoPassword.instructions2button') }}
            <template #after="{ iconClass }">
              <ui-icon :class="iconClass">check</ui-icon>
            </template>
          </ui-button>
        </ui-form-field>
      </template>
    </ui-form>
    <ui-progress v-show="formLoading" indeterminate active></ui-progress>
    <error-component v-show="errorForgot" :message="errorMessage" />
  </ui-card>
</template>
<script>
import { useValidator, helpers } from "balm-ui";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import userApi from "@/api/UserApi";
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';

const validations = [
  {
    key: "forgotemail",
    label: "Email",
    validator: "required, tdEmailValidator",
  },
];

export default {
  name: "ForgotPasswordForm",
  components: {
    ErrorComponent,
  },
  emits: ["updateCodeValidated", "updateForgotEmail", "udpateForgotCode"],
  data() {
    return {
      balmUI: useValidator(),
      validations,
      validMsg: {},
      formData: {
        forgotemail: "",
        forgotcode: "",
      },
      isCodeValidated: false,
      formLoading: false,
      errorForgot: false,
      errorMessage: "",
    };
  },
  computed: {
    isCodeEmpty() {
      return helpers.isEmpty(this.formData.forgotcode);
    },
  },
  methods: {
    async sendCode() {
      let result = this.balmUI.validate(this.formData);
      //console.log(result);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {

        window.grecaptcha.enterprise.ready(async () => {

          this.formLoading = true;
          this.errorForgot = false;

          try {

            const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.SEND_FORGOT_PASSWORD_CODE });
            await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.SEND_FORGOT_PASSWORD_CODE, token, process.env.VUE_APP_RECAPTCHA_KEY)

            await userApi.requestForgotCode({
              email: this.formData.forgotemail.trim().toLowerCase(),
            });

            this.formLoading = false;

            this.$alert({
              title: "Importante",
              message: this.$t("forgoPassword.codeSent"),
              state: "info",
            });
          } catch (error) {
            this.formLoading = false;
            this.errorMessage = error.mensaje;
            this.errorForgot = true;
          }
        })
      }
    },
    async validateCode() {
      let result = this.balmUI.validate(this.formData);
      //console.log(result);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {

        window.grecaptcha.enterprise.ready(async () => {

          this.formLoading = true;
          this.isCodeValidated = false;
          this.errorForgot = false;

          try {

            const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.VALIDATE_FORGOT_PASSWORD_CODE });
            await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.VALIDATE_FORGOT_PASSWORD_CODE, token, process.env.VUE_APP_RECAPTCHA_KEY)

            await userApi.validateForgotCode({
              email: this.formData.forgotemail.trim().toLowerCase(),
              codigo: this.formData.forgotcode,
            });

            this.formLoading = false;
            this.isCodeValidated = true;

            this.$emit("updateCodeValidated", true);
            this.$emit(
              "updateForgotEmail",
              this.formData.forgotemail.trim().toLowerCase()
            );
            this.$emit("udpateForgotCode", this.formData.forgotcode);
          } catch (error) {
            this.formLoading = false;
            this.isCodeValidated = false;
            this.errorMessage = error.mensaje;
            this.errorForgot = true;

            this.$emit("updateCodeValidated", false);
            this.$emit("updateForgotEmail", "");
            this.$emit("udpateForgotCode", "");
          }
        });
      }
    },
  },
};
</script>
<style scoped>
label {
  text-align: justify;
}

.card-container {
  padding: 2em;
  margin-top: 3em;
  min-height: 620px;
}
</style>