<template>
  <p>{{ message }}</p>
</template>
<script>
export default {
  name: "ErrorComponent",
  props: {
    message: String,
  },
};
</script>
<style scoped>
p {
  background: #ffebee;
  color: #d32f2f;
  padding: 0.3em;
  text-align: center;
}
</style>