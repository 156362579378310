<template>
    <h1>{{ $t('footer.linksMission') }}</h1>
    <div v-shape class="container">
        <h2>Misión</h2>
        <p>Innovar en los Congresos de Baile Mexicanos.<br>
            Proteger los datos de todos nuestros usuarios.<br>
            Agilizar y facilitar el proceso de compra y acceso a los Congresos.
        </p>
    </div>

    <div v-shape class="container">
        <h2>Visión</h2>
        <p>Ser la plataforma digital donde convergen los mejores Eventos y Congresos de baile en México para su ágil
            consulta y
            compras seguras de los usuarios, además de servir como herramienta de gestión para el productor de dichos
            eventos.</p>
    </div>

</template>

<script>
export default {
    name: "MisionVisionView"
}
</script>

<style scoped>
.container {
    max-width: 60%;
    margin: auto;
    background: white;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

h1 {
    text-align: center;
}

h2 {
    margin: 20px 50px;
}

p {
    text-align: justify;
    margin: 20px 50px;
}
</style>