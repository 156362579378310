<template>
    <div class="assign-container">
        <h3> {{ $t('registries.fillInformation') }}</h3>
        <ui-icon-button icon="save" class="icon-save" @click.prevent="saveFormData"></ui-icon-button>
        <br>
        <h4> {{ $t('registries.generalData') }} </h4>
        <ui-textfield class="textfield" v-model="companyGroup"> {{ $t('shoppingCart.companyGroup')}}
        </ui-textfield>
        <template v-if="tdRegisterType.question1Form">
            <ui-textfield class="textfield" v-model="answer1"> {{
            tdRegisterType.question1Form }}
            </ui-textfield>
        </template>
        <template v-if="tdRegisterType.question2Form">
            <ui-textfield class="textfield" v-model="answer2"> {{
            tdRegisterType.question2Form }}
            </ui-textfield>
        </template>
        <template v-if="tdRegisterType.question3Form">
            <ui-textfield class="textfield" v-model="answer3"> {{
            tdRegisterType.question3Form }}
            </ui-textfield>
        </template>
        <h4> {{ $t('registries.participantsSubtitle') }} </h4>
        <h5 class="red"> {{ $t('registries.allNamesRequired') }} </h5>
        <div class="form-container" v-for="(value, index) in participantsCount" :key="index">
            <div class="header-container">
                <ui-icon class="icon-person">person</ui-icon>
                <h5> {{ index + 1 }} / {{ participantsCount }}</h5>
            </div>
            <ui-textfield required class="textfield" v-model="fullName[index]"> {{ $t('registries.fullName') }}
            </ui-textfield>
            <div class="form-subcontainer">
                <ui-textfield input-type="number" min="0" max="100" class="textfield-half-width-left"
                    v-model="age[index]"> {{
                    $t('registries.age') }}
                </ui-textfield>
                <div class="radio-button-container">
                    <ui-radio v-model="genre[index]" value="Hombre" class="radio-element"> </ui-radio>
                    <label>{{ $t('registries.genreH') }}</label>
                </div>
                <div class="radio-button-container">
                    <ui-radio v-model="genre[index]" value="Mujer" class="radio-element"> </ui-radio>
                    <label>{{ $t('registries.genreM') }}</label>
                </div>
            </div>
            <div class="form-subcontainer">
                <ui-textfield class="textfield-half-width-left" v-model="state[index]"> {{ $t('registries.state') }}
                </ui-textfield>
                <ui-textfield class="textfield-half-width-right" v-model="city[index]"> {{ $t('registries.city') }}
                </ui-textfield>
            </div>
            <ui-textfield input-type="email" class="textfield" v-model="email[index]"> {{ $t('registries.email') }}
            </ui-textfield>
        </div>
        <ui-button raised class="save" @click.prevent="saveFormData"> {{
        $t('alerts.saveButton')}}
        </ui-button>
        <ui-progress class="progress" v-show="loading" indeterminate active></ui-progress>
        <error-component v-show="errorSave" :message="errorMessage" />
    </div>
</template>

<script>

import { useUserStore } from '@/stores/user';
import { mapActions, mapState } from 'pinia';
import { helpers } from 'balm-ui';
import validator from '@/utils/validator';
import ErrorComponent from '@/components/common/ErrorComponent.vue';
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';

export default {
    name: "AssignRegistries",
    components: { ErrorComponent },
    props: {
        eventId: String,
        tdRegisterType: Object,
        participantsCount: Number
    },
    mounted() {
        // Clear the registryFormData every time we enter this page
        this.clearFormData();
    },
    data() {
        return {
            companyGroup: "",
            answer1: "",
            answer2: "",
            answer3: "",
            fullName: Array(this.participantsCount),
            age: Array(this.participantsCount),
            genre: Array(this.participantsCount),
            state: Array(this.participantsCount),
            city: Array(this.participantsCount),
            email: Array(this.participantsCount),
            loading: false,
            errorSave: false,
            errorMessage: ""
        }
    },
    computed: {
        ...mapState(useUserStore, ['registryPaymentIntentId']),
    },
    methods: {
        ...mapActions(useUserStore, ['submitRegistryForm']),
        clearFormData() {
            const userStore = useUserStore();
            userStore.$patch({
                registryFormData: null
            })
        },

        validateFormData() {

            for (var index = 0; index < this.participantsCount; index++) {

                if (helpers.isEmpty(this.fullName[index])) {
                    this.$alert({
                        message: this.$t('errors.noNames'),
                        state: 'error',
                    });
                    return false
                }

                if (!validator.validateName(this.fullName[index])) {
                    this.$alert({
                        message: this.$t('errors.invalidName'),
                        state: 'error',
                    });
                    return false
                }

                if (!helpers.isEmpty(this.email[index]) && !validator.validateEmail(this.email[index])) {
                    this.$alert({
                        message: this.$t('errors.invalidEmail'),
                        state: 'error',
                    });
                    return false
                }
            }

            return true;
        },

        async saveFormData() {

            if (this.validateFormData()) {

                window.grecaptcha.enterprise.ready(async () => {

                    try {

                        this.loading = true;

                        const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.SAVE_REGISTRIES });
                        await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.SAVE_REGISTRIES, token, process.env.VUE_APP_RECAPTCHA_KEY)

                        const registries = []

                        for (var index = 0; index < this.participantsCount; index++) {
                            const registry = {
                                participantName: this.fullName[index],
                                age: (helpers.isEmpty(this.age[index]) || isNaN(this.age[index])) ? 0 : parseInt(this.age[index]),
                                genre: helpers.isEmpty(this.genre[index]) ? "Hombre" : this.genre[index],
                                state: this.state[index],
                                city: this.city[index],
                                email: this.email[index]
                            }
                            //console.log(registry)
                            registries.push({ ...registry })
                        }

                        const userStore = useUserStore();
                        userStore.$patch({
                            registryFormData: {
                                eventId: this.eventId,
                                registerTypeId: this.tdRegisterType.registerTypeId,
                                companyGroup: this.companyGroup,
                                answer1: this.answer1,
                                answer2: this.answer2,
                                answer3: this.answer3,
                                registryModels: registries
                            }
                        })



                        // For Registries, Save is always mandatory, so we call the Submission immediately after saving the Form data
                        await this.submitRegistryForm(this.registryPaymentIntentId)

                        this.loading = false;
                        this.$toast(this.$t('alerts.savedSuccessfully'))

                    } catch (error) {
                        this.loading = false;
                        this.errorSave = true;
                        this.errorMessage = error.mensaje;
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
h3 {
    text-align: center;
}

h4 {
    text-align: left;
}

.red {
    text-align: center;
    color: red;
}

.header-container {
    display: flex;
}


.icon-person {
    color: #666;
    margin-top: 16px;
    margin-right: 10px;
}

.assign-container {
    background: white;
    padding: 20px;
    text-align: center;
}

.textfield {
    width: 100%;
    margin-top: 10px;
}

.form-container {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px dotted #ccc;
}

.form-subcontainer {
    padding-top: 10px;
    padding-bottom: 20px;
}

.radio-button-container {
    text-align: left;
}

.radio-element {
    margin-left: 20px;
}

.textfield-half-width-left {
    width: 48%;
    float: left;
}

.textfield-half-width-right {
    width: 48%;
    float: right;
}

.icon-save {
    color: #652d66;
    float: right;
}

.save {
    width: 60%;
    height: 50px;
    margin-top: 10px;
}

.progress {
    margin-top: 20px;
}
</style>