export default {
  "common": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])},
    "selectOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information available"])}
  },
  "titles": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer - Where to dance?"])},
    "goToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to Ticket Dancer"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "personalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My purchases"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover your access to Ticket Dancer"])}
  },
  "myAccount": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred currency"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name(s)"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "createDisclosure1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ticket Dancer we would like to keep you updated via e-mail about events and promotions. You can change this setting in your \"Settings\" page. Consult our "])},
    "createDisclosure2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Spanish)."])},
    "createDisclosure3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare having read and accepted our "])},
    "createDisclosure4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use (Spanish)"])},
    "createUserConfirmation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to use the following email?"])},
    "createUserConfirmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot change your email later."])},
    "createUserNotification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["An email from Ticket Dancer was sent to ", _interpolate(_list(0)), " to verify your account. Please check your inbox (or Spam) and follow the instructions."])},
    "createUserButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "resendNewEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send new email"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a new password. Don't forget the password requirements."])},
    "reenterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter new password"])},
    "updateYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your personal information"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User information"])},
    "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced settings"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user has been updated"])},
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "deleteAccoountDisclosure1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete your account? All your information will be permanently deleted, including payment and purchase information."])},
    "deleteAccoountDisclosure2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This cannot be undone!"])},
    "successDeletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account was deleted successfully"])},
    "logoutConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You logged out successfully"])}
  },
  "currencies": {
    "mxn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇲🇽 - Mexican pesos"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇺🇸 - US dollars"])}
  },
  "forgoPassword": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot my password"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll send you a reset code to your email. Enter the email you used to register to Ticket Dancer."])},
    "instructions1button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have my reset code"])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email in the text field above and the code you received in your inbox here."])},
    "instructions2TF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code (single use)"])},
    "instructions2button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate code"])},
    "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you the access code to your email. In a phew moments you'll receive it in your inbox."])}
  },
  "events": {
    "allEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All events"])},
    "selectTicketsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your tickets"])},
    "durationDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["From ", _interpolate(_list(0)), " to ", _interpolate(_list(1))])},
    "loadMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more events..."])},
    "artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main artists"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image gallery"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "selectTicketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a ticket"])},
    "noTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't get any available tickets for this event. Please try later."])},
    "ticketIncludes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each ticket includes:"])},
    "ticketQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity:"])},
    "ticketTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total: ", _interpolate(_list(0))])},
    "showTotalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Show details"])},
    "hideTotalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Hide details"])},
    "pricePerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per ticket:"])},
    "feePerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service per ticket:"])},
    "pricePerRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per register:"])},
    "feePerRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service per register:"])}
  },
  "registries": {
    "selectRegistriesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Payments and Registrations"])},
    "noRegistries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't get any available payment or registration for this event. Please try later."])},
    "selectRegisterTypeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a Payment or a Registration"])},
    "selectRegisterTypeItemTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Registration of ", _interpolate(_list(0))])},
    "registrationIncludes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each registration includes:"])},
    "registriesSubtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal of registries:"])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Registry (payment) - ", _interpolate(_list(0)), " - ", _interpolate(_list(1))])},
    "fillInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the following information"])},
    "generalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General (optional)"])},
    "participantsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People to register"])},
    "allNamesRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least all full names are required*"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "genreH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "genreM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "shoppingCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Order"])},
    "noActivePO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have an active Purchase Order"])},
    "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Purchase Order..."])},
    "activeShoppingCartFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an active Purchase Order. Do you want to continue with the previous one or create a new?"])},
    "remainingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining time"])},
    "selectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment method"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete payment"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept of payment:"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity:"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
    "ticketSubtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal of tickets:"])},
    "feeSubtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal of service*:"])},
    "nonRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Service not refundable"])},
    "couponRedeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount applied:"])},
    "couponName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem"])},
    "redeemSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code applied"])},
    "removeCouponSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code removed successfully"])},
    "assignTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign your tickets"])},
    "companyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group or Dance company"])},
    "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ". Full assistant name"])},
    "allNamesRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All names are required*"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time ran out to complete your Order. Please add the tickets again."])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Purchase Order"])},
    "exitConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this Purchase Order?"])},
    "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards saved for this event"])},
    "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use another payment method"])},
    "addCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new card now"])},
    "addCardDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Needed to display any installments available"])},
    "cardholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder name"])}
  },
  "plans": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading loan installments available..."])},
    "plansAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Installments available for this card"])},
    "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select installment plan"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Only one payment of ", _interpolate(_list(0))])},
    "multiple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " monthly payments of ", _interpolate(_list(1))])},
    "msiAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0% interest loan installments available"])},
    "msiAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 0% interest loan installments available in all store"])},
    "fixed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " fixed payments"])},
    "msi": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " installemts with 0% interest"])}
  },
  "paymentStatus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase status"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THANK YOU!"])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "successTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your tickets are on their way. As soon as we confirm your payment you will receive them in your email.\nSee you in the best events!"])},
    "successRegistries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registrations are on their way. As soon as we confirm your payment you will receive them in your email.\nSee you in the best events!"])},
    "agreedButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreed"])},
    "oxxoButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See OXXO voucher"])},
    "bankTransferButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Bank Transfer details"])}
  },
  "purchases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchases made"])},
    "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past events"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming events"])},
    "ticketsOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "registriesOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Payments and Registries"])},
    "orderNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Order #: ", _interpolate(_list(0))])},
    "paymentDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Purchase date: ", _interpolate(_list(0))])},
    "goToEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to event"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR code(s)"])},
    "ticketsDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* This is merely informative is not valid as a Ticket. Present the tickets that were sent to your email or download them again by tapping the Download icon."])},
    "registriesDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* This is merely informative and is not valid as a Registration. Present the Registration Forms that were sent to your email or download them again by tapping the Download icon."])},
    "purchaseTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Purchase total: ", _interpolate(_list(0))])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])}
  },
  "transactions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeeded"])},
    "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
    "transactionOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction #"])},
    "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date (yyyy-MM-dd)"])},
    "transactionEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "transactionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
    "transactionTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "transactionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "transactionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])}
  },
  "bankTransfers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfers"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully paid"])},
    "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially paid"])},
    "emptyOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your payment so you can have an Order #"])},
    "lastUpdateDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Last update date: ", _interpolate(_list(0))])},
    "depositedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposited amount:"])},
    "remainingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining amount:"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank reference"])},
    "referenceLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to bank reference deposit details"])},
    "goToPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to My Purchases"])}
  },
  "footer": {
    "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "aboutText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ticket Dancer® is an integral platform that contains all the information about dance events, giving the promotors a chance to sell, distribute and spread their tickets, as well as rent advertising spaces within our app netowrk.\nTicket Dancer®, is the best option for dance events promotors, as well as the most secure and reliable choice for people who enjoy dance events.\n\nVersion: ", _interpolate(_list(0)), "\n\nLicenses: The icons used in this app were brought to you by Icons8 under Creative Commons Attribution-NoDerivs 3.0 Unported license. https://creativecommons.org/licenses/by-nd/3.0/\nhttps://iconos8.es"])},
    "linksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "linksMission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission & Vision (Spanish)"])},
    "linksPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy (Spanish)"])},
    "linksTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & conditions (Spanish)"])},
    "linksFaqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQS"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIGHTS RESERVED FOR TICKET DANCER ®"])}
  },
  "alerts": {
    "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danger"])},
    "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
    "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "updateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "stayButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "savedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information has been saved successfully"])}
  },
  "errors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again"])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost connection!"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])},
    "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be valid"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be at least 6 characters long, one capital letter, one lower capital letter and a number."])},
    "mismatchPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords don't match"])},
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is not information at this moment. Please try later"])},
    "noTicketNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all the tickets are assigned. Please assign and 'Save' the information of all the tickets"])},
    "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only letters are allowed in the Names of the assistants"])},
    "invalidPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed. Please try another payment method."])},
    "noNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name(s) required"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of your emails is invalid"])},
    "invalidAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age field requires an integer value"])},
    "noRegistryNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all the registries are assigned. Please assign and 'Save' the information of all the registries"])}
  },
  "questions": {
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is Ticket Dancer app?"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I register a new payment card?"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What Payment Methods does Ticket Dancer accept?"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What should I do if I have questions or problems with my banking operations?"])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I ask for an invoice of my purchase? (Only in Mexico)"])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What if I lost my ticket(s)?"])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I see the Privacy Policy?"])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I make a payment with a card which I'm not the holder's name of?"])},
    "q9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I see the Terms of Use?"])},
    "q10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Ticket Dancer for free?"])},
    "q11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I be sure that my sensitive data is safe?"])}
  },
  "answers": {
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer is the place where the best dance events coexist for you waiting to be discovered. With Ticket Dancer you can buy your tickets on the app every time, everywhere.But wait! Because in Ticket Dancer you also will find detail of all the activities, schedule, artists, locations and much more of all you favorite events."])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's super easy, when you are about to complete your order you can save your card information for future purchases."])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, in Ticket Dancer you can buy your tickets with the most credit and debit cards of your trust.\n\nCredit cards: VISA, Mastercard and American Express.\nDebit cards: HSBC, Scotiabank, CitiBanamex, BBVA Bancomer, Santander, IXE, Banco Azteca, Inbursa.\nCredit cards with monthly installments: BBVA Bancomer, CitiBanamex, Inbursa, Santander, Monex, Afirme, Banorte, BanRegio, HSBC, Scotiabank.\nOXXO* (Only in Mexico): You can pay in OXXO. You just have to select OXXO as a Payment Method and complete the payment in an OXXO establishment.\n*NOTE: You only have 3 days to complete the payment. After that, you will have to create a new payment intent."])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't worry. Ticket Dancer uses a sophisticated mechanism to monitor and keep safe all your payments.\n\nWithin Ticket Dancer app you can see the status of all your transactions, just go to \"Transaction History\" menu option of the home page.\n\nIf after all you still have troubles or doubts, don't hesitate to send us an email to soporte", "@", "ticketdancer.com.mx, giving us your full name, email (Ticket Dancer user email), phone number and all the detail you can give us about your problem. (If you can attach a screenshot, it would definitely help us to solve it quicker)."])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are in Mexico, it's possible to ask for the invoice of your purchase in no more that 48 hours after making the purchase.\n\nFor that, we need you to send and email to soporte", "@", "ticketdancer.com.mx, with the subject \"INVOICE REQUEST\"  and send us the email of the user that made the purchase, the order number and the details of your purchase."])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you lost your tickets you have up to 48 hours before your event starts to request us via email soporte", "@", "ticketdancer.com.mx to send you your tickets order ID.\n\nKeep in mind that the lost tickets not being claimed won't be able to enter. Also consider that you can only request for this process only once, so watch for your tickets!"])},
    "a7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the Footer you can find our Privacy Policy (spanish)."])},
    "a8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of course. But don't misuse this option unless your couple had a bad day and you need to lift his/her mood with two ticktes of his favorite congress..."])},
    "a9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the Footer you can find our Terms of Use (spanish)."])},
    "a10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good things in life comes for free, and Ticket Dancer is NOT the exception."])},
    "a11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer will never storage your private banking data. Besides, we use the best Industry tools that allow us to keep all the banking operations safe and encrypted."])}
  }
}