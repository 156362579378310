<template>
    <div>
        <ui-card elevated class="event-card">
            <ui-card-content class="event-card-content" @click.prevent="goToEventDetail">
                <img :src="withEvent.urlBanner" />
                <h3> {{ withEvent.name }} </h3>
                <p>
                    {{ $t('events.durationDate', [shortStartDate, shortEndDate]) }}
                </p>
                <label v-show="withEvent.msiEnabled" class="msi">
                    {{ $t('plans.msiAll') }}
                </label>
            </ui-card-content>
            <ui-card-actions class="event-card-actions">
                <ui-button class="event-card-button" id="registry-button" @click.prevent="goToSelectRegisterType">
                    {{ $t('registries.selectRegistriesButton') }}
                    <template #after>
                        <ui-icon>request_quote</ui-icon>
                    </template>
                </ui-button>
                <ui-button class="event-card-button" @click.prevent="goToSelectTicket">
                    {{ $t('events.selectTicketsButton') }}
                    <template #after>
                        <ui-icon>confirmation_number</ui-icon>
                    </template>
                </ui-button>
            </ui-card-actions>
        </ui-card>
    </div>
</template>

<script>
import dateUtils from '@/utils/dateUtils'

export default {
    name: "TdEvent",
    props: {
        withEvent: Object
    },
    computed: {
        shortStartDate() {
            return dateUtils.getLocaleDateFromString(this.withEvent.startDate);
        },
        shortEndDate() {
            return dateUtils.getLocaleDateFromString(this.withEvent.endDate);
        }
    },
    methods: {
        goToEventDetail() {
            //console.log(this.withEvent.id)
            this.$router.push({
                name: 'eventDetail',
                params: {
                    eventId: this.withEvent.id
                }
            });
        },
        goToSelectTicket() {
            this.$router.push({
                name: 'selectTicket',
                params: {
                    eventId: this.withEvent.id
                }
            });
        },
        goToSelectRegisterType() {
            this.$router.push({
                name: 'selectRegisterType',
                params: {
                    eventId: this.withEvent.id
                }
            });
        }
    }
}
</script>

<style scoped>
.event-card {
    margin: auto;
    height: 400px;
}

.event-card-content {
    padding: 8px;
}

.event-card-content img {
    width: 100%;
    height: 200px;
    display: block;
    margin: auto;
}

.event-card-content p {
    text-align: justify;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em
}

.event-card-actions {
    justify-content: flex-end;
}

.event-card-button {
    margin: 0px 4px;
    font-size: 0.65em
}

.msi {
    font-size: small;
    font-weight: bold;
    text-align: right;
    margin-right: 10px;
    color: #3156d5;
}
</style>