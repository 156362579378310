<template>
  <img id="logo" alt="Logo de Ticket Dancer" src="@/assets/isologo-td-color.png" width="250" />
  <h5>Baila con confianza, lúcete con Ticket Dancer ®</h5>
  <h1>{{ $t("titles.enter") }}</h1>
  <external-sign-in />
  <ui-grid id="container">
    <ui-grid-cell columns="6">
      <login-form />
    </ui-grid-cell>
    <ui-grid-cell columns="6">
      <create-user-form />
    </ui-grid-cell>
  </ui-grid>
</template>
<script>
import LoginForm from "@/components/user/LoginForm.vue";
import CreateUserForm from "@/components/user/CreateUserForm.vue";
import ExternalSignIn from "@/components/user/ExternalSignIn.vue";
import { useMainStore } from "@/stores/main";
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';

export default {
  name: "LoginCreate",
  components: {
    LoginForm,
    CreateUserForm,
    ExternalSignIn
  },
  mounted() {

    if (window.grecaptcha) {

      window.grecaptcha.enterprise.ready(async () => {

        try {
          const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.PAGE_LOAD_LOGIN_CREATE });
          await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.PAGE_LOAD_LOGIN_CREATE, token, process.env.VUE_APP_RECAPTCHA_KEY)
        }
        catch (error) {
          console.error(error)
          this.$router.push("/not-found")
        }
      });
    }

    const mainStore = useMainStore();
    mainStore.pageTitle = this.$t("titles.enter");
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
  margin-top: 2em;
}

h5 {
  text-align: center;
  margin-top: 0em;
}

#container {
  max-width: 80%;
}

#logo {
  display: block;
  margin: auto;
}
</style>