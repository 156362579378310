<template>
  <main-swiper></main-swiper>
  <all-events-grid></all-events-grid>
</template>
<script>
import MainSwiper from "@/components/home/MainSwiper.vue";
import AllEventsGrid from "@/components/home/AllEventsGrid.vue";

export default {
  name: "homeView",
  components: { MainSwiper, AllEventsGrid }
};
</script>
<style lang="">
</style>