import { URLS } from "@/utils/constants";
import { useUserStore } from "@/stores/user";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";

export default {

    async getPaymentBankTransfers(complete, page, pageSize) {

        let capcha = await commonsApi.getCaptcha();

        const userStore = useUserStore();

        let response = await fetch(URLS.PAYMENT_BANK_TRANSFERS + "?complete=" + complete + "&page=" + page + "&size=" + pageSize, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: userStore.userLogged.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentBankTransfers = await response.json();
            return paymentBankTransfers;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }

    },

    async getRegistryPaymentBankTransfers(complete, page, pageSize) {

        let capcha = await commonsApi.getCaptcha();

        const userStore = useUserStore();

        let response = await fetch(URLS.REGISTRY_PAYMENT_BANK_TRANSFERS + "?complete=" + complete + "&page=" + page + "&size=" + pageSize, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: userStore.userLogged.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryPaymentBankTransfers = await response.json();
            return registryPaymentBankTransfers;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }

    },
}