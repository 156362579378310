import { useMainStore } from "@/stores/main"

export default {
    getCurrencyFromNumber(total, currency) {

        const mainStore = useMainStore();
        var localeString = 'es-MX'
        if (mainStore.defaultLanguage === 'en') {
            localeString = 'en-US'
        }

        return total.toLocaleString(localeString, { style: 'currency', currency: currency.toUpperCase(), maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
}