<template>
    <ui-skeleton :loading="ticketsLoading" active :paragraph="{ rows: 15 }">
        <div class="content">
            <h1>{{ $t('events.selectTicketsButton') }}</h1>
            <image-text-header :image-url="tdEvent.urlBanner" :description="tdEvent.description"></image-text-header>
            <error-component v-show="errorLoad" :message="errorMessage" />
            <ui-divider class="divider">{{ $t('events.selectTicketTitle') }}</ui-divider>
            <p v-if="!withTickets">
                {{ $t('events.noTickets') }}
            </p>
            <template v-else>
                <ticket-selection class="item" v-for="ticket in availableTickets" :key="ticket.ticketId"
                    :image-url="tdEvent.urlLogo" :ticket="ticket" :event-id="tdEvent.id">
                </ticket-selection>
            </template>
        </div>
    </ui-skeleton>
</template>

<script>

import eventsApi from '@/api/EventsApi';
import { mapState } from 'pinia';
import { useMainStore } from '@/stores/main';
import { useUserStore } from '@/stores/user';
import { helpers } from 'balm-ui'
import ErrorComponent from '@/components/common/ErrorComponent.vue';
import TicketSelection from '@/components/tickets/TicketSelection.vue';
import ImageTextHeader from '@/components/common/ImageTextHeader.vue';
import { analytics, logEvent } from "@/utils/firebase"

export default {
    name: "SelectTicketView",
    components: { TicketSelection, ErrorComponent, ImageTextHeader },
    data() {
        return {
            tdEvent: Object,
            availableTickets: [],
            ticketsLoading: false,
            errorLoad: false,
            errorMessage: "",
        }
    },
    computed: {
        ...mapState(useUserStore, ['userLogged']),
        withTickets() {
            return this.availableTickets.length > 0
        }
    },
    async mounted() {
        await this.loadTickets(this.$route.params.eventId)
    },
    methods: {
        async loadTickets(eventId) {
            this.ticketsLoading = true
            this.errorLoad = false

            try {

                const userStore = useUserStore()
                const mainStore = useMainStore()

                this.tdEvent = await eventsApi.getEventById(eventId)

                if (this.tdEvent) {

                    //console.log(this.tdEvent)

                    var currency = 'mxn'
                    if (helpers.isEmpty(userStore.userLogged)) {
                        currency = mainStore.defaultLanguage === 'es' ? 'mxn' : 'usd'
                    } else {
                        currency = userStore.userLogged.preferredCurrency
                    }

                    //console.log(currency)

                    const ticketsResponse = await eventsApi.getTicketsByEventId(currency, eventId)
                    this.availableTickets = [...ticketsResponse.boletos]
                    mainStore.$patch({ pageTitle: this.tdEvent.name })

                    logEvent(analytics, 'view_tickets', {
                        event_id: this.tdEvent.id,
                        event_name: this.tdEvent.name
                    })

                    this.ticketsLoading = false;

                } else {
                    // Event not found
                    this.$router.push("/not-found")
                }

            } catch (error) {
                this.ticketsLoading = false;
                // API or Network Error
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
            }
        },
    }
}
</script>

<style scoped>
h1 {
    text-align: center;
}

.divider {
    max-width: 85%;
    margin: auto;
}

.item {
    margin-bottom: 20px;
}

.content p {
    text-align: center;
}

.creating-shopping-cart-container {
    text-align: center;
}

.creating-shopping-cart-text {
    font-size: large;
}
</style>