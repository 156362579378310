<template>
    <ui-skeleton :loading="registriesLoading" active :paragraph="{ rows: 15 }">
        <div class="content">
            <h1>{{ $t('registries.selectRegistriesButton') }}</h1>
            <image-text-header :image-url="tdEvent.urlBanner" :description="tdEvent.description"></image-text-header>
            <ui-divider class="divider">{{ $t('registries.selectRegisterTypeTitle') }}</ui-divider>
            <template v-if="withRegistries">
                <register-type-selection class="item" v-for="registerType in availableRegistries"
                    :key="registerType.registerTypeId" :event-id="tdEvent.id" :image-url="tdEvent.urlLogo"
                    :register-type="registerType">
                </register-type-selection>
            </template>
            <p v-else>
                {{ $t('registries.noRegistries') }}
            </p>
        </div>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </ui-skeleton>
</template>

<script>

import eventsApi from '@/api/EventsApi';
import { useMainStore } from '@/stores/main';
import { useUserStore } from '@/stores/user';
import { helpers } from 'balm-ui'
import ErrorComponent from '@/components/common/ErrorComponent.vue';
import RegisterTypeSelection from '@/components/registries/RegistrerTypeSelection.vue';
import ImageTextHeader from '@/components/common/ImageTextHeader.vue';
import { analytics, logEvent } from "@/utils/firebase"

export default {
    name: "SelectRegisterTypeView",
    components: { RegisterTypeSelection, ErrorComponent, ImageTextHeader },
    data() {
        return {
            tdEvent: Object,
            availableRegistries: [],
            registriesLoading: false,
            errorLoad: false,
            errorMessage: "",
        }
    },
    computed: {
        withRegistries() {
            return this.availableRegistries.length > 0
        }
    },
    async mounted() {
        await this.loadRegistries(this.$route.params.eventId)
    },
    methods: {
        async loadRegistries(eventId) {
            this.registriesLoading = true
            this.errorLoad = false

            try {

                const userStore = useUserStore()
                const mainStore = useMainStore()

                this.tdEvent = await eventsApi.getEventById(eventId)

                if (this.tdEvent) {

                    var currency = 'mxn'
                    if (helpers.isEmpty(userStore.userLogged)) {
                        currency = mainStore.defaultLanguage === 'es' ? 'mxn' : 'usd'
                    } else {
                        currency = userStore.userLogged.preferredCurrency
                    }

                    const registriesResponse = await eventsApi.getRegistriesByEventId(currency, eventId)
                    this.availableRegistries = [...registriesResponse]
                    mainStore.$patch({ pageTitle: this.tdEvent.name })

                    logEvent(analytics, 'view_registers', {
                        event_id: this.tdEvent.id,
                        event_name: this.tdEvent.name
                    })

                    this.registriesLoading = false;

                } else {
                    // Event not found
                    this.$router.push("/not-found")
                }

            } catch (error) {
                this.registriesLoading = false;
                // API or Network Error
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
            }
        }
    }
}
</script>

<style scoped>
h1 {
    text-align: center;
}

.divider {
    max-width: 85%;
    margin: auto;
}

.item {
    margin-bottom: 20px;
}

.content p {
    text-align: center;
}
</style>