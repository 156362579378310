import { defineStore } from "pinia"
import { LS_KEYS } from "@/utils/constants"
import { selectedLocale } from '@/i18n'

export const useMainStore = defineStore("main", {
  state: () => {
    return {
      pageTitle: "",
      defaultLanguage: selectedLocale
    }
  },
  actions: {
    updateDefaultLanguage(newLanguage) {
      localStorage.setItem(LS_KEYS.USER_DEFAULT_LOCALE, newLanguage);
      this.defaultLanguage = newLanguage
    },
  }
})
