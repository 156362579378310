<template>
    <h1>{{ $t('myAccount.updateYourAccount') }}</h1>
    <user-data />
</template>
<script>
import UserData from "@/components/user/UserData.vue";
import { useMainStore } from "@/stores/main";

export default {
    name: "PerfilUsuario",
    components: {
        UserData,
    },
    mounted() {
        const mainStore = useMainStore();
        mainStore.pageTitle = this.$t("titles.personalSettings");
    },
};
</script>

<style scoped>
h1 {
    text-align: center;
}
</style>