<template>
  <div>
    <h2>Oops... {{ $t("errors.notFound") }} :(</h2>
  </div>
</template>
<script>
import { useMainStore } from "@/stores/main";

export default {
  name: "NoContentView",
  mounted() {
    const mainStore = useMainStore();
    mainStore.pageTitle = this.$t("errors.notFound");
  },
};
</script>
<style scoped>
h2 {
  text-align: center;
  margin-top: 2em;
}
</style>