// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    authDomain: "ticket-dancer.firebaseapp.com",
    projectId: "ticket-dancer",
    storageBucket: "ticket-dancer.appspot.com",
    messagingSenderId: "126634753738",
    appId: "1:126634753738:web:6039d8aefe0308e4f7fe33",
    measurementId: "G-PFPZ60J3LG"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export {
    analytics
}

export {
    logEvent
}