import { createI18n } from 'vue-i18n'
import { LS_KEYS } from '@/utils/constants'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function setDefaulLocale() {
  var defLang = localStorage.getItem(LS_KEYS.USER_DEFAULT_LOCALE)
  if (defLang === null) {
    if (/^en\b/.test(navigator.language)) {
      defLang = "en"
    } else {
      defLang = "es"
    }
    localStorage.setItem(LS_KEYS.USER_DEFAULT_LOCALE, defLang);
  }
  return defLang
}

export const selectedLocale = setDefaulLocale() || process.env.VUE_APP_I18N_LOCALE || 'es'

export default createI18n({
  legacy: false,
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  globalInjection: true
})
