<template>
    <div>
        <ui-card elevated class="main-card">
            <ui-card-content class="main-card-content" @click="open = true">
                <img :src="url" />
                <p>
                    {{ description }}
                </p>
            </ui-card-content>
        </ui-card>

        <ui-dialog v-model="open" fullscreen>
            <ui-dialog-content>
                {{ description }}
            </ui-dialog-content>
            <ui-dialog-actions>
                <ui-button @click="open = false">OK</ui-button>
            </ui-dialog-actions>
        </ui-dialog>
    </div>
</template>

<script>
export default {
    name: "TdPromotion",
    props: {
        url: String,
        description: String
    },
    data() {
        return {
            open: false
        };
    }
}
</script>

<style scoped>
.main-card {
    width: 100%;
    height: auto;
}

.main-card-content {
    height: 720px;
    padding: 4px;
}

.main-card-content img {
    width: auto;
    max-width: 100%;
    max-height: 450px;
    height: auto;
    margin: 0 auto;
    display: block;
}

.main-card-content p {
    height: 110px;
    text-align: justify;
}
</style>