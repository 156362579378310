import { helpers } from 'balm-ui'; // Default Usage
// OR
// import helpers from 'balm-ui/utils/helpers'; // ### Individual Usage
import i18n from '@/i18n';
import validator from '@/utils/validator';

export default {
  required: {
    validate(value) {
      return !helpers.isEmpty(value);
    },
    message: '%s ' + i18n.global.t('errors.required')
  },
  tdPassValidator: {
    validate(value) {
      var result = validator.validatePassword(value);
      return result;
    },
    message: "%s " + i18n.global.t('errors.invalidPassword'),
  },
  tdEmailValidator: {
    validate(value) {
      var result = validator.validateEmail(value)
      return result;
    },
    message: "%s " + i18n.global.t('errors.valid'),
  },
}
