<template>
    <ui-card outlined>
        <div :class="[$tt('subtitle2'), 'card-heading']">
            <div class="left">
                {{ (bankTransfer.orderId) ? $t('purchases.orderNumber', [bankTransfer.orderId]) : $t('bankTransfers.emptyOrder') }}
            </div>
            <div class="right">
                {{ formattedDate }}
            </div>
        </div>
        <ui-list-divider></ui-list-divider>
        <a v-ripple class="card-content">
            <h3 :class="[$tt('headline6'), 'card-content__title']">
                {{ bankTransfer.eventName }}
            </h3>
        </a>
        <div class="info-content">
            <div class="name-quantity-container">
                <ui-icon class="name-quantity-item" size="24" outlined>{{ icon }}</ui-icon>
                <div class="name-quantity-item">X</div>
                <div class="name-quantity-item">{{ quantity }}</div>
                <div class="name-quantity-item">{{ name }}</div>
            </div>
            <ui-grid>
                <ui-grid-cell columns="4">
                    <h3 class="cell-text">
                        {{ $t('bankTransfers.depositedAmount') }}
                    </h3>
                    <h2 class="cell-text">
                        {{ depositedAmount }}
                    </h2>
                </ui-grid-cell>
                <ui-grid-cell columns="4">
                    <h3 class="cell-text">
                        {{ $t('bankTransfers.remainingAmount') }}
                    </h3>
                    <h2 class="cell-text">
                        {{ remainingAmount }}
                    </h2>
                </ui-grid-cell>
                <ui-grid-cell columns="4">
                    <h3 class="cell-text">
                        {{ $t('bankTransfers.reference') }}
                    </h3>
                    <h2 class="cell-text" :class="referenceStyle">
                        {{ bankTransfer.complete ? $t('bankTransfers.completed') : bankTransfer.reference}}
                    </h2>
                </ui-grid-cell>
            </ui-grid>
        </div>
        <ui-list-divider></ui-list-divider>
        <ui-card-actions>
            <ui-card-icons>
                <ui-button @click.prevent="goToPurchases" v-if="bankTransfer.complete">
                    {{ $t('bankTransfers.goToPurchases') }}
                    <template #after>
                        <ui-icon>arrow_forward</ui-icon>
                    </template>
                </ui-button>
                <ui-button @click.prevent="goToBankReference" v-else>
                    {{ $t('bankTransfers.referenceLink') }}
                    <template #after>
                        <ui-icon>arrow_forward</ui-icon>
                    </template>
                </ui-button>
            </ui-card-icons>
        </ui-card-actions>
    </ui-card>
</template>

<script>

import { useMainStore } from "@/stores/main";
import { mapState } from 'pinia';
import dateUtils from '@/utils/dateUtils'
import numberUtils from '@/utils/numberUtils'
import { helpers } from "balm-ui";

export default {
    name: "BankTransferItem",
    props: {
        bankTransfer: Object
    },
    computed: {
        ...mapState(useMainStore, ['defaultLanguage']),
        formattedDate() {
            const date = dateUtils.getLocaleDateFromString(this.bankTransfer.lastPaymentDate)
            return this.$t('bankTransfers.lastUpdateDate', [date[0].toUpperCase() + date.slice(1)]);
        },
        icon() {
            return this.bankTransfer.type === 'TICKET' ? "confirmation_number" : "request_quote"
        },
        quantity() {
            return this.bankTransfer.type === 'TICKET' ? this.bankTransfer.ticketsNum : this.bankTransfer.registersNum
        },
        name() {
            return this.bankTransfer.type === 'TICKET' ? this.bankTransfer.ticketName : ((this.defaultLanguage === 'es' ? this.purchase.registerTypeTitle : this.purchase.registerTypeTitleEn) + ": " + this.purchase.registerTypeName)
        },
        depositedAmount() {
            return helpers.isEmpty(this.bankTransfer.depositedAmount) ? '-.--' : numberUtils.getCurrencyFromNumber(this.bankTransfer.depositedAmount, this.bankTransfer.currency)
        },
        remainingAmount() {
            return helpers.isEmpty(this.bankTransfer.remainingAmount) ? '-.--' : numberUtils.getCurrencyFromNumber(this.bankTransfer.remainingAmount, this.bankTransfer.currency)
        },
        referenceStyle() {
            return this.bankTransfer.complete ? 'completed' : ''
        }
    },
    methods: {
        goToBankReference() {
            if (!helpers.isEmpty(this.bankTransfer.transferLink)) {
                window.open(this.bankTransfer.transferLink, "_blank");
            }
        },
        goToPurchases() {
            this.$router.push("/compras")
        }
    }
}
</script>

<style scoped>
.card-heading {
    padding: 8px 16px;
}

.left {
    float: left;
    font-weight: bolder;
}

.right {
    float: right;
    color: #652d66;
}

.card-content {
    padding: 16px;
    text-decoration: none;
    color: inherit;
}

.card-content__title {
    margin: 0 0 0 0;
}

.info-content {
    margin-bottom: 10px;
    margin-left: 10px;
}

.name-quantity-item {
    display: inline;
    margin: 4px;
    vertical-align: middle;
}

.cell-text {
    text-align: center;
}

.completed {
    color: #006600;
}
</style>