<template>
    <div class="details-container">
        <h3> {{ $t('shoppingCart.details') }}</h3>
        <div class="row">
            <div class="label">
                {{ $t('shoppingCart.concept') }}
            </div>
            <div class="value">
                {{ concept }}
            </div>
        </div>
        <div class="row">
            <div class="label">
                {{ $t('shoppingCart.quantity') }}
            </div>
            <div class="value">
                {{ quantityText }}
            </div>
        </div>
        <div class="row">
            <div class="label">
                {{ $t('shoppingCart.description') }}
            </div>
            <div class="value" v-html="description">
            </div>
        </div>
        <div class="total">
            {{ total }}
            <br>
            <checkout-total-details :is-ticket="isTicket" :payment-subtotal="paymentSubtotal"
                :service-subtotal="serviceSubtotal" :coupon-amount="couponAmount"
                :currency="currency"></checkout-total-details>
        </div>
    </div>
</template>

<script>
import numberUtils from '@/utils/numberUtils'
import CheckoutTotalDetails from '../common/CheckoutTotalDetails.vue'

export default {
    name: 'PaymentDetails',
    props: {
        isTicket: Boolean,
        concept: String,
        quantity: Number,
        description: String,
        totalAmount: Number,
        paymentSubtotal: Number,
        couponAmount: Number,
        serviceSubtotal: Number,
        currency: String
    },
    components: {
        CheckoutTotalDetails
    },
    computed: {
        quantityText() {
            return "x " + this.quantity
        },
        total() {
            let totalStr = numberUtils.getCurrencyFromNumber(this.totalAmount, this.currency)
            return this.$t('events.ticketTotal', [totalStr])
        },
    }
}
</script>

<style scoped>
h3 {
    text-align: center;
}

.details-container {
    background: white;
    padding: 20px;
}

.row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.row .label {
    font-weight: bolder;
}

.row .value {
    white-space: pre-wrap;
}

.total {
    text-align: right;
    font-size: x-large;
    font-weight: bolder;
}
</style>