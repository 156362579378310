<template>
    <ui-button class="details" @click="toogleDetails"> {{ detailsText }} </ui-button>
    <div v-show="showDetails">
        <div>
            <div class="label">
                {{ priceLbl }}
            </div>
            <div class="amount">
                {{ priceDescr }}
            </div>
        </div>
        <div>
            <div class="label">
                {{ serviceLbl }}
            </div>
            <div class="amount">
                {{ serviceDescr }}
            </div>
        </div>
    </div>
</template>

<script>

import numberUtils from '@/utils/numberUtils'

export default {
    name: "PreviewTotalDetails",
    props: {
        isTicket: Boolean,
        price: Number,
        commission: Number,
        currency: String
    },
    data() {
        return {
            showDetails: false,
        }
    },
    computed: {
        detailsText() {
            return this.showDetails ? this.$t('events.hideTotalDetails') : this.$t('events.showTotalDetails')
        },
        priceLbl() {
            return this.isTicket ? this.$t('events.pricePerTicket') : this.$t('events.pricePerRegistry')
        },
        serviceLbl() {
            return this.isTicket ? this.$t('events.feePerTicket') : this.$t('events.feePerRegistry')
        },
        priceDescr() {
            return numberUtils.getCurrencyFromNumber(this.price, this.currency)
        },
        serviceDescr() {
            return numberUtils.getCurrencyFromNumber(this.commission, this.currency)
        }
    },
    methods: {
        toogleDetails() {
            this.showDetails = !this.showDetails
        },
    }
}
</script>

<style scoped>
button.details {
    font-size: small;
}

.label {
    width: 110px;
    float: left;
    font-size: 0.7em;
    font-weight: bolder;
}

.amount {
    width: 90px;
    float: right;
    font-size: 0.7em;
    text-align: right;
}
</style>