import { useMainStore } from "@/stores/main"

const longDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export default {

    getLocaleDateFromString(dateString) {

        const mainStore = useMainStore();

        return new Date(dateString.replace(/-/g, "/")).toLocaleDateString(mainStore.defaultLanguage, longDateOptions);
    }
}