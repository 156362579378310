<template>
    <ui-skeleton :loading="slidesLoading" active :paragraph="{ rows: 20 }">
        <swiper class="main-swiper" :modules="modules" :slides-per-view="1" :space-between="30" navigation>
            <swiper-slide>
                <ui-card elevated class="main-slider">
                    <ui-card-content class="main-card-content">
                        <div class="video-content">
                            <video-player :options="videoOptions" class="video-player" />
                            <img alt="Logo de Ticket Dancer" src="@/assets/isologo-td-color.png" width="10"
                                class="video-img" />
                            <p class="video-text">
                                Baila con confianza, lúcete con Ticket Dancer ®
                            </p>
                        </div>
                    </ui-card-content>
                </ui-card>
            </swiper-slide>
            <swiper-slide v-show="hasPromotionsEvents" v-for="(promotionEvent, index) in promotionEvents"
                :key="promotionEvent.id">
                <td-promotion v-if="index < numberOfPromotions" :url="promotionEvent.imageUrlWeb"
                    :description="promotionEvent.description" class="main-slider">
                </td-promotion>
                <td-main-event v-else :with-event="promotionEvent" class="main-slider"></td-main-event>
            </swiper-slide>
        </swiper>
    </ui-skeleton>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import VideoPlayer from '@/components/common/VideoPlayer.vue'
import TdPromotion from './TdPromotion.vue';
import TdMainEvent from './TdMainEvent.vue';
import eventsApi from '@/api/EventsApi';

export default {
    name: "MainSwiper",
    components: { Swiper, SwiperSlide, VideoPlayer, TdPromotion, TdMainEvent },
    data() {
        return {
            promotionEvents: [],
            slidesLoading: true,
            numberOfPromotions: 0,
            modules: [Navigation],
            videoOptions: {
                autoplay: 'any',
                controls: true,
                loop: true,
                aspectRatio: '16:9',
                sources: [
                    {
                        src: 'td.mp4',
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    computed: {
        hasPromotionsEvents() {
            return this.promotionEvents.length > 0
        }
    },
    async mounted() {
        await this.loadBanner()
    },
    methods: {
        async loadBanner() {

            try {
                const promotions = await eventsApi.getPromotions();
                const mainEvents = await eventsApi.getMainEvents();

                //console.log(promotions)
                //console.log(mainEvents)

                this.slidesLoading = false

                this.promotionEvents = [...promotions, ...mainEvents]
                this.numberOfPromotions = promotions.length

                //console.log(this.promotionEvents)

            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
    color: #ffffff;
}

.main-swiper {
    background: url('@/assets/header-bg.png');
    padding: 24px;
}

.main-slider {
    width: 65%;
    max-width: 1280px;
    max-height: 750px;
    margin: auto;
}

.video-content {
    position: relative;
}

.video-player {
    position: relative;
    top: 0;
}

.video-img {
    width: 200px;
    max-width: 200px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.video-text {
    font-weight: 600;
    font-size: large;
    text-align: center;
}
</style>