<template>
    <ui-card outlined>
        <ui-card-content>
            <div class="container">
                <h2 style="text-align: center;" class="container-element">{{ $t('events.gallery') }}</h2>
                <ui-spinner class="spinner" active v-show="loadingImages"></ui-spinner>
                <ui-grid class="gallery" v-if="withImages">
                    <ui-grid-cell align="middle" columns="4" v-for="(galleryImage, index) in galleryImageUrls"
                        :key="index">
                        <img class="item" :src="galleryImage" />
                    </ui-grid-cell>
                </ui-grid>
                <p class="container-element" v-else>
                    {{ $t('errors.noInfo') }}
                </p>
            </div>
        </ui-card-content>
    </ui-card>
</template>

<script>
import eventsApi from '@/api/EventsApi';

export default {
    name: "TdGallery",
    props: {
        eventId: String
    },
    data() {
        return {
            galleryImageUrls: [],
            loadingImages: false,
        }
    },
    async mounted() {
        //console.log("mounted: " + this.eventId)
        await this.loadGallery();
    },
    computed: {
        withImages() {
            return this.galleryImageUrls.length > 0
        }
    },
    methods: {
        async loadGallery() {
            if (this.eventId) {

                //console.log("Loading Images of EventId: " + this.eventId)

                this.loadingImages = true
                try {
                    this.galleryImageUrls = await eventsApi.listGalleryImages(this.eventId)
                    this.loadingImages = false
                } catch (error) {
                    console.log(error)
                    this.loadingImages = false
                }
            }
        }
    }
}
</script>
<style scoped>
.container {
    position: relative;
}

.spinner {
    position: absolute;
    margin: auto;
    top: 120px;
    left: 0;
    right: 0;
}

.container-element {
    margin: 10px 30px;
}

.gallery {
    max-width: 1280px;
    margin: 0 auto;
}

.item {
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>