<template>
  <top-bar>
    <router-view />
  </top-bar>
  <footer id="mainFooter">
    <ui-grid>
      <ui-grid-cell class="footer-cell" columns="6">
        <h4>{{ $t('footer.aboutTitle') }}</h4>
        <p>
          {{ $t('footer.aboutText', [version]) }}
        </p>
      </ui-grid-cell>
      <ui-grid-cell class="footer-cell" columns="3">
        <h4>{{ $t('footer.linksTitle') }}</h4>
        <ul>
          <li>
            <router-link class="footer-link" to="/mision-vision">{{ $t('footer.linksMission') }}</router-link>
          </li>
          <li>
            <router-link class="footer-link" to="/aviso-privacidad">{{ $t('footer.linksPolicy') }}</router-link>
          </li>
          <li>
            <router-link class="footer-link" to="/condiciones-uso">{{ $t('footer.linksTerms') }}</router-link>
          </li>
          <li>
            <router-link class="footer-link" to="/faqs">{{ $t('footer.linksFaqs') }}</router-link>
          </li>
        </ul>
      </ui-grid-cell>
      <ui-grid-cell class="footer-cell" columns="3">
        <h4>{{ $t('footer.contactTitle') }}</h4>
        <a href="https://www.instagram.com/ticketdancer" target="_blank " title="Instagram"><img class="footer-icon"
            alt="Siguenos en Instagram" src="@/assets/instagram.png" /></a>
        <a href="https://www.fb.me/TicketDancer" target="_blank" title="Facebook"><img class="footer-icon"
            alt="Siguenos en Facebook" src="@/assets/facebook.png" /></a>
        <a href="https://twitter.com/ticketdancer" target="_blank " title="Twitter"><img class="footer-icon"
            alt="Siguenos en Twitter" src="@/assets/twitter.png" /></a>
        <a href="mailto:soporte@ticketdancer.com.mx" target="_blank " title="Email"><img class="footer-icon"
            alt="Soporte de Ticket Dancer" src="@/assets/email.png" /></a>
      </ui-grid-cell>
    </ui-grid>
    <ui-divider class="footer-divider">{{ $t('footer.rights') }}, {{ new Date().getFullYear() }}.</ui-divider>
  </footer>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/common/TopBar.vue";

export default {
  name: "App",
  components: {
    TopBar,
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${process.env.VUE_APP_RECAPTCHA_KEY}`)
    document.head.appendChild(recaptchaScript)
  },
  data() {
    return {
      version: process.env.VUE_APP_TD_VERSION
    }
  }
};
</script>

<style>
html {
  --mdc-typography-font-family: "Nunito", sans-serif;
}

body {
  display: block;
  margin: 0px;
  background: #eee;
}

label {
  font-family: "Nunito", sans-serif;
}

#app {
  font-family: "Nunito", sans-serif;
}

#mainFooter {
  margin-top: 20px;
  color: #ccc;
  background: #000000cc;
}

#mainFooter h5 {
  text-align: center;
}

#mainFooter p {
  text-align: justify;
  font-size: 0.7em;
  white-space: pre-wrap;
}

#mainFooter ul {
  font-size: smaller;
}

.footer-cell {
  padding: 20px;
}

.footer-link {
  color: #ccc;
}

.footer-divider {
  color: #ccc;
  font-size: small;
}

.footer-icon {
  height: 40px;
  width: 40px;
  margin: 5px;
}
</style>
