<template>
    <h1>{{ $t('footer.linksPolicy') }}</h1>
    <div v-shape class="container">
        <p>
            Ticket Dancer, en cumplimiento a lo establecido en la Ley Federal de Datos Personales en Posesión de los
            Particulares, tiene entre sus objetivos la protección de la información personal proporcionada por cada
            persona (la “Información”). Por lo anterior, Ticket Dancer ha establecido los siguientes lineamientos para
            proteger dicha información.
        </p>
        <h2>1. Información Personal Solicitada</h2>
        <p>Ticket Dancer solicitará información de carácter personal a los usuarios de los servicios, titulares de
            información personal, con el objeto de poder brindar la mejor experiencia personalizada en el uso de los
            servicios que Ticket Dancer proporciona, para a la medida de sus posibilidades hacerte llegar a discreción
            de Ticket Dancer mensajes que puedan ser de tu interés, ya sean propios o bien de nuestros anunciantes y
            publicidad de terceros con quién Ticket Dancer tenga celebrados convenios. Con el objetivo de brindarte una
            mejor experiencia, por ello y para fines de calidad en el servicio te enviaremos una encuesta de
            satisfacción. El tipo de información que se solicita a los usuarios de los servicios incluye: nombre,
            dirección de correo electrónico (e-mail), género, edad e información de tarjetas de crédito; no toda la
            Información que se solicite al momento de registrarse a los servicios de Ticket Dancer es obligatoria de
            proporcionarse, salvo aquella que Ticket Dancer considere conveniente y que así se le haga saber al titular
            al momento del registro.
        </p>
        <p>
            Ticket Dancer solicitará dicha Información para poder realizar la venta de boletos al titular o cuando
            considere sea oportuno o necesario como por ejemplo, cuando el titular quiera participar en concursos o
            promociones patrocinadas por Ticket Dancer o por terceros, en las encuestas puestas en práctica para evaluar
            y/o proporcionar de manera más satisfactoria los servicios, cuando los usuarios quieran hacer una compra,
            etc. La información personal que Ticket Dancer recolecta podrá ser utilizada para promocionar eventos,
            análisis de datos y mejorar nuestros servicios, publicidad de terceros con quién Ticket Dancer tenga
            celebrados convenios y contenidos. Si desea ser excluido de una lista de distribución de correo, puede
            actualizar sus preferencias aquí o enviando un correo a <a
                href="mailto:soporte@ticketdancer.com.mx">soporte@ticketdancer.com.mx.</a> El titular de los datos
            al registrarse otorga su pleno consentimiento libre y voluntariamente de proporcionar los datos personales
            que le sean requeridos, en el entendido de que si el usuario decidiera no proporcionar información
            obligatoria, este no podrá acceder a los servicios que efectivamente requiere dicha información, sin
            embargo, podrá tener acceso a todos los demás servicios que no requieran dicha Información. Ticket Dancer en
            ningún caso y bajo ninguna circunstancia almacena información de datos personales sensibles como origen
            racial o étnico, estado de salud actual, información genética, creencias religiosas, filosóficas y morales,
            afiliación sindical, opiniones políticas, preferencia sexual, ni ninguna otra información catalogada como
            sensible.
        </p>
        <h2>2. Privacidad y Protección de Datos Personales</h2>
        <p>Ticket Dancer utiliza a su alcance la tecnología más avanzada y los procesos para la protección de la
            información proporcionada por los titulares de los datos personales. Esta tecnología cifra, codifica y
            previene la intercepción de la información suministrada por el Internet, incluyendo tarjetas de crédito y
            direcciones de correo electrónico. Ticket Dancer establece y mantiene medidas de seguridad administrativas,
            técnicas y físicas que permiten proteger los datos personales contra: daños, pérdida o alteración,
            destrucción, o el uso, acceso o tratamiento no autorizado. Ticket Dancer contiene hipervínculos o
            hipertextos “links”, banners, botones y herramientas de búsqueda en la World Wide Web que al ser oprimidos o
            utilizados por los usuarios conllevan a otros portales o sitios de Internet propiedad de terceros. No
            obstante que en algunos casos estos sitios o portales de terceros se encuentran enmarcados con la barra de
            navegación o el look & feel de Ticket Dancer, la Información que el titular llegase a proporcionar a través
            de esos sitios o portales no se encuentra cubierta o contemplada por este aviso de privacidad y su manejo o
            utilización no es responsabilidad de Ticket Dancer, por lo que recomendamos a nuestros usuarios verificar
            los avisos y políticas de privacidad desplegadas o aplicables a estos sitios o portales de terceros.</p>
        <p>Ticket Dancer implementa medidas precautorias administrativas, técnicas y físicas para resguardar la
            información personal del titular de pérdidas, robos, o uso indebidos así como del acceso, la divulgación,
            modificación o destrucción no autorizados.</p>
        <p>Los servicios online emplean cifrado SSL (Secure Sockets Layer) en todas las páginas web y aplicaciones
            móviles en las que se solicita información personal a fin de proteger la confidencialidad de la información
            personal durante la transmisión a través de Internet.</p>
        <p>Al utilizar algunos productos, servicios o aplicaciones online de Ticket Dancer, propias o de terceros como
            son foros, blogs o redes sociales, la información personal que comparte el titular es visible para otros
            usuarios, que pueden leerla, recopilarla o utilizarla. El titular será responsable por la información
            personal que decida proporcionar en estos casos.</p>
        <p>
            Al dar su consentimiento para la transferencia de datos personales en Ticket Dancer, el titular de los
            mismos reconoce y acepta que Ticket Dancer podrá hacer la transferencia de sus datos a terceros, incluyendo
            patrocinadores, publicistas, contratistas y/o socios comerciales. Ticket Dancer también recolectará
            información que es derivada de los gustos, preferencias y en general de la utilización que hacen los
            usuarios de los servicios. Dicha información derivada, al igual que la Información personal que el titular
            proporcione, podrá ser utilizada para diversos objetivos comerciales, como lo es el proporcionar datos
            estadísticos a anunciantes potenciales, enviar publicidad a los usuarios de acuerdo a sus intereses
            específicos, conducir investigaciones de mercadeo, y otras actividades o promociones que Ticket Dancer
            considere apropiadas. Ticket Dancer también podrá revelar información cuando por mandato de ley y/o de
            autoridad competente le fuere requerido o por considerar de buena fe que dicha revelación es necesaria para:
        </p>
        <p>
            I) cumplir con procesos legales;<br><br>

            II) cumplir con el Convenio del Usuario;<br><br>

            III) responder reclamaciones que involucren cualquier Contenido que menoscabe derechos de terceros o; IV)
            proteger los derechos, la propiedad, o la seguridad de Ticket Dancer, sus Usuarios y el público en general.
        </p>
        <h3>Responsabilidad sobre el uso de la Información Financiera.</h3>
        <p>Ticket Dancer no almacena de ninguna forma la información financiera de los usuarios. Ticket Dancer tiene un
            contrato con el proveedor de servicio Stripe, el cual se encarga de almacenar la
            información financiera de nuestros clientes y realizar todas las transacciones bancarias en este Sitio de
            manera eficiente y segura. Para mayor información de los “Avisos de Privacidad” y “Términos y Condiciones”
            de Stripe favor de visitar <a href="https://www.stripe.com/es-mx"
                target="_blank">https://www.stripe.com</a>. También
            podemos proporcionarle su información
            financiera a los organizadores de eventos, principalmente con el propósito de facilitar la entrega de los
            boletos, resolver disputas y servicio al cliente en el lugar del evento y en la fecha del evento, y podemos
            proporcionar su información financiera a aquellos comerciantes que le están proporcionando bienes, servicios
            o reservaciones que usted compró u ordenó, o bien que están administrando las ofertas de los programas a los
            que ingresó en este Sitio (o que ingresó por medio de otro sitio después de hacer clic en un enlace de este
            Sitio que promovía tal oferta o programa).</p>
        <p>Podríamos revelar información financiera específica cuando determinemos que tal revelación puede ser útil
            para cumplir con la ley, para cooperar con o buscar asistencia por parte de autoridades de seguridad pública
            o para proteger los intereses o seguridad de Ticket Dancer o de los otros visitantes al Sitio o usuarios de
            servicios o productos proporcionados por Ticket Dancer o para participar conjuntamente con otras entidades
            en actividades contra el fraude. También, su información financiera puede ser suministrada a terceros en el
            caso de una transferencia de propiedad o de bienes, o en un proceso de quiebra de Ticket Dancer. Ticket
            Dancer no es responsable o de cualquier otra divulgación de su información financiera por parte de un
            tercero con quien Ticket Dancer tenga permitido divulgar su información financiera bajo este aviso de
            privacidad.</p>
        <p>Ticket Dancer no es responsable o de cualquier otra manera responsable legalmente de cualquier uso o
            divulgación de su información financiera por parte de un tercero con quien Ticket Dancer tenga permitido
            divulgar su información financiera bajo este aviso de privacidad.</p>
        <h2>3. Uso de “cookies” y WebBeacons</h2>
        <p>
            Ticket Dancer hace uso de herramientas que permiten obtener información de forma pasiva del usuario como
            cookies y web beacons y como una herramienta de uso común para registrar el comportamiento de navegación en
            línea (tracking).</p>
        <p>Las cookies son archivos de texto que son descargados automáticamente y almacenados en el navegador y/o disco
            duro del equipo de cómputo del usuario al navegar en un sitio de internet y que permiten al sitio recordar
            algunos datos del usuario. Ticket Dancer utiliza cookies para recordar tu usuario de Ticket Dancer y
            permitir recordar al navegador los datos que registraste en tu cuenta de Ticket Dancer y la ciudad o zona
            geográfica que seleccionas al consultar los eventos, tus datos de pago, historial de compras, preferencias,
            así como los datos para poder llevar a cabo una compra en línea en Ticket Dancer.com.mx. También para que la
            siguiente ocasión en que ingreses al sitio y tengas una nueva sesión a través del mismo navegador, ya esté
            precargado con tu nombre.</p>
        <p>Ticket Dancer o sus anunciantes dentro del sitio y aplicaciones móviles de Ticket Dancer pueden utilizar
            cookies como herramientas de seguimiento de comportamiento. También puede utilizar web beacons y tecnologías
            similares. Se utilizan estas herramientas:</p>
        <ul>
            <li>Para reconocer a clientes nuevos o pasados.</li>
            <li>Para almacenar tu contraseña con la que estas registrado en nuestros sitios.</li>
            <li>Para mejora de la experiencia de uso en nuestros sitios.</li>
            <li>Para mostrar contenido publicitario que pudiera ser de tu interés. Para lograrlo, podemos observar tu
                comportamiento de navegación en nuestro sitio y otros. También podemos recabar información sobre tu
                historial de navegación.</li>
            <li>Para entender de mejor manera los intereses de los consumidores y los visitantes de los sitios en línea
                de Ticket Dancer.</li>
            <li>Para medir el flujo de tráfico al sitio.</li>
        </ul>
        <p>Cierta publicidad en ticketdancer.com.mx puede ser basada en comportamiento de navegación, en sitios de
            Ticket Dancer o externos.</p>
        <p>Forma de controlar el uso de cookies y web beacons.</p>
        <p>Tu navegador puede darte la opción de controlar el uso de cookies. Como hacerlo depende del tipo de cookes.
            Algunos navegadores pueden ser configurados para rechazar el uso de cookies. Para controlar cookies de
            flash, que pueden ser utilizadas ocasionalmente, puedes ir
            <a
                href="http://www.macromedia.com/support/documentation/es/flashplayer/help/settings_manager07.html">aqui</a>,
            ya que este
            tipo de cookies no pueden ser controladas a través de la configuración del navegador.
        </p>
        <h2>4. Medios y procedimiento para la actualización del aviso de privacidad.</h2>
        <p>El presente aviso de privacidad será el medio para la notificación de cambios o actualizaciones, el cual
            estará publicado en <a
                href="https://www.ticketdancer.com.mx/aviso-privacidad">https://www.ticketdancer.com.mx/aviso-privacidad</a>.
        </p>
        <h2>5. Mecanismos para ejercer los derechos vinculados a la protección de datos personales (derechos ARCO).</h2>
        <p>A fin de brindar al titular el medio para ejercer los derechos ARCO, Ticket Dancer pone a disposición de los
            titulares el siguiente formulario a través del cual se dará trámite y seguimiento a las solicitudes de
            acceso, rectificación, cancelación u oposición.</p>
        <p>El titular podrá enviar a través de este medio una solicitud para el acceso, rectificación, cancelación u
            oposición, respecto de sus datos personales.</p>
        <p>Dicha solicitud deberá contener:</p>
        <ul>
            <li>El nombre completo del titular y todos los datos que le fueron solicitados para registrarse así como un
                correo electrónico para comunicarle la respuesta a su solicitud.</li>
            <li>Copia electrónica de los documentos que acrediten la identidad del titular de los datos personales (RFC,
                IFE).</li>
            <li>Descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los
                derechos antes mencionados.</li>
            <li>Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
            <li>Indicar de las modificaciones a realizarse y/o las limitaciones al uso de los datos personales y aportar
                la documentación que sustente su petición.</li>
        </ul>
        <p>Ticket Dancer comunicará al titular de los datos personales la determinación adoptada, en un plazo no mayor a
            20 días hábiles contados desde la fecha en que se recibió la solicitud. Este plazo podrá ser ampliado por
            Ticket Dancer en una sola ocasión por un periodo igual, siempre y cuando así lo justifiquen las
            circunstancias del caso.</p>
        <p>Ticket Dancer informará al titular de los datos personales el sentido y motivación de la resolución por
            correo electrónico y acompañará dicha resolución de las pruebas pertinentes, en su caso. Ticket Dancer podrá
            negar el acceso total o parcial a los datos personales o a la realización de la rectificación, cancelación u
            oposición al tratamiento de los mismos, en los siguientes supuestos:</p>
        <ul>
            <li>Cuando el solicitante no sea el titular o el representante legal no esté acreditado para ello.</li>
            <li>Cuando en la base de datos de Ticket Dancer no se encuentren los datos personales del solicitante.</li>
            <li>Cuando se lesionen los derechos de un tercero.</li>
            <li>Cuando exista impedimento legal o resolución de una autoridad.</li>
            <li>Cuando la rectificación, cancelación u oposición haya sido previamente realizada, de manera que la
                solicitud carezca de materia.</li>
        </ul>
        <p>La cancelación de datos personales dará lugar a un periodo de bloqueo tras el cual Ticket Dancer procederá a
            la supresión de los datos correspondientes. Una vez cancelados los datos personales correspondientes, Ticket
            Dancer dará aviso a su titular.</p>
        <p>Hecho lo anterior, Ticket Dancer podrá conservar los datos personales exclusivamente para efectos de las
            responsabilidades derivadas del tratamiento a que se reﬁere el Aviso de Privacidad.</p>
        <p>Ticket Dancer no estará obligado a cancelar los datos personales cuando se trate de los supuestos
            establecidos en el artículo vigésimo sexto de la Ley Federal para la Protección de Datos en Posesión de los
            Particulares. Asimismo, cuando la información recabada en los datos personales deje de ser necesaria para el
            cumplimiento de las ﬁnalidades previstas en este Aviso de Privacidad y en las disposiciones jurídicas
            aplicables, tus datos personales serán cancelados de la base de datos de Ticket Dancer.</p>
        <h2>6. Información de Niños menores de 13 Años residentes en los Estados Unidos de Norte América y sus
            territorios
        </h2>
        <p>Ticket Dancer no recolecta Información proveniente de Usuarios menores de 13 años residentes de los Estados
            Unidos de Norteamérica y sus territorios.</p>
        <p>SI TIENE ALGUNA DUDA O PREGUNTA RESPECTO AL AVISO DE PRIVACIDAD, POR FAVOR CONTACTENOS EN LA SIGUIENTE
            DIRECCIÓN: <a href="mailto:soporte@ticketdancer.com.mx">soporte@ticketdancer.com.mx</a></p>
        <p class="bold">Fecha de última actualización: 08 de Septiembre de 2022</p>
    </div>
</template>

<script>
export default {
    name: "PrivacyPolicyView"
}
</script>

<style scoped>
.container {
    max-width: 60%;
    margin: auto;
    background: white;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

h1 {
    text-align: center;
}

h2,
h3 {
    margin: 20px 50px;
}

p,
li {
    text-align: justify;
    margin: 20px 50px;
}

p.bold {
    font-weight: bolder;
}

a {
    color: #652d66;
}
</style>