import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import formValidator from './utils/formValidator';
import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui-plus'; // BalmJS Team Material Components
import 'balm-ui-css';
import i18n from './i18n'

const pinia = createPinia()
const app = createApp(App)

app.use(BalmUI, {
    $theme: {
        primary: "#652d66",
        secondary: "#e5a946"
    },
    $validator: formValidator,
    $toast: {
        position: 'center'
    }
})
app.use(BalmUIPlus)
app.use(router)
app.use(i18n)
app.use(pinia)
app.mount('#app')
