<template>
    <h1>{{ $t('footer.linksFaqs') }}</h1>
    <div v-shape class="container">
        <ui-collapse v-for="(value, index) in numberOfQuestions" :key="index" with-icon ripple>
            <template #toggle>
                <div>
                    <h3> {{ questions[index] }} </h3>
                </div>
            </template>
            <div>
                <p> {{ answers[index] }} </p>
            </div>
        </ui-collapse>
    </div>
</template>
<script>
import i18n from '@/i18n';

const questions = [
    i18n.global.t('questions.q1'),
    i18n.global.t('questions.q2'),
    i18n.global.t('questions.q3'),
    i18n.global.t('questions.q4'),
    i18n.global.t('questions.q5'),
    i18n.global.t('questions.q6'),
    i18n.global.t('questions.q7'),
    i18n.global.t('questions.q8'),
    i18n.global.t('questions.q9'),
    i18n.global.t('questions.q10'),
    i18n.global.t('questions.q11')
]

const answers = [
    i18n.global.t('answers.a1'),
    i18n.global.t('answers.a2'),
    i18n.global.t('answers.a3'),
    i18n.global.t('answers.a4'),
    i18n.global.t('answers.a5'),
    i18n.global.t('answers.a6'),
    i18n.global.t('answers.a7'),
    i18n.global.t('answers.a8'),
    i18n.global.t('answers.a9'),
    i18n.global.t('answers.a10'),
    i18n.global.t('answers.a11'),
]
export default {
    name: "FaqsView",
    data() {
        return {
            questions,
            answers
        }
    },
    computed: {
        numberOfQuestions() {
            return this.questions.length
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 60%;
    margin: auto;
    background: white;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

h1 {
    text-align: center;
}

h3 {
    margin: 20px 50px;
}

p {
    text-align: justify;
    margin: 20px 50px;
}
</style>