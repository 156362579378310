<template>
    <!-- Google SignIn setup-->
    <div id="g_id_onload" :data-client_id=googleClientId data-context="use" data-ux_mode="popup"
        data-callback="processGoogleSignInCallback" data-auto_select="false" data-itp_support="true">
    </div>

    <ui-grid id="grid">
        <ui-grid-cell columns="6" align="middle">
            <div class="grid-cell-element">
                <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline"
                    data-text="continue_with" data-size="large" data-logo_alignment="left" data-width="240">
                </div>
            </div>
        </ui-grid-cell>
        <ui-grid-cell columns="6" align="middle">
            <div class="grid-cell-element">
                <!-- Apple Id setup-->
                <div id="appleid-signin" data-color="black" data-border="false" data-type="continue" data-width="240"
                    data-height="38"></div>
            </div>
        </ui-grid-cell>
        <ui-grid-cell columns="12">
            <ui-progress v-show="signinIn" indeterminate active></ui-progress>
            <error-component v-show="errorSignIn" :message="errorMessage" />
        </ui-grid-cell>
    </ui-grid>
</template>

<script>
import { helpers } from "balm-ui";
import { useUserStore } from "@/stores/user";
import { useMainStore } from "@/stores/main";
import { mapState, mapActions } from "pinia";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { analytics, logEvent } from "@/utils/firebase"

export default {
    components: {
        ErrorComponent,
    },
    mounted() {
        this.configureGoogleSignIn();
        this.configureAppleSignIn();

    },
    unmounted() {
        document.removeEventListener('AppleIDSignInOnSuccess', this.processAppleSuccessSignIn)
        document.removeEventListener('AppleIDSignInOnFailure', this.processAppleErrorSignIn)
    },
    data() {
        return {
            googleClientId: process.env.VUE_APP_GOOGLE_OATH_CLIENT,
            appleClientId: process.env.VUE_APP_APPLE_ID_CLIENT,
            appleIdCallback: `${origin}/login-create`,
            errorMessage: "",
            signinIn: false,
            errorSignIn: false
        }
    },
    computed: {
        ...mapState(useMainStore, ['defaultLanguage']),
    },
    methods: {
        ...mapActions(useUserStore, { signInWithGoogle: 'signInWithGoogle', signInWithApple: 'signInWithApple' }),
        configureGoogleSignIn() {
            const googleSignInScript = document.createElement("script");
            googleSignInScript.setAttribute(
                "src",
                "https://accounts.google.com/gsi/client"
            );
            googleSignInScript.async = true;
            googleSignInScript.defer = true;
            document.head.appendChild(googleSignInScript);

            window.processGoogleSignInCallback = this.processGoogleSignInCallback

        },
        async processGoogleSignInCallback(response) {

            //console.log(response)
            if (!helpers.isEmpty(response) && !helpers.isEmpty(response.credential)) {

                try {
                    this.errorSignIn = false;
                    this.signinIn = true;
                    await this.signInWithGoogle({
                        idToken: response.credential,
                        language: this.defaultLanguage
                    });
                    logEvent(analytics, 'login', {
                        type: 'Google Sign In'
                    })
                    this.signinIn = false;
                    this.redirectAfterLogin()

                } catch (error) {
                    this.errorMessage = error.mensaje;
                    this.errorSignIn = true;
                    this.signinIn = false;

                }
            }
        },
        configureAppleSignIn() {
            const clientId = document.createElement("meta")
            clientId.setAttribute("name", "appleid-signin-client-id")
            clientId.setAttribute("content", this.appleClientId)
            document.head.appendChild(clientId)

            const scope = document.createElement("meta")
            scope.setAttribute("name", "appleid-signin-scope")
            scope.setAttribute("content", "name email")
            document.head.appendChild(scope)

            const redirectUri = document.createElement("meta")
            redirectUri.setAttribute("name", "appleid-signin-redirect-uri")
            // See this URI setup in the router
            redirectUri.setAttribute("content", this.appleIdCallback)
            document.head.appendChild(redirectUri)

            const state = document.createElement("meta")
            state.setAttribute("name", "appleid-signin-state")
            state.setAttribute("content", crypto.randomUUID())
            document.head.appendChild(state)

            const popup = document.createElement("meta")
            popup.setAttribute("name", "appleid-signin-use-popup")
            popup.setAttribute("content", "true")
            document.head.appendChild(popup)

            const appleIdScript = document.createElement("script");
            appleIdScript.setAttribute(
                "src",
                "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            );
            document.body.appendChild(appleIdScript);

            document.addEventListener('AppleIDSignInOnSuccess', this.processAppleSuccessSignIn)

            document.addEventListener('AppleIDSignInOnFailure', this.processAppleErrorSignIn)

        },
        async processAppleSuccessSignIn(event) {
            //console.log(event);
            //console.log(event.detail);
            //console.log(this.$route.query.redirect);
            if (!helpers.isEmpty(event.detail)) {
                try {
                    this.errorSignIn = false;
                    this.signinIn = true;
                    await this.signInWithApple({
                        code: event.detail.authorization.code,
                        id_token: event.detail.authorization.id_token,
                        state: event.detail.authorization.state,
                        // Needs to be done this way due to Apple Sign In restrictions.
                        user: helpers.isEmpty(event.detail.user) ? null : JSON.stringify(event.detail.user),
                        language: this.defaultLanguage,
                        redirectUri: this.appleIdCallback
                    })
                    logEvent(analytics, 'login', {
                        type: 'Apple ID'
                    })
                    this.signinIn = false;
                    this.redirectAfterLogin()
                } catch (error) {
                    this.errorMessage = error.mensaje;
                    this.errorSignIn = true;
                    this.signinIn = false;
                }
            }
        },
        processAppleErrorSignIn(event) {
            if (!helpers.isEmpty(event.detail)) {
                this.errorMessage = this.$t('errors.generic')
                this.errorSignIn = true;
                this.signinIn = false;
            }
        },
        redirectAfterLogin() {
            this.$router.push(this.$route.query.redirect || '/');
        }
    }
}
</script>

<style scoped>
#grid {
    max-width: 620px;
    height: 80px;
}

.grid-cell-element {
    text-align: center;
}
</style>