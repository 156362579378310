<template>
    <div v-shape class="container">
        <h3 class="title"> {{ $t('registries.selectRegisterTypeItemTitle', [registerType.registerTypeTitle]) }} </h3>
        <ui-grid>
            <ui-grid-cell columns="1" class="grid-cell-centered">
                <ui-icon-button :icon="toogleIcon" @click.prevent="toggleDescription">
                </ui-icon-button>
            </ui-grid-cell>
            <ui-grid-cell columns="2">
                <img :src="imageUrl" />
            </ui-grid-cell>
            <ui-grid-cell columns="3" class="grid-cell-centered">
                <p class="register-type-name">
                    {{ registerType.registerTypeName }}
                </p>
            </ui-grid-cell>
            <ui-grid-cell columns="2" class="grid-cell-centered">
                <ui-select v-model="quantity" :options="registerTypeQuantityOptions" class="quantity-select">
                    {{ $t('events.ticketQuantity') }}
                </ui-select>
            </ui-grid-cell>
            <ui-grid-cell columns="2" class="grid-cell-centered">
                <div>
                    <div>
                        <h2> {{ registerTypeTotal }} </h2>
                    </div>
                    <preview-total-details :is-ticket="false" :price="registerType.registerTypePrice"
                        :commission="registerType.registerTypeCommission" :currency="registerType.currency">
                    </preview-total-details>
                </div>
            </ui-grid-cell>
            <ui-grid-cell columns="2" class="grid-cell-right">
                <div>
                    <div>
                        <ui-button class="select" @click.prevent="goToRegistroView">
                            {{ $t('common.select') }}
                        </ui-button>
                    </div>
                    <label v-show="registerType.msiEnabled" class="msi">
                        {{ $t('plans.msiAvailable') }}
                    </label>
                </div>
            </ui-grid-cell>
        </ui-grid>
        <div class="register-type-description" v-show="descriptionVisible">
            <p>
                {{ $t('registries.registrationIncludes') }}
            </p>
            <p v-html="registerType.registerTypeDescription">
            </p>
        </div>
    </div>
</template>

<script>
import { SELECT_VALUES } from '@/utils/constants'
import numberUtils from '@/utils/numberUtils'
import PreviewTotalDetails from '../common/PreviewTotalDetails.vue'

const registerTypeQuantityOptions = [...SELECT_VALUES.TICKETS_QUANTITY]
export default {
    name: "RegisterTypeSelection",
    props: {
        imageUrl: String,
        registerType: Object,
        eventId: String
    },
    components: {
        PreviewTotalDetails
    },
    data() {
        return {
            descriptionVisible: false,
            registerTypeQuantityOptions,
            quantity: 1,
        }
    },
    computed: {
        toogleIcon() {
            return this.descriptionVisible ? "remove" : "add"
        },
        registerTypeTotal() {
            let price = this.registerType.registerTypePrice
            let comision = this.registerType.registerTypeCommission
            let total = (price + comision) * this.quantity
            let totalStr = numberUtils.getCurrencyFromNumber(total, this.registerType.currency)
            return this.$t('events.ticketTotal', [totalStr])
        },
    },
    methods: {
        toggleDescription() {
            this.descriptionVisible = !this.descriptionVisible
        },
        goToRegistroView() {
            this.$router.push(`/register-checkout?registerTypeId=${this.registerType.registerTypeId}&quantity=${this.quantity}&eventId=${this.eventId}`)
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 95%;
    margin: auto;
    background: white;
}

.title {
    padding-top: 10px;
    padding-left: 10px;
}

.container img {
    width: 140px;
    height: 130px;
}

.grid-cell-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-cell-right {
    display: flex;
    justify-content: right;
    align-items: center;
}

.register-type-name {
    font-weight: bolder;
    font-size: x-large;
}

.quantity-select {
    width: 120px;
}

button.select {
    color: white;
    background-color: #3156d5;
    width: 185px;
}

.register-type-description {
    padding: 20px;
}

.msi {
    font-size: small;
    font-weight: bold;
    color: #3156d5;
}
</style>