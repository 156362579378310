<template>
    <ui-card outlined>
        <ui-card-content>
            <div class="container">
                <h2 class="container-element">{{ $t('events.info') }}</h2>
                <div class="container-element" v-if="info !== null" v-html="info">
                </div>
            </div>
        </ui-card-content>
    </ui-card>
</template>

<script>
export default {
    name: "TdInformation",
    props: {
        info: String
    }
}
</script>

<style scoped>
.container {
    position: relative;
    min-height: 200px;
}

.container-element {
    margin: 10px 30px;
}
</style>