<template>
  <div id="login-form-container">
    <ui-card id="card-container" v-shadow.transition="[2, 8]">
      <h3> {{ $t("titles.login") }}</h3>
      <ui-form type="|" item-margin-bottom="20" action-align="right">
        <template #default="{ itemClass, actionClass }">
          <ui-form-field :class="itemClass">
            <ui-textfield input-type="email" outlined fullwidth v-model="formData.loginemail"
              helper-text-id="email-login-helper-text" :disabled="formLoading">
              {{ $t('myAccount.email') }}
              <template #before>
                <ui-textfield-icon>email</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="email-login-helper-text" v-model:validMsg="validMsg.loginemail">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="itemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-login-helper-text"
              v-model="formData.loginpassword" :disabled="formLoading" :attrs="{ autocomplete: 'current-password' }"
              @keyup.enter="login">
              {{ $t('myAccount.password') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-login-helper-text" v-model:validMsg="validMsg.loginpassword">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="actionClass">
            <ui-button raised native-type="submit" @click.prevent="login" :disabled="formLoading">
              {{ $t('myAccount.login') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">login</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
      <error-component v-show="errorLogin" :message="errorMessage" />
    </ui-card>
    <ui-button id="forgot-button" @click.prevent="goToForgotPassword">{{ $t('forgoPassword.button') }}</ui-button>
  </div>
  <ui-dialog v-model="openInactiveUserDialog" fullscreen>
    <ui-dialog-title> {{ $t('alerts.important') }}</ui-dialog-title>
    <ui-dialog-content>
      <p>{{ inactiveUserMessage }}</p>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click.prevent="resendEmail"> {{ $t('myAccount.resendNewEmail') }}</ui-button>
    </ui-dialog-actions>
  </ui-dialog>
</template>

<script>
import { useValidator } from "balm-ui";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { useUserStore } from "@/stores/user";
import { mapActions } from "pinia";
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';
import { analytics, logEvent } from "@/utils/firebase"

const validations = [
  {
    key: "loginemail",
    label: "Email",
    validator: "required",
  },
  {
    key: "loginpassword",
    label: "Password",
    validator: "required",
  },
];

export default {
  name: "LoginForm",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      balmUI: useValidator(),
      validations,
      validMsg: {},
      formData: {
        loginemail: "",
        loginpassword: "",
      },
      formLoading: false,
      errorLogin: false,
      errorMessage: "",
      openInactiveUserDialog: false,
      inactiveUserMessage: "",
    };
  },
  methods: {
    ...mapActions(useUserStore, { loginFromStore: 'login', resendValidationEmail: 'resendValidationEmail' }),
    async login() {
      let result = this.balmUI.validate(this.formData);
      //console.log(result);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {

        window.grecaptcha.enterprise.ready(async () => {

          try {

            this.formLoading = true;
            this.errorLogin = false;

            const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.LOGIN });
            await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.LOGIN, token, process.env.VUE_APP_RECAPTCHA_KEY)

            const payload = {
              email: this.formData.loginemail,
              password: this.formData.loginpassword,
            };

            await this.loginFromStore(payload);

            logEvent(analytics, 'login', {
              type: 'Ticket Dancer'
            })

            this.formLoading = false;
            this.$router.push(this.$route.query.redirect || '/');
          } catch (error) {
            this.formLoading = false;

            // User not validated error
            if (error.code === "14") {
              this.inactiveUserMessage = error.mensaje;
              this.openInactiveUserDialog = true;
            } else {
              this.errorMessage = error.mensaje;
              this.errorLogin = true;
            }
            //console.log(error);
          }

        });
      }
    },
    async resendEmail() {
      this.openInactiveUserDialog = false;
      this.formLoading = true;
      this.errorLogin = false;

      try {
        const payload = {
          email: this.formData.loginemail,
        };

        await this.resendValidationEmail(payload);

        this.formLoading = false;

        this.$alert({
          title: this.$t('alerts.important'),
          message: this.$t('myAccount.createUserNotification', [this.formData.loginemail]),
          state: "info",
        });
      } catch (error) {
        //console.log(error);
        this.formLoading = false;
        this.errorMessage = error.mensaje;
        this.errorLogin = true;
      }
    },
    goToForgotPassword() {
      this.$router.push("/forgot-password");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  text-align: center;
  margin-top: 2em;
}

h3 {
  text-align: center;
  margin-bottom: 2em;
}

#login-form-container {
  max-width: 600px;
  margin: auto;
}

#card-container {
  padding: 2em;
  margin-top: 3em;
}

#forgot-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}
</style>