<template>
    <h1>{{ $t('transactions.title') }}</h1>
    <div class="container">
        <div class="selection-container">
            <ui-segmented-buttons v-model="selectedTransactionType" :items="transactionOptions" single-select>
            </ui-segmented-buttons>
        </div>
        <ui-table fullwidth :data="filteredTransactions" :thead="thead" :tbody="tbody">
            <template #typeQuantity="{ data }">
                <ui-icon class="single-line-item" outlined> {{ getIconType(data) }} </ui-icon>
                <div class="single-line-item">{{ getQuantity(data) }}</div>
            </template>
            <template #total="{ data }">
                <div :class="getStatusClass(data)"> {{ getTotal(data) }}</div>
            </template>
            <template #actions="{ data }">
                <ui-icon-button icon="visibility" @click="showDetails(data)"></ui-icon-button>
            </template>
            <ui-progress v-show="transactionsLoading" indeterminate active></ui-progress>
            <div class="load-more-container" v-show="!transactionsLoading">
                <ui-button outlined @click.prevent="loadTransactions"> {{ $t("transactions.loadMore") }}</ui-button>
            </div>
        </ui-table>
        <ui-dialog v-model="displayDetails">
            <ui-dialog-title>
                {{ transactionSelected.orderId }}
            </ui-dialog-title>
            <ui-dialog-content>
                <ui-divider></ui-divider>
                <div class="concept-container">
                    <div class="single-line-item bold"> {{ transactionSelected.subtotalLbl }} </div>
                    <div class="single-line-item right"> {{ transactionSelected.subtotal }}</div>
                </div>
                <div class="concept-container" v-show="transactionSelected.couponName">
                    <div class="single-line-item bold"> {{ transactionSelected.couponName }} </div>
                    <div class="single-line-item right green"> {{ transactionSelected.couponAmount }}</div>
                </div>
                <div class="concept-container">
                    <div class="single-line-item bold"> {{ $t('shoppingCart.feeSubtotal') }} </div>
                    <div class="single-line-item right"> {{ transactionSelected.commission }}</div>
                </div>
                <div class="concept-container">
                    {{ $t('shoppingCart.nonRefundable') }}
                </div>
                <ui-divider></ui-divider>
                <div class="concept-container">
                    <ui-icon :class="transactionSelected.succeeded ? 'transaction-succeeded' : 'transaction-failed'"
                        class="single-line-item"> {{
                            statusIcon }}</ui-icon>
                    <p :class="transactionSelected.succeeded ? 'transaction-succeeded' : 'transaction-failed'"
                        class="single-line-item">{{
                            transactionSelected.amount }}</p>
                </div>
                <div class="concept-container">
                    <template v-if="transactionSelected.paymentSource === 'card'">
                        <ui-icon class="single-line-item" outlined>payment</ui-icon>
                        <div class="single-line-item"> (xx {{ transactionSelected.cardId }})</div>
                    </template>
                    <template v-else-if="transactionSelected.paymentSource === 'customer_balance'">
                        <ui-icon class="single-line-item" outlined>account_balance</ui-icon>
                        <div class="single-line-item"> {{ $t('purchases.bankTransfer') }}</div>
                    </template>
                    <template v-else>
                        <img src="@/assets/oxxo.png" width="36" />
                    </template>
                </div>
                <div v-show="!transactionSelected.succeeded">
                    <error-component :message="transactionSelected.paymentError" />
                </div>
            </ui-dialog-content>
            <ui-dialog-actions>
                <ui-button @click="displayDetails = false">OK</ui-button>
            </ui-dialog-actions>
        </ui-dialog>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useMainStore } from '@/stores/main';
import paymentsApi from '@/api/PaymentsApi.js'
import numberUtils from '@/utils/numberUtils'
import ErrorComponent from '@/components/common/ErrorComponent.vue'

export default {
    name: "TransactionHistoryView",
    components: {
        ErrorComponent
    },
    async mounted() {
        const mainStore = useMainStore()
        mainStore.$patch({ pageTitle: this.$t('transactions.title') })

        await this.loadTransactions();
    },
    data() {
        return {
            transactionOptions: [
                {
                    icon: "density_small",
                    text: this.$t('transactions.all'),
                },
                {
                    icon: "confirmation_number",
                    text: this.$t('purchases.ticketsOption'),
                },
                {
                    icon: "request_quote",
                    text: this.$t('purchases.registriesOption'),
                },
                {
                    icon: "check_circle",
                    text: this.$t('transactions.succeeded'),
                },
                {
                    icon: "error",
                    text: this.$t('transactions.failed'),
                }
            ],
            filteredTransactions: [],
            selectedTransactionType: 0,
            pageTickets: 0,
            pageRegistries: 0,
            itemsPerPage: 10,
            thead: [
                this.$t('transactions.transactionOrder'),
                this.$t('transactions.transactionDate'),
                this.$t('transactions.transactionEventName'),
                this.$t('transactions.transactionType'),
                this.$t('transactions.transactionName'),
                this.$t('shoppingCart.companyGroup'),
                {
                    value: this.$t('transactions.transactionTotal'),
                    align: 'center'
                },
                this.$t('transactions.transactionDetails'),
            ],
            tbody: [
                {
                    field: "id",
                    fn: (data) => {
                        return data.id.substring(0, 12);
                    },
                },
                'date',
                'eventName',
                {
                    slot: 'typeQuantity'
                },
                {
                    field: "name",
                    fn: (data) => {
                        return data.type === 'TICKET' ? data.ticketName : (data.registerTypeTitle + ":" + data.registerTypeName)
                    }
                },
                "companyGroup",
                {
                    slot: 'total'
                },
                {
                    slot: 'actions'
                }
            ],
            transactionSelected: {
                orderId: "",
                amount: "",
                subtotalAmount: "",
                couponName: "",
                couponAmount: "",
                subtotalLbl: "",
                subtotal: "",
                commission: "",
                succeeded: false,
                paymentSource: "",
                cardId: "",
                paymentError: "",
            },
            transactionsLoading: false,
            displayDetails: false,
            errorLoad: false,
            errorMessage: ""
        }
    },
    watch: {
        selectedTransactionType() {

            this.pageTickets = 0;
            this.pageRegistries = 0;
            this.filteredTransactions = []

            this.loadTransactions();
        }
    },
    computed: {
        ...mapState(useUserStore, ['userLogged']),
        statusIcon() {
            return this.transactionSelected.succeeded ? 'check_circle' : 'error'
        }
    },
    methods: {
        async loadTransactions() {

            this.transactionsLoading = true;

            try {

                switch (this.selectedTransactionType) {

                    case 0: {

                        const allPayments = await paymentsApi.getPaymentHistory(this.userLogged.token, this.pageTickets, this.itemsPerPage);
                        if (allPayments.length > 0) {
                            this.filteredTransactions.push(...allPayments);
                            this.pageTickets++;
                        }

                        const allRegistries = await paymentsApi.getRegistryPaymentHistory(this.userLogged.token, this.pageRegistries, this.itemsPerPage);
                        if (allRegistries.length > 0) {
                            this.filteredTransactions.push(...allRegistries);
                            this.pageRegistries++;
                        }

                        break;
                    }
                    case 1: {

                        const allPayments = await paymentsApi.getPaymentHistory(this.userLogged.token, this.pageTickets, this.itemsPerPage);
                        if (allPayments.length > 0) {
                            this.filteredTransactions.push(...allPayments);
                            this.pageTickets++;
                        }

                        break;
                    }

                    case 2: {

                        const allRegistries = await paymentsApi.getRegistryPaymentHistory(this.userLogged.token, this.pageRegistries, this.itemsPerPage);
                        if (allRegistries.length > 0) {
                            this.filteredTransactions.push(...allRegistries);
                            this.pageRegistries++;
                        }

                        break;
                    }

                    case 3: {

                        const allPayments = await paymentsApi.getPaymentHistory(this.userLogged.token, this.pageTickets, this.itemsPerPage);
                        if (allPayments.length > 0) {
                            for (var i = 0; i < allPayments.length; i++) {
                                if (allPayments[i].opTransactionStatus === 'SUCCEEDED') {
                                    this.filteredTransactions.push(allPayments[i])
                                }
                            }
                            this.pageTickets++;
                        }

                        const allRegistries = await paymentsApi.getRegistryPaymentHistory(this.userLogged.token, this.pageRegistries, this.itemsPerPage);
                        if (allRegistries.length > 0) {
                            for (var x = 0; x < allRegistries.length; x++) {
                                if (allRegistries[x].opTransactionStatus === 'SUCCEEDED') {
                                    this.filteredTransactions.push(allRegistries[x])
                                }
                            }
                            this.pageRegistries++;
                        }

                        break;
                    }

                    case 4: {

                        const allPayments = await paymentsApi.getPaymentHistory(this.userLogged.token, this.pageTickets, this.itemsPerPage);
                        if (allPayments.length > 0) {
                            for (var j = 0; j < allPayments.length; j++) {
                                if (allPayments[j].opTransactionStatus === 'FAILED') {
                                    this.filteredTransactions.push(allPayments[j])
                                }
                            }
                            this.pageTickets++;
                        }

                        const allRegistries = await paymentsApi.getRegistryPaymentHistory(this.userLogged.token, this.pageRegistries, this.itemsPerPage);
                        if (allRegistries.length > 0) {
                            for (var y = 0; y < allRegistries.length; y++) {
                                if (allRegistries[y].opTransactionStatus === 'FAILED') {
                                    this.filteredTransactions.push(allRegistries[y])
                                }
                            }
                            this.pageRegistries++;
                        }

                        break;
                    }
                }

                this.filteredTransactions.sort((historyA, historyB) => new Date(historyB.date) - new Date(historyA.date))
                this.transactionsLoading = false;

            } catch (error) {
                this.errorLoad = true;
                this.transactionsLoading = false;
                this.errorMessage = error.mensaje;
            }
        },
        getIconType(history) {
            return history.type === 'TICKET' ? 'confirmation_number' : 'request_quote'
        },
        getQuantity(history) {
            return "x " + (history.type === 'TICKET' ? history.ticketsNum : history.registersNum)
        },
        getTotal(history) {
            return numberUtils.getCurrencyFromNumber(history.amount, history.currency)
        },
        getStatusClass(history) {
            return history.opTransactionStatus === 'SUCCEEDED' ? 'transaction-succeeded' : 'transaction-failed'
        },
        showDetails(history) {
            const amount = history.amount;
            const cAmount = history.couponAmount ?? 0;
            const subtotal = history.subtotalAmount ?? 0;
            const comision = history.commission * subtotal;

            this.transactionSelected.orderId = this.$t('transactions.transactionOrder') + ": " + history.id;
            this.transactionSelected.subtotalLbl = history.type === 'TICKET' ? this.$t('shoppingCart.ticketSubtotal') : this.$t('registries.registriesSubtotal')
            this.transactionSelected.subtotal = numberUtils.getCurrencyFromNumber(subtotal, history.currency)
            this.transactionSelected.couponName = history.couponName ? (history.couponName + ":") : ""
            this.transactionSelected.couponAmount = "- " + numberUtils.getCurrencyFromNumber(cAmount, history.currency)
            this.transactionSelected.commission = numberUtils.getCurrencyFromNumber(comision, history.currency)
            this.transactionSelected.succeeded = history.opTransactionStatus === 'SUCCEEDED'
            this.transactionSelected.amount = this.$t('events.ticketTotal', [numberUtils.getCurrencyFromNumber(amount, history.currency)])
            this.transactionSelected.paymentSource = history.paymentSource;
            this.transactionSelected.cardId = history.cardId;
            this.transactionSelected.paymentError = history.errorMessage;
            this.displayDetails = true;
        }
    }

}
</script>

<style scoped>
h1 {
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    background: white;
    padding: 20px;
}

.selection-container {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.load-more-container {
    margin: 20px;
    text-align: center;
}

.single-line-item {
    display: inline;
    margin: 4px;
    vertical-align: middle;
}

.single-line-item.bold {
    color: black;
    font-weight: bolder;
    margin-right: 100px;
}

.single-line-item.right {
    text-align: right;
}

.single-line-item.green {
    color: #006600;
}

.transaction-succeeded {
    font-weight: bold;
    color: #006600;
    text-align: right;
}

.transaction-failed {
    font-weight: bold;
    color: #d32f2f;
    text-align: right;
}

.concept-container {
    margin: 10px;
    text-align: right;
}

.disclaimer {
    text-align: center;
}
</style>