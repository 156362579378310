
import { LS_KEYS } from "@/utils/constants";
import { defineStore } from "pinia";
import userApi from '@/api/UserApi.js';
import paymentsApi from "@/api/PaymentsApi";

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            userLogged: JSON.parse(localStorage.getItem(LS_KEYS.USER_LOGIN_KEY)),
            shoppingCart: null, //JSON.parse(localStorage.getItem(LS_KEYS.USER_SHOPPING_CART_KEY)),
            registryFormData: null,
            shoppingCartPaymentIntentId: null,
            registryPaymentIntentId: null,
        }
    },
    mutations: {
    },
    actions: {
        async login(payload) {
            //console.log(payload)
            const userData = await userApi.login(payload)
            this.userLogged = userData

        },

        async signInWithGoogle(payload) {
            //console.log(payload)
            const userData = await userApi.signInWithGoogle(payload)
            this.userLogged = userData

        },

        async signInWithApple(payload) {
            //console.log(payload)
            const userData = await userApi.signInWithApple(payload)
            this.userLogged = userData

        },

        async resendValidationEmail(payload) {
            await userApi.resendValidationEmail(payload);
        },

        logout() {
            userApi.logout();
            this.userLogged = null;
            this.shoppingCart = null;
            this.shoppingCartPaymentIntentId = null;
            this.registryPaymentIntentId = null;
        },

        async updateUser(payload) {
            const userData = await userApi.updateUserData(payload);
            this.userLogged = userData
        },

        async deleteUser(payload) {
            //console.log(payload)
            await userApi.deleteUser(payload)
            this.logout()
        },

        async checkShoppingCart(payload) {
            const shoppingCart = await userApi.checkShoppingCart(payload)
            this.shoppingCart = shoppingCart
            //console.log("Finished checking shopping cart: " + payload)
        },

        async createShoppingCart(selectedTicketModel) {
            const shoppingCart = await paymentsApi.createShoppingCart(selectedTicketModel, this.userLogged.token)
            this.shoppingCart = shoppingCart
        },

        async redeemCoupon(couponRedeemRequest) {
            const shoppingCart = await paymentsApi.redeemCoupon(this.userLogged.token, couponRedeemRequest)
            this.shoppingCart = shoppingCart
        },

        async cancelRedeemCoupon(couponRedeemRequest) {
            const shoppingCart = await paymentsApi.cancelRedeemCoupon(this.userLogged.token, couponRedeemRequest)
            this.shoppingCart = shoppingCart
        },

        async updateShoppingCart(updatedShoppingCart) {
            await paymentsApi.updateShoppingCart(this.userLogged.token, updatedShoppingCart);
            this.shoppingCart.names = updatedShoppingCart.names;
            this.shoppingCart.companyGroup = updatedShoppingCart.companyGroup;
        },

        // As a good practice I only create the 'Actions' that modify the 'Store'. This is the only exception
        async checkSubmissionShoppingCart(shoppingCartSubmission) {
            await paymentsApi.checkSubmissionShoppingCart(this.userLogged.token, shoppingCartSubmission)
        },

        async deleteShoppingCart(orderId) {
            await paymentsApi.deleteShoppingCart(this.userLogged.token, orderId)
            this.shoppingCart = null;
            this.shoppingCartPaymentIntentId = null;
        },

        async disableShoppingCart(orderId) {
            await paymentsApi.disableShoppingCart(this.userLogged.token, orderId)
            this.shoppingCart = null;
            this.shoppingCartPaymentIntentId = null;
        },

        async submitRegistryForm(paymentIntentId) {
            await paymentsApi.submitRegistryForm(this.userLogged.token, paymentIntentId, this.registryFormData)
        },

        deleteRegistryFormData() {
            this.registryFormData = null;
            this.registryPaymentIntentId = null;
        }
    }

});
