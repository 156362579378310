import { URLS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";
import { useMainStore } from "@/stores/main";

export default {

    async createShoppingCart(createShoppingCartModel, token) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CREATE_SHOPPING_CART + "?language=" + mainStore.defaultLanguage, {
            method: "POST",
            body: JSON.stringify({
                eventId: createShoppingCartModel.eventId,
                ticketId: createShoppingCartModel.ticketId,
                numTickets: createShoppingCartModel.numTickets
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let shoppingCartData = await response.json();
            return shoppingCartData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async requestPaymentSheet(token) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REQUEST_PAYMENT_SHEET, {
            method: "POST",
            body: JSON.stringify({
                paymentIntentMethod: "card",
                origin: "Web"
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentSheetData = await response.json();
            return paymentSheetData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async redeemCoupon(token, couponRedeemRequest) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REDEEM_COUPON, {
            method: "POST",
            body: JSON.stringify({
                orderId: couponRedeemRequest.orderId,
                cardPaymentIntentId: couponRedeemRequest.cardPaymentIntentId,
                couponName: couponRedeemRequest.couponName
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let shoppingCart = await response.json();
            return shoppingCart;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async cancelRedeemCoupon(token, couponRedeemRequest) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CANCEL_REDEEM_COUPON, {
            method: "POST",
            body: JSON.stringify({
                orderId: couponRedeemRequest.orderId,
                cardPaymentIntentId: couponRedeemRequest.cardPaymentIntentId,
                couponName: couponRedeemRequest.couponName
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let shoppingCart = await response.json();
            return shoppingCart;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async updateShoppingCart(token, shoppingCart) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_SHOPPING_CART, {
            method: "POST",
            body: JSON.stringify({
                orderId: shoppingCart.orderId,
                names: shoppingCart.names,
                companyGroup: shoppingCart.companyGroup
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async deleteShoppingCart(token, orderId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DELETE_SHOPPING_CART + "/" + orderId, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async disableShoppingCart(token, orderId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DISABLE_SHOPPING_CART, {
            method: "POST",
            body: JSON.stringify({
                orderId: orderId,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async checkSubmissionShoppingCart(token, shoppingCartSubmission) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CHECK_SUBMISSION_SHOPPING_CART, {
            method: "POST",
            body: JSON.stringify({
                orderId: shoppingCartSubmission.orderId,
                paymentIntentId: shoppingCartSubmission.paymentIntentId
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async requestRegistryPaymentSheet(token, registerTypeId, participantsCount) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REQUEST_REGISTER_PAYMENT_SHEET + "?language=" + mainStore.defaultLanguage, {
            method: "POST",
            body: JSON.stringify({
                paymentIntentMethod: "card",
                registerTypeId: registerTypeId,
                participantsCount: participantsCount,
                origin: "Web"
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentSheetData = await response.json();
            return paymentSheetData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async submitRegistryForm(token, paymentIntentId, registryFormModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.SUBMIT_REGISTRY_FORM + "/" + paymentIntentId, {
            method: "POST",
            body: JSON.stringify(registryFormModel),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getPastPayments(token) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_PAST_PAYMENTS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentData = await response.json();
            return paymentData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getFuturePayments(token) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_FUTURE_PAYMENTS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentData = await response.json();
            return paymentData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getPastRegistryPayments(token) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_PAST_REGISTRY_PAYMENTS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentData = await response.json();
            return paymentData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getFutureRegistryPayments(token) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_FUTURE_REGISTRY_PAYMENTS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentData = await response.json();
            return paymentData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async downloadPdf(token, purchase) {

        let capcha = await commonsApi.getCaptcha();

        let url = purchase.type === 'TICKET' ? URLS.DOWNLOAD_TICKETS : URLS.DOWNLOAD_REGISTRIES;

        let response = await fetch(url + "/" + purchase.eventId + "/" + purchase.id, {
            method: "GET",
            headers: {
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let fileData = await response.blob();
            return fileData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getPaymentHistory(token, page, pageSize) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_PAYMENTS_HISTORY + "?page=" + page + "&size=" + pageSize, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentsHistoryData = await response.json();
            return paymentsHistoryData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }

    },

    async getRegistryPaymentHistory(token, page, pageSize) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_REGISTRY_PAYMENTS_HISTORY + "?page=" + page + "&size=" + pageSize, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryPaymentsHistoryData = await response.json();
            return registryPaymentsHistoryData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }

    },

    async requestSetupIntent(token, connectedAccountId) {

        let capcha = await commonsApi.getCaptcha();

        var url = URLS.REQUEST_SETUP_INTENT;

        if (connectedAccountId) {
            url += '?connectedAccountId=' + connectedAccountId;
        }


        let response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let setupIntentdata = await response.json();
            return setupIntentdata.paymentIntentIdClientSecret;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getCards(token, paymentIntentId, connectedAccountId) {

        let capcha = await commonsApi.getCaptcha();

        var url = URLS.LIST_CARDS + "?paymentIntentId=" + paymentIntentId;

        if (connectedAccountId) {
            url += '&connectedAccountId=' + connectedAccountId;
        }

        let response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let cards = await response.json();
            return cards;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }

    },

    async getPlansForCard(token, paymentIntentId, paymentMethodId, connectedAccountId) {

        let capcha = await commonsApi.getCaptcha();

        var url = URLS.LIST_PLANS_FOR_CARD + "?paymentIntentId=" + paymentIntentId + "&paymentMethodId=" + paymentMethodId;

        if (connectedAccountId) {
            url += '&connectedAccountId=' + connectedAccountId;
        }

        let response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let cardPlans = await response.json();
            return cardPlans;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async deleteCard(token, paymentMethodId, connectedAccountId) {

        let capcha = await commonsApi.getCaptcha();

        var url = URLS.DELETE_CARD + "?paymentMethodId=" + paymentMethodId;

        if (connectedAccountId) {
            url += '&connectedAccountId=' + connectedAccountId;
        }

        let response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }

    },

    async confirmPaymentIntent(token, connectedAccountId, cardInstallmentPlan) {

        let capcha = await commonsApi.getCaptcha();

        var url = URLS.CONFIRM_PAYMENT_INTENT;

        if (connectedAccountId) {
            url += '?connectedAccountId=' + connectedAccountId;
        }

        let response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(cardInstallmentPlan),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let paymentIntentConfirmResponse = await response.json();
            return paymentIntentConfirmResponse
                ;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }
}