<template>
    <ui-skeleton :loading="eventLoading" active :paragraph="{ rows: 15 }">
        <h1 v-if="initialDataLoaded && tdRegisterType">{{ fullConcept }}</h1>
        <div v-if="initialDataLoaded && tdRegisterType">
            <image-text-header :image-url="tdEvent.urlBanner" :description="tdEvent.description">
            </image-text-header>
            <ui-grid class="payment-grid">
                <ui-grid-cell class="payment-details-cell" columns="4">
                    <assign-registries :event-id="tdEvent.id" :td-register-type="tdRegisterType"
                        :participants-count="participantsCount">
                    </assign-registries>
                </ui-grid-cell>
                <ui-grid-cell class="payment-details-cell" columns="4">
                    <payment-details :isTicket="false" :concept="fullConcept" :quantity="participantsCount"
                        :description="tdRegisterType.registerTypeDescription" :total-amount="totalAmount"
                        :payment-subtotal="paymentSubtotal" :service-subtotal="serviceSubtotal"
                        :currency="tdRegisterType.currency">
                    </payment-details>
                </ui-grid-cell>
                <ui-grid-cell class="payment-details-cell" columns="4">
                    <payment-methods :is-ticket="false" :event-id="tdEvent.id" :total-amount="totalAmount"
                        :registerTypeId="tdRegisterType.registerTypeId" :participants-count="participantsCount"
                        :full-register-type-name="fullRegisterTypeName" :currency="tdRegisterType.currency"
                        :td-account="tdRegisterType.tdAccount" :connect-account-id="tdRegisterType.connectAccountId"
                        :msi-enabled="tdRegisterType.msiEnabled">
                    </payment-methods>
                </ui-grid-cell>
            </ui-grid>
        </div>
    </ui-skeleton>
</template>

<script>

import eventsApi from '@/api/EventsApi';
import { useMainStore } from '@/stores/main';
import { useUserStore } from '@/stores/user';
import { helpers } from 'balm-ui';
import ImageTextHeader from '@/components/common/ImageTextHeader.vue';
import AssignRegistries from '@/components/registries/AssignRegistries.vue';
import PaymentMethods from '@/components/payment/PaymentMethods.vue';
import PaymentDetails from '@/components/payment/PaymentDetails.vue';
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';
import { analytics, logEvent } from "@/utils/firebase"

export default {
    name: "RegistroView",
    components: { ImageTextHeader, AssignRegistries, PaymentMethods, PaymentDetails },
    data() {
        return {
            tdEvent: Object,
            tdRegisterType: Object,
            initialDataLoaded: false,
            eventLoading: false,
            errorLoad: false,
            errorMessage: ""
        }
    },
    async mounted() {

        if (window.grecaptcha) {

            window.grecaptcha.enterprise.ready(async () => {

                try {
                    const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.PAGE_LOAD_REGISTRY });
                    await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.PAGE_LOAD_REGISTRY, token, process.env.VUE_APP_RECAPTCHA_KEY)
                }
                catch (error) {
                    console.error(error)
                    this.$router.push("/not-found")
                }
            });
        }

        this.validateQueryParams();
        await this.loadRegisterType();
        await this.loadEvent();

        logEvent(analytics, 'create_register', {
            event_name: this.tdEvent.name,
            register: this.tdRegisterType.registerTypeTitle + ':' + this.tdRegisterType.registerTypeName
        })
    },
    computed: {
        fullConcept() {
            return this.tdRegisterType.registerTypeTitle + " - " + this.tdRegisterType.registerTypeName
        },
        participantsCount() {
            return parseInt(this.$route.query.quantity)
        },
        paymentSubtotal() {
            return this.tdRegisterType.registerTypePrice * this.participantsCount
        },
        serviceSubtotal() {
            return this.tdRegisterType.registerTypeCommission * this.participantsCount
        },
        totalAmount() {
            return (this.tdRegisterType.registerTypePrice + this.tdRegisterType.registerTypeCommission) * this.participantsCount
        },
        fullRegisterTypeName() {
            return this.tdRegisterType.registerTypeTitle + ":" + this.tdRegisterType.registerTypeName
        }
    },
    methods: {

        validateQueryParams() {

            if (helpers.isEmpty(this.$route.query.registerTypeId)
                || helpers.isEmpty(this.$route.query.quantity)
                || isNaN(parseInt(this.$route.query.quantity))
                || helpers.isEmpty(this.$route.query.eventId)) {

                //console.log("empty params")
                this.$router.push("/not-found")
            }

        },

        async loadRegisterType() {

            this.eventLoading = true
            this.errorLoad = false

            try {
                const userStore = useUserStore();
                this.tdRegisterType = await eventsApi.getRegisterTypeById(userStore.userLogged.token, this.$route.query.registerTypeId)
                this.eventLoading = false;
            } catch (error) {
                this.errorLoad = true;
                this.eventLoading = false;
                this.$router.push("/not-found")
            }
        },

        async loadEvent() {
            this.eventLoading = true
            this.errorLoad = false

            try {
                this.tdEvent = await eventsApi.getEventById(this.$route.query.eventId)

                if (this.tdEvent) {

                    const store = useMainStore()
                    store.$patch({ pageTitle: this.$t('registries.title', [this.tdRegisterType.registerTypeTitle, this.tdEvent.name]) })
                    this.eventLoading = false;
                    this.initialDataLoaded = true;

                } else {
                    // Event not found
                    this.$router.push("/not-found")
                }

            } catch (error) {
                this.errorLoad = true;
                this.eventLoading = false;
            }
        },
    }
}
</script>

<style scoped>
h1 {
    text-align: center;
}

.payment-grid {
    background: #eee;
}

.payment-details-cell {
    min-height: 500px;
}
</style>