import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/main'
import HomeView from '@/views/HomeView.vue'
import LoginCreateView from '@/views/LoginCreateView.vue'
import ForgotPasswordView from '@/views/ForgotPasswordView.vue'
import PerfilUsuarioView from '@/views/PerfilUsuarioView.vue'
import NoContentView from '@/views/NoContentView.vue'
import EventDetailView from '@/views/EventDetailView.vue'
import SelectTicketView from '@/views/SelectTicketView.vue'
import SelectRegisterTypeView from '@/views/SelectRegisterTypeView.vue'
import MisionVisionView from '@/views/MisionVisionView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import TermsView from '@/views/TermsView.vue'
import FaqsView from '@/views/FaqsView.vue'
import ShoppingCartView from '@/views/ShoppingCartView.vue'
import CheckPaymentStatusView from '@/views/CheckPaymentStatusView.vue'
import RegistroView from '@/views/RegistroView.vue'
import PurchasesView from '@/views/PurchasesView.vue'
import TransactionHistoryView from '@/views/TransactionHistoryView.vue'
import BankTransfersView from '@/views/BankTransfersView.vue';
import i18n from "@/i18n"

const routes = [
  {
    path: '/',
    component: HomeView,
    meta: {
      checkShoppingCart: true,
    }
  },
  {
    path: '/login-create',
    component: LoginCreateView,
    meta: {
      requiresLogout: true,
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordView,
    meta: {
      requiresLogout: true,
    }
  },
  {
    path: '/mi-cuenta',
    component: PerfilUsuarioView,
    meta: {
      requiresAuth: true,
      checkShoppingCart: true,
    }
  },
  {
    path: '/evento/:eventId',
    name: "eventDetail",
    component: EventDetailView,
    meta: {
      checkShoppingCart: true,
    }
  },
  {
    path: '/select-ticket/:eventId',
    name: "selectTicket",
    component: SelectTicketView,
    meta: {
      checkShoppingCart: true,
    }
  },
  {
    path: '/select-register-type/:eventId',
    name: "selectRegisterType",
    component: SelectRegisterTypeView,
    meta: {
      checkShoppingCart: true,
    }
  },
  {
    path: '/shopping-cart',
    component: ShoppingCartView,
    meta: {
      checkShoppingCart: true,
      requiresAuth: true,
    }
  },
  {
    path: '/check-payment-status',
    component: CheckPaymentStatusView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-checkout',
    component: RegistroView,
    meta: {
      checkShoppingCart: true,
      requiresAuth: true,
    }
  },
  {
    path: '/compras',
    component: PurchasesView,
    meta: {
      checkShoppingCart: true,
      requiresAuth: true,
    }
  },
  {
    path: '/transacciones',
    component: TransactionHistoryView,
    meta: {
      checkShoppingCart: true,
      requiresAuth: true,
    }
  },
  {
    path: '/transferencias-bancarias',
    component: BankTransfersView,
    meta: {
      checkShoppingCart: true,
      requiresAuth: true,
    }
  },
  {
    path: '/mision-vision',
    component: MisionVisionView
  },
  {
    path: '/aviso-privacidad',
    component: PrivacyPolicyView
  },
  {
    path: '/condiciones-uso',
    component: TermsView
  },
  {
    path: '/faqs',
    component: FaqsView
  },
  {
    path: '/:catchAll(.*)*',
    component: NoContentView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return {
      top: 0
    }
  }
})

router.beforeResolve(async to => {

  if (to.meta.checkShoppingCart) {

    const userStore = useUserStore();

    if (userStore.userLogged) {

      const payload = {
        token: userStore.userLogged.token,
      };

      await userStore.checkShoppingCart(payload);

    }
  }
})

router.beforeEach(to => {

  //console.log("beforeEach")

  const mainStore = useMainStore();
  const userStore = useUserStore();

  if (to.meta.requiresAuth && !userStore.userLogged) {
    // This route requires auth, check if logged in.
    // If not, redirect to login page.
    return {
      path: "/login-create",
      // save the location we are trying to access before Logging in, in order to come back later
      query: { redirect: to.fullPath }
    };
  } else if (to.meta.requiresLogout && userStore.userLogged) {
    // this route requires that the user is not logged in.
    // If the user is logged in, it will be redirected to home.
    return {
      path: "/"
    }
  } else {
    mainStore.pageTitle = i18n.global.t('titles.home')
    return;
  }
})

export default router
