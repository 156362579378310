<template>
    <h1>{{ $t('footer.linksTerms') }}</h1>
    <div v-shape class="container">
        <p>
            Bienvenido a Ticket Dancer. A continuación se describen las Condiciones de Uso (En adelante las
            “Condiciones”) que rigen el uso del sitio web y las aplicaciones móviles de Ticket Dancer (en adelante, el
            “Sitio”). Al usar o visitar el Sitio, expresamente manifiestas tu conformidad y te obligas con los términos
            y condiciones de esta página y las “apps”, así como de todas las leyes y reglamentos aplicables de
            conformidad a la legislación vigente y aplicable para el uso del Sitio. Ticket Dancer, se reserva el derecho
            a cambiar estas Condiciones en cualquier momento, las cuales aplicaran inmediatamente después de que hayan
            sido publicadas en el Sitio. Por favor, visite esta página del sitio regularmente. Si usted viola estas
            Condiciones, Ticket Dancer puede cancelar su uso de este Sitio, excluirlo para el uso en un futuro de
            operaciones que pretenda realizar en el Sitio, cancelar boletos que haya recibido a través del Sitio,
            cancelar su pedido de boletos, y/o tomar la acción legal que juzgue conveniente para sus intereses.
        </p>
        <h2>Uso permitido</h2>
        <p>En este acto manifiestas plenamente que conoces que únicamente estas autorizado a visitar, ver y consultar
            las páginas de este Sitio para tu propio uso, y que no deberás duplicar, descargar, publicar, modificar o
            distribuir de cualquier otra manera alterar el contenido de página, o del material contenido en este Sitio
            para ningún propósito distinto que no sea revisar eventos e información de publicidad, para uso personal, o
            para comprar boletos o mercancía a menos de que haya sido autorizado específicamente por Ticket Dancer. El
            contenido y software de este Sitio es propiedad de Ignacio García Martell y está protegido bajo las leyes
            internacionales y nacionales de derecho de autor. Hemos publicado un aviso legal y varios títulos de crédito
            en las páginas del Sitio, estos no deberán ser removidos. Por favor, no elimines este aviso o estos
            créditos, o cualquier información adicional que venga incluida con los avisos y créditos.</p>
        <h2>Política de compra de boletos</h2>
        <p>Veracidad y Exactitud de la Información que nos Proporcionas; Cumplimiento con las Leyes; Verificaciones y
            Autorizaciones.</p>
        <p>La venta de boletos a eventos de entretenimiento está regulada a nivel estatal, municipal o en su caso
            federal. Se te podrá pedir que proporciones cierta información cuando se involucre en ciertos procesos del
            Sitio. En este acto manifiestas y garantizas que toda la información que proporcionas es verdadera, completa
            y correcta, y que actualizarás toda la información a medida que cambie. También reconoces que cumplirás en
            todo momento con los términos, condiciones y leyes que rigen el presente servicio, Y manifiestas estar de
            ACUERDO EN LIBERAR A Ticket Dancer DE CUALQUIER RESPONSABILIDAD, DERIVADAS DE LA FALTAS COMETIDAS POR TI EN
            CONTRA DE CUALQUIER LEY, NOSOTROS CUMPLIREMOS CON LAS AUTORIDADES QUE ASÍ LO REQUIERAN Y LES PODREMOS
            PROPORCIONAR TODA LA INFORMACIÓN QUE TU NOS HAYAS REMITIDO PARA AYUDAR EN CUALQUIER INVESTIGACIÓN O PROCESO
            QUE PUEDAN LLEVAR A CABO. Si no podemos verificar o comprobar la autenticidad de cualquier información o
            boletos que nos proporciones durante cualquier proceso de inscripción, pedido, compra, publicación de
            boletos, venta, verificación de autenticidad, entrega, pago o proceso de remesa, o cualquier otro proceso no
            mencionado aquí, o si no podemos verificar o autorizar tu tarjeta de crédito o información de cuenta
            bancaria, entonces se te prohibirá el uso del Sitio.</p>
        <p>Cada usuario está limitado a una cantidad de boletos que el organizador del evento establece como máximo.</p>
        <h2>Política de reembolsos y devoluciones</h2>
        <p>Ticket Dancer NO hace reembolsos ni devoluciones de ninguna transacción realizada a través de la plataforma,
            a excepción de los siguientes escenarios:
        </p>
        <p>(a) El Evento es cancelado o pospuesto por el organizador del mismo.</p>
        <p>
            En caso de que el Usuario de la plataforma solicite un reembolso o devolución y se encuentre bajo las
            excepciones mencionadas en el punto anterior, el monto del reembolso corresponderá a el precio del boleto sin
            contar la comisión por servicio por el uso de nuestra plataforma desglosado en el comprobante de compra enviado
            por correo. Así mismo, dicho reembolso se efectuará dentro de los próximos 30 días naturales después de haberlo
            solicitado y será realizado al mismo medio de pago con el que se realizó la transacción.
        </p>
        <p>COMO ALTERNATIVA, Ticket Dancer ofrece la transferencia de boletos de un usuario a otro dentro de la misma
            plataforma, bajo aprobación de parte del organizador. Dicha opción puede estar sujeta a cargo por servicio.
        </p>
        <h2>Política de transferencias bancarias</h2>
        <p>Agregar la referencia de pago que se muestra en la compra al momento de realizar la transferencia.
            De no poner la referencia no podrá ser localizado tu pago, por lo que no podremos realizar aclaraciones o devoluciones.
        </p>
        <ul>
            <li>Verifica cuidadosamente la cantidad a transferir.</li>
            <li>Una vez que completes el total de tu pago, te llegarán tus boletos a este correo electrónico de manera automática de 1 a 3 días hábiles.</li>
            <li>Ticket Dancer no se hace responsable por fondos enviados incorrectamente.</li>
        </ul>
        <p>Para cualquier aclaración por favor envía un correo a <a
                href="mailto:soporte@ticketdancer.com.mx">soporte@ticketdancer.com.mx.</a>
                Si tu aclaración es por devolución y ésta aplica, se te reembolsará el monto menos los cargos por servicio derivados de la operación.
        </p>
        <h2>Enlaces y resultados de búsqueda</h2>
        <p>El Sitio puede producir automáticamente resultados de búsqueda que tienen referencia o se enlazan a sitios de
            terceros a través de la Red Mundial World Wide Web. Ticket Dancer no tiene control sobre estos sitios o su
            contenido. Ticket Dancer no puede garantizar, representar o asegurar que el contenido de estos sitios sea
            exacto, legal y/o inofensivo. Ticket Dancer no patrocina el contenido de sitios de terceros, ni asegura que
            no contendrán virus o que no impactarán de cualquier otra manera su computadora. Al utilizar este Sitio para
            realizar búsquedas o para enlazarse a otro sitio, tu estas de acuerdo y entiendes que no podrás realizar
            ningún reclamo en contra de Ticket Dancer por cualquier daño o pérdida, cualquiera que sea, que resulte al
            usar el Sitio para obtener resultados de búsqueda o enlaces a otro sitio. Si tienes un problema con un
            enlace del Sitio, por favor envía un correo electrónico a <a
                href="mailto:soporte@ticketdancer.com.mx">soporte@ticketdancer.com.mx.</a></p>
        <h2>Uso comercial</h2>
        <p>Ningún tablero de anuncios, de este Sitio pueden ser usado por nuestros visitantes dentro de los términos
            establecidos por Ticket Dancer así como por la legislación de la materia por lo que nos reservamos el
            derecho a bloquearte el acceso a este Sitio o a otros servicios de Ticket Dancer, o a cancelar un boleto o
            una orden de boletos en relación con cualquier persona que se cree ser, o que se cree que está actuando en
            conexión con cualquier persona que se crea que esté violando la ley o los términos establecidos por los
            derechos de Ticket Dancer, o bien que ha ordenado un número de boletos que excede los límites establecidos.
            El violar cualquiera de las limitaciones o los términos de este Sitio será considerado como una violación de
            estos Términos.
        </p>
        <h2>Uso NO autorizado del sitio</h2>
        <p>Cualquier uso ilegal o no autorizado de este sitio constituye una violación de estas Condiciones de Uso. El
            uso no autorizado de cualquier robot, araña o cualquier otro proceso automatizado en el sitio. Lo siguiente
            también representa violaciones de estas Condiciones de Uso:
        </p>
        <p>(a) que cualquier individuo que solicite de manera excesiva el uso de las páginas del sitio dentro del
            periodo de veinticuatro horas se entenderá como “Uso Ilegal”); o</p>
        <p>(b) el usar cualquier contraseña o código de acceso, sin importar si tal contraseña o código de acceso es
            único, para participar en una pre-venta o cualquier otro tipo de oferta en el Sitio si tu no eres el
            destinatario original de tal código de acceso o contraseña (por ejemplo: si tu no recibiste ese código de
            acceso o contraseña de Ticket Dancer o cualquier otra organización con la cual Ticket Dancer está trabajando
            conjuntamente para permitir dicha pre-venta u oferta).</p>
        <p>Sospecha de violación de estas Condiciones de uso o de la ley; medidas cautelares, equitativas e
            indemnización de daños y perjuicios</p>
        <p>Las violaciones de estas Condiciones de Uso, incluyendo el uso no autorizado del sitio, pueden ser
            investigadas y se pueden tomar las acciones legales necesarias, incluyendo más no limitándose acciones
            civiles, penales y cautelares. En este acto manifiestas tu conformidad que a la sola discreción de Ticket
            Dancer, y sin previo aviso, Ticket Dancer puede restringir tu acceso al Sitio, cancelar tu pedido de boletos
            o boletos adquiridos a través de tu pedido de boletos, cancelar su publicación de boletos, eliminar
            cualquier Contenido del Usuario no autorizado o ejercer cualquier otro recurso disponible, si Ticket Dancer
            tiene elementos o indicios que tu conducta o la conducta de cualquier tercero con la cual Ticket Dancer cree
            que actúas en común, o el Contenido de Usuario que usted proporcionaste, son inconsistentes con los Términos
            establecidos en esta página o con la Legislación , o que violan los derechos de Ticket Dancer, de un usuario
            de Ticket Dancer o cualquier otro usuario del Sitio. En este acto manifiestas tu conformidad en que los
            daños monetarios pueden no proporcionar una solución suficiente para Ticket Dancer en cuanto a la violación
            de estos Términos y con el pago de daños y perjuicios o cualquier otra compensación equitativa de tales
            violaciones.</p>
        <p>Acuerdas en que el Uso Ilegal del sitio, como se definió anteriormente, puede causar daños y perjuicios a
            Ticket Dancer que puede ocasionar entre otras cosas el impedimento de mantener una buena relación con los
            clientes, la pérdida de ventas y el aumento de los gastos involucrados en combatir el Uso. También estás de
            acuerdo en que los daños monetarios por el Uso ilegal del sitio son difíciles de averiguar y que comprobar
            los daños monetarios por Uso ilegal son costosos e inconvenientes de calcular. De acuerdo a esto, acuerdas
            que la indemnización por daños y prejuicios están justificados por el Uso abusivo. Por lo tanto, estás de
            acuerdo en que si tú o cualquier otra persona que actúe conjuntamente contigo sólo o de manera colectiva
            soliciten más de 1000 páginas del sitio en un periodo de veinticuatro horas, tú y aquellas personas que
            actúen contigo podrán ser solidariamente o separadamente responsables de liquidar los daños en la cantidad
            de cinco dólares ($5.00) por página solicitada cada vez que se haga una solicitud de página después de que
            se hayan realizado las primeras 1000 solicitudes de páginas durante un período de veinticuatro horas.
            También estás de acuerdo en que esta será la manera de medir los daños de cualquier Uso ilegal que se
            presentara antes de que esta estipulación en estas Condiciones de Uso entraran en vigor.</p>
        <p>Ticket Dancer no tiene la obligación de proporcionarte ningún tipo de reembolso si ejerce cualquiera de sus
            derechos o recursos si se determina que has violado estos Términos o cualquier otro derecho de Ticket
            Dancer. Además, nos reservamos el derecho, a nuestra propia discreción, de modificar, suspender o
            descontinuar cualquier parte de este Sitio en cualquier momento, con o sin previo aviso. También nos
            reservamos el derecho, a nuestra propia discreción, de imponer límites en ciertas funciones y servicios y en
            restringir el acceso a cualquier parte o a todo el Sitio sin previo aviso. Nosotros no tendremos ninguna
            responsabilidad legal hacia ti o hacia cualquier tercera parte por ningún reclamo o causa de acción que
            surja al ejercer los derechos anteriormente mencionados.</p>
        <h2>Privacidad</h2>
        <p>Creemos que tu privacidad y la privacidad de nuestros usuarios es muy importante. Estos Términos están
            sujetos a la Política de Privacidad, la cual es incorporada a la presente para su referencia. Ticket Dancer
            no es responsable o de cualquier otra manera responsable legalmente de cualquier uso o divulgación de su
            información de contacto, o información financiera, por parte de un tercero con quien Ticket Dancer tenga
            permitido divulgar su información de contacto bajo esta política de privacidad. No deberás colocar en el
            Sitio información personal que pueda ser utilizada para identificarte o ponerse en contacto contigo,
            incluyendo, más no limitándose a tu nombre, dirección de residencia o trabajo, números de teléfono, pagers o
            localizadores, direcciones de correo electrónico o cualquier otro tipo de información. Si “colocas” o
            “publicas” esta información, Ticket Dancer no puede prevenir que sea utilizada en una manera que viole estos
            Términos, la ley, o su privacidad personal o su seguridad. Al “colocar” o “publicar” dicha información en el
            Sitio, violas estos Términos.</p>
        <p>Ticket Dancer no es responsable por ninguna pérdida de datos que surja por la operación del Sitio o por
            aplicar los Términos. Instamos a todos los usuarios a que mantengan su propia versión de respaldo de
            cualquier Contenido de Usuario o cualquier otro tipo de información que emitan al Sitio.</p>
        <h2>Política de derechos de autor</h2>
        <p>Nosotros determinaremos los privilegios de cualquier usuario que utilice este Sitio para transmitir
            ilegalmente cualquier material con derechos de autor sin previo consentimiento expreso de licencia, defensa
            válida o exención de uso justo para hacerlo. En particular, los usuarios que emitan Contenido de Usuario a
            este Sitio, ya sean artículos, imágenes, historias, software o cualquier otro material con derechos de
            autor; deben de asegurarse que el Contenido de Usuario cargado no infringe ningún derecho de autor o
            cualquier otro derecho de terceros (incluyendo, más no limitándose a, marcas registradas, secretos
            comerciales, derechos de privacidad o publicidad). Después de ser notificados debidamente por el dueño de
            los derechos de autor o sus agentes, y después de que se reciba una confirmación a través de una orden de
            corte o por admisión por parte del usuario de que han utilizado este Sitio como un instrumento de infracción
            ilegal, entonces nosotros cancelaremos los derechos del usuario que ha infringido los derechos de autor para
            usar y/o acceder a este Sitio. También podremos, a nuestra propia discreción, decidir y cancelar los
            derechos del usuario para usar o acceder al Sitio antes de ese momento, si creemos que se ha llevado a cabo
            la infracción en cuestión.</p>
        <h2>Cláusula de exención de responsabilidades</h2>
        <p>TICKET DANCER NO SE OBLIGA A QUE EL SITIO ESTE LIBRE DE ERRORES, QUE NO TENDRÁ INTERRUPCIONES, O QUE
            PROPORCIONE RESULTADOS ESPECÍFICOS POR EL USO DEL SITIO O POR CUALQUIER CONTENIDO, BÚSQUEDA O ENLACE EN EL
            SITIO Y SUS CONTENIDOS SON ENTREGADOS “COMO ESTÁ” Y “COMO ESTÉ DISPONIBLE”. TICKET DANCER NO PUEDE ASEGURAR
            QUE LOS ARCHIVOS QUE DESCARGE DEL SITIO ESTARÁN LIBRES DE VIRUS O DE CONTAMINACIÓN O DE CARACTERÍSTICAS
            DESTRUCTIVAS. TICKET DANCER SE LIBERA DE TODA RESPONSABILIDAD DE GARANTÍAS, TICKET DANCER NO SERÁ
            RESPONSABLE POR NINGUN DAÑO DE CUALQUIER TIPO QUE SURJA DEL USO DE ESTE SITIO, INCLUYENDO SIN LIMITACIÓN,
            DAÑOS DIRECTOS, INDIRECTOS, INCIDENTALES, PUNITIVOS Y CONSECUENCIALES. TICKET DANCER NO GARANTIZA NINGÚ
            RESULTADO ESPECÍFICO DEL USO DE ESTE SITIO O EL USO DE LOS SERVICIOS DE TICKET DANCER.</p>
        <p>TICKET DANCER SE LIBERA DE CUALQUIER Y TODA RESPONSABILIDAD POR CUALQUIER HECHO, OMISIÓN Y CONDUCTA POR PARTE
            DE CUALQUIER USUARIO DE TERCEROS, USUARIO DE TICKET DANCER, PUBLICISTAS Y/O PATROCINADORES DEL SITIO, EN
            CONEXIÓN CON EL SERVICIO DE TICKET DANCER O DE CUALQUIER OTRA FORMA RELACIONADA CON SU USO DEL SITIO Y/O DEL
            SERVICIO DE TICKET DANCER. TICKET DANCER NO ES RESPONSABLE POR LOS PRODUCTOS, SERVICIOS, ACCIONES O FALTA DE
            ACCIÓN DE CUALQUIER LUGAR, ARTISTA, PROMOTOR O CUALQUIER TERCERO EN CONEXIÓN CON EL SITIO O AL QUE SE HA
            HECHO REFERENCIA EN EL SITIO.</p>
        <p>Sin prejuicio de lo anteriormente mencionado, usted puede reportar la mala conducta de usuarios y/o
            publicistas de terceros, proveedores de servicios y/o productos a los que se hace referencia o que están
            incluidos en el Sitio de Ticket Dancer a través de este formulario. Ticket Dancer puede investigar el
            reclamo y tomar la acción adecuada, a su plena discreción.</p>
        <h2>Límites de responsabilidad</h2>
        <p>BAJO NINGUNA CIRCUNSTANCIA TICKET DANCER SERÁ RESPONSABLE DE CUALQUIER DAÑO INDIRECTO, CONSECUENCIAL,
            EJEMPLAR, INCIDENTAL, ESPECIAL O PUNITIVO, O POR LA PÉRDIDA DE GANANCIAS, INGRESOS U OPORTUNIDADES DE
            NEGOCIOS, AUN CUANDO SE LE HAYA AVISADO A Ticket Dancer DE LAS POSIBILIDADES DE TALES DAÑOS.</p>
        <h2>Disputas</h2>
        <p>Si tienes una disputa y tu disputa involucra un evento (o el boleto de un evento) que está localizado en los
            Estados Unidos Mexicanos, entonces la disputa será regida por las leyes y la jurisdicción de la Ciudad de
            México, CDMX, renunciando en este acto de cualquier otro jurisdicción que pudiere corresponderle de
            conformidad a su domicilio presente o futuro.</p>
        <h2>Indemnización</h2>
        <p>En este acto manifiestas de manera expresa que es tu voluntad el liberarnos de manera expresa de cualquier
            indemnización, liberando a Ticket Dancer y sus filiales, y a cada uno de los funcionarios, gerentes,
            agentes, empleados, contratistas y principales de las filiales de Ticket Dancer de cualquier responsabilidad
            de perdida, obligación, reclamo o demanda, incluyendo los honorarios razonables de abogados, realizados por
            un tercero, debido a, o que surjan del uso de este Sitio, incluyendo también su uso del Sitio para
            proporcionar un enlace a otro Sitio o para cargar contenido o cualquier otra información al Sitio.</p>
        <h2>Marcas registradas</h2>
        <p>Ticket Dancer y Ticket Dancer.com.mx y sus diseños son marcas registradas de Ignacio García Martell. Todos
            los derechos reservados {{ new Date().getFullYear() }}.</p>
    </div>
</template>
<script>
export default {
    name: "TermsView"
}
</script>

<style scoped>
.container {
    max-width: 60%;
    margin: auto;
    background: white;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

h1 {
    text-align: center;
}

h2 {
    margin: 20px 50px;
}

p,li {
    text-align: justify;
    margin: 20px 50px;
}

a {
    color: #652d66;
}
</style>