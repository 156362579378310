<template>
    <ui-skeleton :loading="eventLoading" active :paragraph="{ rows: 15 }">
        <div class="content">
            <div v-if="initialDataLoaded && shoppingCart" class="time-container">
                {{ $t('shoppingCart.remainingTime') }}
                <br>
                {{ remainingMinutesText }} : {{ remainingSecondsText }}
            </div>
            <h1>{{ $t('shoppingCart.title') }}</h1>
            <div v-if="initialDataLoaded && shoppingCart">
                <image-text-header :image-url="tdEvent.urlBanner" :description="tdEvent.description">
                </image-text-header>
                <ui-grid class="payment-grid">
                    <ui-grid-cell class="payment-details-cell" columns="4">
                        <assign-tickets :order-id="shoppingCart.orderId" :mandatory-names="shoppingCart.isMandatoryName"
                            :num-names-required="shoppingCart.numNamesRequired" :saved-names="shoppingCart.names"
                            :saved-company-group="shoppingCart.companyGroup">
                        </assign-tickets>
                    </ui-grid-cell>
                    <ui-grid-cell class="payment-details-cell" columns="4">
                        <payment-details :isTicket="true" :concept="shoppingCart.ticketName"
                            :quantity="shoppingCart.numTickets" :description="shoppingCart.ticketDescription"
                            :total-amount="shoppingCart.totalamount" :payment-subtotal="shoppingCart.totalSinComision"
                            :service-subtotal="shoppingCart.comisionNormal" :coupon-amount="shoppingCart.couponAmount"
                            :currency="shoppingCart.currency">
                        </payment-details>
                    </ui-grid-cell>
                    <ui-grid-cell class="payment-details-cell" columns="4">
                        <payment-methods :is-ticket="true" :event-id="tdEvent.id" :order-id="shoppingCart.orderId"
                            :total-amount="shoppingCart.totalamount" :with-coupon="withCoupon"
                            :applied-coupon-name="shoppingCart.couponName" :currency="shoppingCart.currency"
                            :td-account="shoppingCart.tdAccount" :connect-account-id="shoppingCart.connectAccountId"
                            :msi-enabled="shoppingCart.msiEnabled">
                        </payment-methods>
                    </ui-grid-cell>
                </ui-grid>
                <div class="deleteContainer">
                    <ui-button class="destroy" @click.prevent="deleteCurrentShoppingCart" :disabled="loading">
                        {{ $t('shoppingCart.deleteButton') }}
                        <template #after="{ iconClass }">
                            <ui-icon :class="iconClass">delete</ui-icon>
                        </template>
                    </ui-button>
                </div>
            </div>
            <div v-else class="empty-po-container" v-shape>
                <ui-icon class="empty-po-icon" size="48">remove_shopping_cart</ui-icon>
                <p class="empty-po-text"> {{ $t('shoppingCart.noActivePO') }} </p>
                <ui-button @click.prevent="goHome">
                    {{ $t('common.goHome') }}
                    <template #after="{ iconClass }">
                        <ui-icon :class="iconClass">home</ui-icon>
                    </template>
                </ui-button>
            </div>
        </div>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </ui-skeleton>
</template>

<script>
import { mapState } from 'pinia';
import { mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useMainStore } from '@/stores/main';
import { helpers } from 'balm-ui'
import ImageTextHeader from '@/components/common/ImageTextHeader.vue';
import AssignTickets from '@/components/tickets/AssignTickets.vue';
import PaymentMethods from '@/components/payment/PaymentMethods.vue';
import PaymentDetails from '@/components/payment/PaymentDetails.vue';
import ErrorComponent from '@/components/common/ErrorComponent.vue';
import eventsApi from '@/api/EventsApi';
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';
import { analytics, logEvent } from "@/utils/firebase"

export default {
    name: "ShoppingCartView",
    components: {
        ImageTextHeader,
        AssignTickets,
        PaymentMethods,
        PaymentDetails,
        ErrorComponent
    },
    data() {
        return {
            eventLoading: false,
            tdEvent: Object,
            initialDataLoaded: false,
            errorLoad: false,
            timerEnabled: true,
            errorMessage: "",
            remainingMinutesText: "--",
            remainingSecondsText: "--",
            remainingTimeInSeconds: 0,
            openConfirmDeletion: false,
            loading: false
        }
    },
    async mounted() {

        if (window.grecaptcha) {

            window.grecaptcha.enterprise.ready(async () => {

                try {
                    const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.PAGE_LOAD_SHOPPING_CART });
                    await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.PAGE_LOAD_SHOPPING_CART, token, process.env.VUE_APP_RECAPTCHA_KEY)
                }
                catch (error) {
                    console.error(error)
                    this.$router.push("/not-found")
                }
            });
        }

        // Checking if there is an Active Shopping Cart occurs in the 'beforeResolve' router callback
        await this.checkOrCreateShoppingCart()
        await this.loadEvent()
        this.remainingTimeInSeconds = this.shoppingCart.remainingTime * 60
        this.startTimer()

        logEvent(analytics, 'create_shopping_cart', {
            event_name: this.tdEvent.name,
            ticket_name: this.shoppingCart.ticketName
        })
    },
    computed: {
        ...mapState(useUserStore, ['shoppingCart']),
        withCoupon() {
            return this.shoppingCart.couponName && this.shoppingCart.couponAmount > 0
        },
    },
    watch: {
        timerEnabled(value) {
            if (value) {
                this.setupTimer()
            }
        },
        remainingTimeInSeconds(value) {
            if (value > 1 && this.timerEnabled) {
                this.setupTimer()
            } else {
                this.exitShoppingCartProcess();
            }
        }
    },
    methods: {
        ...mapActions(useUserStore, ['createShoppingCart', 'deleteShoppingCart', 'disableShoppingCart']),
        goHome() {
            this.$router.push("/");
        },
        async checkOrCreateShoppingCart() {

            if (helpers.isEmpty(this.shoppingCart)) {

                await this.createNewShoppingCart();

            } else {

                this.$confirm({
                    message: this.$t('shoppingCart.activeShoppingCartFound'),
                    state: 'warning',
                    acceptText: this.$t('alerts.new'),
                    cancelText: this.$t('alerts.previous')
                }).then(async (result) => {
                    if (result) {
                        await this.deleteShoppingCart(this.shoppingCart.orderId)
                        await this.createNewShoppingCart();
                    }
                });
            }
        },
        async createNewShoppingCart() {

            this.eventLoading = true
            const selectedTicketModel = {
                eventId: this.$route.query.eventId,
                ticketId: this.$route.query.ticketId,
                numTickets: this.$route.query.numTickets,
            }

            try {
                await this.createShoppingCart(selectedTicketModel);
                this.eventLoading = false;
            } catch (error) {
                this.eventLoading = false
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
                this.$alert({
                    message: error.mensaje,
                    state: 'error',
                });
                this.$router.push("/")
            }
        },
        async loadEvent() {
            this.eventLoading = true
            this.errorLoad = false

            try {
                this.tdEvent = await eventsApi.getEventById(this.shoppingCart.eventId)
                const store = useMainStore()
                store.$patch({ pageTitle: this.$t('shoppingCart.title') + ' - ' + this.tdEvent.name })
                this.eventLoading = false;
                this.initialDataLoaded = true;
            } catch (error) {
                this.errorLoad = true;
                this.eventLoading = false;
                this.errorMessage = error.mensaje;
            }
        },
        setupTimer() {
            setTimeout(() => {

                if (this.timerEnabled) {
                    this.remainingTimeInSeconds -= 1
                    const minutes = Math.trunc((this.remainingTimeInSeconds % 3600) / 60);
                    const seconds = (this.remainingTimeInSeconds % 3600) % 60;
                    this.remainingMinutesText = String(minutes).padStart(2, '0')
                    this.remainingSecondsText = String(seconds).padStart(2, '0')
                }

            }, 1000)
        },
        startTimer() {
            this.timerEnabled = true
        },
        stopTimer() {
            this.timerEnabled = false
        },
        exitShoppingCartProcess() {

            this.stopTimer()

            try {

                logEvent(analytics, 'cancel_shopping_cart', {
                    event_name: this.tdEvent.name,
                    ticket_name: this.shoppingCart.ticketName
                })

                this.disableShoppingCart(this.shoppingCart.orderId)
            } catch (error) {
                console.log(error)
            }

            this.$alert({
                message: this.$t('shoppingCart.timeout'),
                state: 'error',
            });

            this.$router.push({
                name: 'selectTicket',
                params: {
                    eventId: this.shoppingCart.eventId
                }
            });
        },
        openConfirmDialog() {
            this.openConfirmDeletion = true;
        },

        deleteCurrentShoppingCart() {

            this.$confirm({
                message: this.$t('shoppingCart.exitConfirmation'),
                acceptText: this.$t('alerts.deleteButton'),
                cancelText: this.$t('alerts.stayButton'),
            }).then(async (result) => {
                if (result) {

                    this.loading = true;
                    this.errorLoad = false;
                    try {
                        logEvent(analytics, 'cancel_shopping_cart', {
                            event_name: this.tdEvent.name,
                            ticket_name: this.shoppingCart.ticketName
                        })
                        await this.deleteShoppingCart(this.shoppingCart.orderId)
                        this.loading = false;
                    } catch (error) {
                        this.loading = false;
                        this.errorLoad = true;
                        this.errorMessage = error.mensaje
                        console.log(error)
                    }
                }
            });
        }
    }
}

</script>

<style scoped>
h1 {
    text-align: center;
}

.empty-po-container {
    text-align: center;
    margin-top: 100px;
}

.empty-po-icon {
    color: #666;
}

.empty-po-text {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #666;
    font-size: larger;
}

.time-container {
    min-height: 70px;
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    right: 0;
    z-index: 1;
    padding-top: 10px;
    text-align: center;
    background-color: #652d66;
    color: white;
    font-weight: bolder;
    font-size: x-large;
}

.payment-grid {
    background: #eee;
}

.payment-details-cell {
    min-height: 560px;
}

.deleteContainer {
    text-align: center;
    background-color: #eee;
}

button.destroy {
    color: #D32F2F;
}
</style>