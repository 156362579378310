import i18n from "@/i18n";

export default {
    getSupportedCurrencies() {
        return [
            {
                label: i18n.global.t('currencies.mxn'),
                value: 0
            },
            {
                label: i18n.global.t('currencies.usd'),
                value: 1
            }
        ]
    }
}