<template>
    <div v-shape class="header">
        <ui-grid>
            <ui-grid-cell columns="4" class="grid-cell">
                <img :src="imageUrl" />
            </ui-grid-cell>
            <ui-grid-cell columns="8">
                <div class="text" v-html="description">
                </div>
            </ui-grid-cell>
        </ui-grid>
    </div>
</template>

<script>
export default {
    name: "ImageTextHeader",
    props: {
        imageUrl: String,
        description: String
    }
}
</script>

<style scoped>
.header {
    max-width: 95%;
    margin: 0 auto;
    background: white;
}

.grid-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-cell img {
    max-height: 230px;
    width: 80%;
}

.text {
    text-align: justify;
    white-space: pre-wrap;
}
</style>