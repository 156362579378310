<template>
    <ui-button class="details" @click="toogleDetails"> {{ detailsText }} </ui-button>
    <div v-show="showDetails" class="container">
        <div class="row">
            <div class="label">
                {{ subtotalPayLbl }}
            </div>
            <div class="amount">
                {{ subtotalPayDescr }}
            </div>
        </div>
        <div v-show="withCoupon" class="row">
            <div class="label coupon">
                {{ $t('shoppingCart.couponRedeemed') }}
            </div>
            <div class="amount coupon">
                {{ couponAmountDescr }}
            </div>
        </div>
        <div class="row">
            <div class="label">
                {{ $t('shoppingCart.feeSubtotal') }}
            </div>
            <div class="amount">
                {{ subtotalServiceDescr }}
            </div>
        </div>
        <div class="row disclaimer">
            {{ $t('shoppingCart.nonRefundable') }}
        </div>
    </div>
</template>

<script>
import numberUtils from '@/utils/numberUtils'

export default {
    name: 'CheckoutTotalDetails',
    props: {
        isTicket: Boolean,
        paymentSubtotal: Number,
        serviceSubtotal: Number,
        couponAmount: Number,
        currency: String
    },
    data() {
        return {
            showDetails: false,
        }
    },
    computed: {
        detailsText() {
            return this.showDetails ? this.$t('events.hideTotalDetails') : this.$t('events.showTotalDetails')
        },
        subtotalPayLbl() {
            return this.isTicket ? this.$t('shoppingCart.ticketSubtotal') : this.$t('registries.registriesSubtotal')
        },
        subtotalPayDescr() {
            return numberUtils.getCurrencyFromNumber(this.paymentSubtotal, this.currency)
        },
        subtotalServiceDescr() {
            return numberUtils.getCurrencyFromNumber(this.serviceSubtotal, this.currency)
        },
        withCoupon() {
            return this.couponAmount && this.couponAmount > 0;
        },
        couponAmountDescr() {
            return this.withCoupon ? ("-" + numberUtils.getCurrencyFromNumber(this.couponAmount, this.currency)) : ""
        }
    },
    methods: {
        toogleDetails() {
            this.showDetails = !this.showDetails
        },
    }
}
</script>

<style scoped>
.container {
    margin-bottom: 150px;
    white-space: pre-wrap;
}

button.details {
    font-size: small;
}

.row {
    max-width: 300px;
    margin-bottom: 5px;
    float: right;
}

.label {
    width: 150px;
    float: left;
    font-size: 0.6em;
    font-weight: bolder;
}

.label.coupon {
    color: #006600;
}

.amount.coupon {
    color: #006600;
}

.amount {
    width: 150px;
    float: right;
    font-size: 0.6em;
    text-align: right;
}

.row.disclaimer {
    width: 100%;
    margin-top: 5px;
    text-align: right;
    font-size: 0.5em;
}
</style>