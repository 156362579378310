<template>
    <h1> {{ $t('events.allEventsTitle') }}</h1>
    <ui-grid class="events-grid">
        <ui-grid-cell columns="4" v-for="event in allEvents" :key="event.id">
            <td-event :withEvent=event>
            </td-event>
        </ui-grid-cell>
    </ui-grid>
    <ui-progress v-show="eventsLoading" indeterminate active></ui-progress>
    <div class="load-more-container" v-show="!eventsLoading">
        <ui-button outlined @click.prevent="getEvents"> {{ $t("events.loadMoreButton") }}</ui-button>
    </div>
</template>

<script>

import TdEvent from './TdEvent.vue';
import eventsApi from '@/api/EventsApi';

export default {
    name: "AllEvents",
    components: {
        TdEvent
    },
    data() {
        return {
            allEvents: [],
            eventsLoading: true,
            currentPage: 0,
            pageSize: 9
        }
    },
    async mounted() {
        await this.getEvents();
    },
    methods: {

        async getEvents() {
            //console.log("Loading events")

            this.eventsLoading = true;
            var moreEvents = await eventsApi.getEvents(this.currentPage, this.pageSize);
            this.eventsLoading = false

            if (moreEvents.length > 0) {
                this.allEvents.push(...moreEvents)
                this.currentPage++;
            }

        }
    }

}
</script>

<style scoped>
h1 {
    text-align: center;
}

.events-grid {
    max-width: 80%;
}

.load-more-container {
    margin: 20px;
    text-align: center;
}
</style>