<template>
    <div class="assign-container">
        <h3> {{ $t('shoppingCart.assignTickets') }}</h3>
        <ui-icon-button icon="save" class="icon-save" :disabled="loading || !enableSave"
            @click.prevent="updateShoppingCartData"></ui-icon-button>
        <h5 v-show="mandatoryNames" class="red"> {{ $t('shoppingCart.allNamesRequired') }} </h5>
        <ui-textfield class="textfield" v-model="companyGroup"> {{ $t('shoppingCart.companyGroup')}}
        </ui-textfield>
        <ui-textfield :required="mandatoryNames" class="textfield" v-for="(value, index) in numNamesRequired"
            v-model="names[index]" :key="index">
            {{
            $t('shoppingCart.participant', [index + 1])}}
        </ui-textfield>
        <ui-button raised class="save" :disabled="loading || !enableSave" @click.prevent="updateShoppingCartData"> {{
        $t('alerts.saveButton')}}
        </ui-button>
        <ui-progress class="progress" v-show="loading" indeterminate active></ui-progress>
        <error-component v-show="errorSave" :message="errorMessage" />
    </div>
</template>

<script>
import { helpers } from 'balm-ui';
import ErrorComponent from '@/components/common/ErrorComponent.vue';
import { mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';
import validator from '@/utils/validator';
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';

export default {
    name: "AssignTickets",
    components: { ErrorComponent },
    props: {
        orderId: String,
        mandatoryNames: Boolean,
        numNamesRequired: Number,
        savedCompanyGroup: String,
        savedNames: String,
    },
    data() {
        return {
            companyGroup: this.savedCompanyGroup,
            names: Array(this.numNamesRequired),
            loading: false,
            errorSave: false,
            errorMessage: ""
        }
    },
    mounted() {
        if (!helpers.isEmpty(this.savedNames)) {
            this.names = [...this.savedNames.split(',')]
        }
    },
    computed: {
        enableSave() {
            for (var i = 0; i < this.names.length; i++) {
                if (helpers.isEmpty(this.names[i])) {
                    return false;
                }
            }

            return true;
        },
    },
    methods: {
        ...mapActions(useUserStore, ['updateShoppingCart']),
        checkValidNames() {
            for (var i = 0; i < this.names.length; i++) {
                var result = validator.validateName(this.names[i]);
                if (result === false) {
                    this.$alert({
                        message: this.$t('errors.invalidName'),
                        state: 'error',
                    });
                    return false;
                }
            }
            return true;
        },
        async updateShoppingCartData() {

            if (this.checkValidNames()) {

                window.grecaptcha.enterprise.ready(async () => {

                    this.loading = true;
                    this.errorSave = false;

                    try {

                        const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.SAVE_SHOPPING_CART });
                        await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.SAVE_SHOPPING_CART, token, process.env.VUE_APP_RECAPTCHA_KEY)

                        await this.updateShoppingCart({
                            orderId: this.orderId,
                            companyGroup: this.companyGroup,
                            names: this.names.join(',')
                        })
                        this.loading = false;
                        this.$toast(this.$t('alerts.savedSuccessfully'))

                    } catch (error) {
                        this.loading = false;
                        this.errorSave = true;
                        this.errorMessage = error.mensaje;
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
h3,
h5 {
    text-align: center;
}

.red {
    color: red;
}

.assign-container {
    background: white;
    padding: 20px;
    text-align: center;
}

.textfield {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.icon-save {
    color: #652d66;
    float: right;
}

.save {
    width: 60%;
    height: 50px;
    margin-top: 10px;
}

.progress {
    margin-top: 20px;
}
</style>