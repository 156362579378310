<template>
    <div>
        <h1>{{ $t('paymentStatus.title') }}</h1>
        <div v-shape="'8px'" class="container">
            <ui-icon size="48" :class="iconClass"> {{ icon }}</ui-icon>
            <h1 v-show="successPayment" class="event-title">{{ $t('paymentStatus.thanks') }}</h1>
            <h3 v-show="!loading" class="event-title"> {{ tdEvent.name }} </h3>
            <img v-show="!loading" class="event-image" :src="tdEvent.urlBanner" />
            <div v-if="successPayment" class="success-message">
                <div v-if="isTicket">
                    {{ $t('paymentStatus.successTickets') }}
                </div>
                <div v-else>
                    {{ $t('paymentStatus.successRegistries') }}
                </div>
            </div>
            <div v-else>
                {{ errorMessage }}
            </div>
            <img v-show="successPayment" src="@/assets/enviado.png" />
            <div v-if="successPayment">
                <ui-button raised class="action-button" @click.prevent="goHome"> {{
                    $t('paymentStatus.agreedButton') }} </ui-button>
                <div v-if="showOxxo" class="link-container">
                    <a class="link" :href="oxxoUrl" target="_blank"> {{
                        $t('paymentStatus.oxxoButton') }}
                    </a>
                </div>
                <div v-else-if="showBank" class="link-container">
                    <a class="link" :href="bankTransferUrl" target="_blank"> {{
                        $t('paymentStatus.bankTransferButton') }}
                    </a>
                </div>
            </div>
            <div v-else>
                <ui-button v-show="!loading" outlined class="action-button" @click.prevent="goToShoppingCart"> {{
                    $t('paymentStatus.tryAgainButton') }} </ui-button>
            </div>
        </div>
        <ui-progress class="progress" v-show="loading" indeterminate active></ui-progress>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </div>
</template>

<script>

import { useMainStore } from "@/stores/main";
import { useUserStore } from "@/stores/user";
import { mapActions } from "pinia";
import eventsApi from "@/api/EventsApi";
import { helpers } from "balm-ui";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { analytics, logEvent } from "@/utils/firebase"

export default {
    name: 'CheckPaymentStatusView',
    components: {
        ErrorComponent
    },
    async mounted() {
        this.validateQueryParams();
        await this.loadEvent()
        await this.checkInformation();
    },
    data() {
        return {
            tdEvent: Object,
            isTicket: false,
            errorLoad: false,
            loading: false,
            errorMessage: "",
            successPayment: false,
            iconClass: "neutral",
            showOxxo: false,
            oxxoUrl: "",
            showBank: false,
            bankTransferUrl: ""
        }
    },
    computed: {
        stripe() {
            if (this.tdEvent) {
                if (this.tdEvent.tdAccount === 'mx') {
                    if (helpers.isEmpty(this.$route.query.connect_account_id)) {
                        return window.Stripe(process.env.VUE_APP_STRIPE_KEY_MX)
                    } else {
                        return window.Stripe(process.env.VUE_APP_STRIPE_KEY_MX, {
                            stripeAccount: this.$route.query.connect_account_id
                        })
                    }
                } else if (this.tdEvent.tdAccount === 'us') {
                    if (helpers.isEmpty(this.$route.query.connect_account_id)) {
                        return window.Stripe(process.env.VUE_APP_STRIPE_KEY_US)
                    } else {
                        return window.Stripe(process.env.VUE_APP_STRIPE_KEY_US, {
                            stripeAccount: this.$route.query.connect_account_id
                        })
                    }
                } else {
                    return undefined;
                }
            }
            return undefined;
        },
        icon() {
            if (this.iconClass === 'success') {
                return "check_circle"
            } else if (this.iconClass === 'fail') {
                return "report"
            } else if (this.iconClass === 'info') {
                return "info"
            } else {
                return "sync"
            }
        }
    },
    methods: {
        ...mapActions(useUserStore, ['disableShoppingCart', 'deleteRegistryFormData']),
        validateQueryParams() {

            // These query params come from PaymentMethods component (after payment)

            if (helpers.isEmpty(this.$route.query.type)
                || helpers.isEmpty(this.$route.query.event_id)
                || helpers.isEmpty(this.$route.query.payment_intent)
                || helpers.isEmpty(this.$route.query.payment_intent_client_secret)) {

                this.$router.push("/not-found")
            }

            if (this.$route.query.type === 'TICKET' && helpers.isEmpty(this.$route.query.order_id)) {
                this.$router.push("/not-found")
            }
        },
        async loadEvent() {

            this.loading = true
            this.errorLoad = false

            try {
                this.tdEvent = await eventsApi.getEventById(this.$route.query.event_id)
                const store = useMainStore()
                store.$patch({ pageTitle: this.$t('paymentStatus.title') + ' - ' + this.tdEvent.name })
                this.loading = false;
            } catch (error) {
                this.errorLoad = true;
                this.loading = false;
                this.errorMessage = error.mensaje;
            }
        },
        async checkInformation() {

            this.isTicket = this.$route.query.type === 'TICKET'

            if (this.stripe) {

                this.loading = true

                const { paymentIntent, error } = await this.stripe.retrievePaymentIntent(
                    this.$route.query.payment_intent_client_secret
                );

                this.loading = false

                if (error) {

                    this.successPayment = false;
                    this.errorMessage = error.mensaje;

                } else if (paymentIntent) {

                    //console.log(paymentIntent)

                    if (paymentIntent.next_action) {

                        if (paymentIntent.next_action.oxxo_display_details
                            && paymentIntent.next_action.oxxo_display_details.hosted_voucher_url) {

                            this.successPayment = true;
                            this.showOxxo = true;
                            this.iconClass = "info";
                            this.oxxoUrl = paymentIntent.next_action.oxxo_display_details.hosted_voucher_url;

                            if (this.$route.query.type === 'TICKET') {
                                await this.invalidateShoppingCart();
                            } else if (this.$route.query.type === 'REGISTRY') {
                                this.deleteRegistryFormData();
                            }

                        } else if (paymentIntent.next_action.display_bank_transfer_instructions
                            && paymentIntent.next_action.display_bank_transfer_instructions.hosted_instructions_url) {

                            this.successPayment = true;
                            this.showBank = true;
                            this.iconClass = "info";
                            this.bankTransferUrl = paymentIntent.next_action.display_bank_transfer_instructions.hosted_instructions_url;

                            if (this.$route.query.type === 'TICKET') {
                                await this.invalidateShoppingCart();
                            } else if (this.$route.query.type === 'REGISTRY') {
                                this.deleteRegistryFormData();
                            }

                        }

                    } else {
                        switch (paymentIntent.status) {

                            case 'succeeded':

                                if (this.isTicket) {
                                    logEvent(analytics, 'payment_complete_shopping_cart', {
                                        event_name: this.tdEvent.name,
                                        payment_intent: paymentIntent.id,
                                        payment_type: 'web'
                                    })
                                } else {
                                    logEvent(analytics, 'payment_complete_register', {
                                        event_name: this.tdEvent.name,
                                        payment_intent: paymentIntent.id,
                                        payment_type: 'web'
                                    })
                                }

                                this.successPayment = true;
                                this.iconClass = "success";

                                if (this.$route.query.type === 'TICKET') {
                                    await this.invalidateShoppingCart();
                                } else if (this.$route.query.type === 'REGISTRY') {
                                    this.deleteRegistryFormData();
                                }

                                break;

                            case 'payment_failed':
                            case 'requires_payment_method':

                                if (this.isTicket) {
                                    logEvent(analytics, 'payment_failed_shopping_cart', {
                                        event_name: this.tdEvent.name,
                                        payment_intent: paymentIntent.id,
                                        payment_type: 'web'
                                    })
                                } else {
                                    logEvent(analytics, 'payment_failed_register', {
                                        event_name: this.tdEvent.name,
                                        payment_intent: paymentIntent.id,
                                        payment_type: 'web'
                                    })
                                }

                                // Redirect your user back to your payment page to attempt collecting
                                // payment again
                                this.successPayment = false;
                                this.errorMessage = this.$t('errors.invalidPaymentMethod')
                                this.iconClass = "fail";
                                break;

                            default:
                                this.successPayment = false;
                                this.errorMessage = this.$t('errors.generic')
                                this.iconClass = "fail";
                                break;
                        }
                    }
                }

            }

        },
        async invalidateShoppingCart() {
            this.loading = true
            this.errorLoad = false

            try {
                await this.disableShoppingCart(this.$route.query.order_id)
                this.loading = false;
            } catch (error) {
                this.errorLoad = true;
                this.loading = false;
                this.errorMessage = error.mensaje;
            }
        },

        goHome() {
            this.$router.push("/");
        },
        goToShoppingCart() {
            if (this.isTicket) {
                this.$router.push("/shopping-cart");
            } else {
                this.goHome();
            }
        },
    }
}

</script>

<style scoped>
h1,
h2,
h3 {
    text-align: center;
}

.container {
    width: 500px;
    background: white;
    margin: auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
}

.event-title {
    padding-top: 5px;
}

.event-image {
    width: 480px;
    height: 250px;
}

.progress {
    margin-top: 20px;
}

.success-message {
    text-align: justify;
    white-space: pre-wrap;
    font-size: large;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.success {
    color: #006600;
}

.fail {
    color: #d32f2f;
}

.info {
    color: #3156d5;
}

.neutral {
    color: #aaa;
}

.action-button {
    margin: 10px;
    width: 220px;
    height: 50px;
}

.link-container {
    margin-top: 20px;
}

.link {
    color: #652d66;
}
</style>