<template>
    <ui-card outlined>
        <div :class="[$tt('subtitle2'), 'card-heading']">
            <div class="left">
                {{ $t('purchases.orderNumber', [purchase.id]) }}
            </div>
            <div class="right">
                {{ formattedDate }}
            </div>
        </div>
        <ui-list-divider></ui-list-divider>

        <a v-ripple class="card-content">
            <h2 :class="[$tt('headline5'), 'card-content__title']">
                {{ purchase.eventName }}
            </h2>
        </a>
        <div class="info-content">
            <ui-grid>
                <ui-grid-cell columns="4">
                    <img :src="purchase.eventUrlLogo" />
                </ui-grid-cell>
                <ui-grid-cell columns="6">
                    <div>
                        <ui-icon class="name-quantity-item" size="36" outlined>{{ icon }}</ui-icon>
                        <div class="name-quantity-item">X</div>
                        <div class="name-quantity-item">{{ quantity }}</div>
                    </div>
                    <div>
                        <h3>{{ name }}</h3>
                    </div>
                    <div>
                        <div class="name-quantity-item"> {{ $t('shoppingCart.companyGroup') }}: </div>
                        <h4 class="name-quantity-item"> {{ purchase.companyGroup }}</h4>
                    </div>
                </ui-grid-cell>
                <ui-grid-cell columns="2">
                    <h3> {{ total }} </h3>
                    <template v-if="isCard">
                        <div>
                            <ui-icon class="name-quantity-item" size="36" outlined>payment</ui-icon>
                            <div class="name-quantity-item"> (xx {{ purchase.cardId }})</div>
                        </div>
                        <div v-if="withPlan" class="name-quantity-item plan"> {{ planDetail }}</div>
                    </template>
                    <template v-else-if="isBankTransfer">
                        <div>
                            <ui-icon class="name-quantity-item" size="36" outlined>account_balance</ui-icon>
                            <div class="name-quantity-item"> {{ $t('purchases.bankTransfer') }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <img src="@/assets/oxxo.png" width="60" />
                    </template>
                </ui-grid-cell>
            </ui-grid>
            <p class="disclaimer" v-show="purchase.isFuture"> {{ disclaimer }}</p>
        </div>
        <ui-list-divider></ui-list-divider>

        <ui-card-actions v-show="purchase.future">
            <ui-card-icons>
                <ui-button @click.prevent="goToEventDetail">
                    {{ $t('purchases.goToEvent') }}
                    <template #after>
                        <ui-icon>arrow_forward</ui-icon>
                    </template>
                </ui-button>
                <ui-button @click.prevent="downloadFile">
                    {{ $t('purchases.download') }}
                    <template #after>
                        <ui-icon>download</ui-icon>
                    </template>
                </ui-button>
            </ui-card-icons>
        </ui-card-actions>
        <ui-progress v-show="fileDownloading" indeterminate active></ui-progress>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </ui-card>
</template>

<script>

import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useMainStore } from "@/stores/main";
import dateUtils from '@/utils/dateUtils'
import numberUtils from '@/utils/numberUtils'
import ErrorComponent from '@/components/common/ErrorComponent.vue'
import paymentsApi from '@/api/PaymentsApi.js'
import { helpers } from 'balm-ui';

export default {
    name: "PurchaseItem",
    components: {
        ErrorComponent
    },
    props: {
        purchase: Object
    },
    data() {
        return {
            fileDownloading: false,
            errorLoad: false,
            errorMessage: ""
        };
    },
    computed: {
        ...mapState(useUserStore, ['userLogged']),
        ...mapState(useMainStore, ['defaultLanguage']),
        formattedDate() {
            const date = dateUtils.getLocaleDateFromString(this.purchase.paymentDate)
            return this.$t('purchases.paymentDate', [date[0].toUpperCase() + date.slice(1)]);
        },
        icon() {
            return this.purchase.type === 'TICKET' ? "confirmation_number" : "request_quote"
        },
        quantity() {
            return this.purchase.type === 'TICKET' ? this.purchase.ticketsNum : this.purchase.registersNum
        },
        name() {
            return this.purchase.type === 'TICKET' ? this.purchase.ticketName : ((this.defaultLanguage === 'es' ? this.purchase.registerTypeTitle : this.purchase.registerTypeTitleEn) + ": " + this.purchase.registerTypeName)
        },
        disclaimer() {
            return this.purchase.type === 'TICKET' ? this.$t('purchases.ticketsDisclaimer') : this.$t('purchases.registriesDisclaimer')
        },
        total() {
            return this.$t('purchases.purchaseTotal', [numberUtils.getCurrencyFromNumber(this.purchase.amount, this.purchase.currency)])
        },
        isCard() {
            return this.purchase.paymentSource === 'card';
        },
        isBankTransfer() {
            return this.purchase.paymentSource === 'customer_balance';
        },
        withPlan() {
            return !helpers.isEmpty(this.purchase.plan)
        },
        planDetail() {
            if (this.purchase.plan === 'msi') {
                return this.$t('plans.msi', [this.purchase.planCount])
            } else if (this.purchase.plan === 'fixed') {
                return this.$t('plans.fixed', [this.purchase.planCount])
            }
            return null
        }
    },
    methods: {
        goToEventDetail() {
            //console.log(this.withEvent.id)
            this.$router.push({
                name: 'eventDetail',
                params: {
                    eventId: this.purchase.eventId
                }
            });
        },
        async downloadFile() {
            try {

                this.fileDownloading = true;
                let fileName = this.purchase.type === 'TICKET' ? "t_" : "r_";
                fileName += this.purchase.id + ".pdf"

                const fileData = await paymentsApi.downloadPdf(this.userLogged.token, this.purchase)
                const link = document.createElement('a')
                link.href = URL.createObjectURL(fileData)
                link.target = "_blank"
                link.download = fileName
                link.click()
                URL.revokeObjectURL(link.href)
                this.fileDownloading = false;

            } catch (error) {
                this.errorLoad = true;
                this.fileDownloading = false;
                this.errorMessage = error.mensaje;
            }
        }
    }
}
</script>

<style scoped>
.card-heading {
    padding: 8px 16px;
}

.left {
    float: left;
}

.right {
    float: right;
    color: #652d66;
}

.card-content {
    padding: 16px;
    text-decoration: none;
    color: inherit;
}

.card-content__title {
    margin: 0 0 0 0;
}

.info-content {
    margin-bottom: 10px;
}

img {
    max-width: 180px;
}

.name-quantity-container {
    vertical-align: middle;
}

.name-quantity-item {
    display: inline;
    margin: 4px;
    vertical-align: middle;
}

.plan {
    font-size: small;
}

.disclaimer {
    margin-left: 8px;
    margin-right: 8px;
    text-align: justify;
    color: #666;
    font-size: smaller;
}
</style>