import { types } from "balm-ui"

export default {
    validatePassword(password) {
        return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/.test(password)
    },

    validateEmail(email) {
        return /[A-Z0-9a-z]([A-Z0-9a-z._%+-]{0,30}[A-Z0-9a-z])?@([A-Z0-9a-z]([A-Z0-9a-z-]{0,30}[A-Z0-9a-z])?\.){1,5}[A-Za-z]{2,8}/.test(email)
    },

    validateName(name) {
        return /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/.test(name)
    },

    validateInt(number) {
        return types.isNumber(number) && Number.isInteger(number);
    }
}