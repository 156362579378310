<template>
  <h1> {{ $t('forgoPassword.button') }} </h1>
  <ui-grid id="container">
    <ui-grid-cell columns="6">
      <forgot-password-form @update-code-validated="updateCodeValidated" @update-forgot-email="updateForgotEmail"
        @udpate-forgot-code="udpateForgotCode" />
    </ui-grid-cell>
    <ui-grid-cell columns="6">
      <reset-password-form v-show="isCodeValidated" :email="forgotemail" :token="forgotcode" />
    </ui-grid-cell>
  </ui-grid>
</template>
<script>

import ForgotPasswordForm from "@/components/user/ForgotPasswordForm.vue";
import ResetPasswordForm from "@/components/user/ResetPasswordForm.vue";
import { useMainStore } from "@/stores/main";
import commonsApi from "@/api/CommonsApi";
import { RECAPTCHA_ACTIONS } from '@/utils/constants';

export default {
  name: "ForgotPasswordPage",
  mounted() {

    if (window.grecaptcha) {

      window.grecaptcha.enterprise.ready(async () => {

        try {
          const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.PAGE_LOAD_FORGOT_PASSWORD });
          await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.PAGE_LOAD_FORGOT_PASSWORD, token, process.env.VUE_APP_RECAPTCHA_KEY)
        }
        catch (error) {
          console.error(error)
          this.$router.push("/not-found")
        }
      });
    }

    const mainStore = useMainStore();
    mainStore.pageTitle = this.$t("titles.forgotPassword");
  },
  components: {
    ForgotPasswordForm,
    ResetPasswordForm,
  },
  data() {
    return {
      isCodeValidated: false,
      forgotemail: "",
      forgotcode: "",
    };
  },
  methods: {
    updateCodeValidated(value) {
      this.isCodeValidated = value;
    },
    updateForgotEmail(value) {
      this.forgotemail = value;
    },
    udpateForgotCode(value) {
      this.forgotcode = value;
    },
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
}
</style>