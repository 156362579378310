<template>
  <div id="user-form-container">
    <ui-card id="card-container" v-shadow.transition="[2, 8]">
      <ui-form type="|" item-margin-bottom="10" action-align="center">
        <template #default="{ subitemClass, actionClass }">
          <ui-form-field :class="subitemClass">
            <h3>{{ $t('myAccount.personalData') }}</h3>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="email" outlined fullwidth v-model="formData.email"
              helper-text-id="email-helper-text" disabled>
              {{ $t('myAccount.email') }}
              <template #before>
                <ui-textfield-icon>email</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="email-helper-text" v-model:validMsg="validMsg.email">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield outlined fullwidth v-model="formData.nombres" helper-text-id="nombres-helper-text"
              :disabled="formLoading">
              {{ $t('myAccount.name') }}
              <template #before>
                <ui-textfield-icon>person</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="nombres-helper-text" v-model:validMsg="validMsg.nombres">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield outlined fullwidth v-model="formData.apellidos" helper-text-id="lastname-helper-text"
              :disabled="formLoading">
              {{ $t('myAccount.lastname') }}
              <template #before>
                <ui-textfield-icon>person</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="lastname-helper-text" v-model:validMsg="validMsg.apellidos">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-divider></ui-divider>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <h3>{{ $t('myAccount.advancedSettings') }}</h3>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <label>{{ $t('myAccount.currency') }}</label>
            <ui-select fullwidth v-model="preferredCurrency" :options="currencyOptions"></ui-select>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <label>{{ $t('myAccount.changePassword') }}</label>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-switch v-model="formData.cambiarPwd"></ui-switch>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-helper-text"
              v-model="formData.passwordActual" v-show="formData.cambiarPwd" :disabled="formLoading"
              :attrs="{ autocomplete: 'current-password' }">
              {{ $t('myAccount.currentPassword') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-helper-text" v-model:validMsg="validMsg.passwordActual"
              v-show="formData.cambiarPwd"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-nuevo-helper-text"
              v-model="formData.passwordNuevo" v-show="formData.cambiarPwd" :disabled="formLoading">
              {{ $t('myAccount.newPassword') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-nuevo-helper-text" v-model:validMsg="validMsg.passwordNuevo"
              v-show="formData.cambiarPwd"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-confirmar-helper-text"
              v-model="formData.passwordConfirmar" v-show="formData.cambiarPwd" :disabled="formLoading">
              {{ $t('myAccount.passwordConfirm') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-confirmar-helper-text" v-model:validMsg="validMsg.passwordConfirmar"
              v-show="formData.cambiarPwd">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="actionClass">
            <ui-button raised native-type="submit" @click.prevent="updateUser" @keyup.enter="updateUser"
              :disabled="formLoading">{{ $t('alerts.updateButton') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">save_alt</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-divider></ui-divider>
          </ui-form-field>
          <ui-form-field :class="actionClass">
            <ui-button class="destroy" native-type="reset" @click.prevent="openConfirmDialog" :disabled="formLoading">
              {{ $t('myAccount.deleteAccount') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">delete</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
      <error-component v-show="errorUpdate" :message="errorMessage" />
    </ui-card>
  </div>
  <ui-dialog v-model="openConfirmDeletion" fullscreen>
    <ui-dialog-title>{{ $t('alerts.danger') }} </ui-dialog-title>
    <ui-dialog-content>
      <p>
        {{ $t('myAccount.deleteAccoountDisclosure1') }}
        <br />
        <br />
        <strong>{{ $t('myAccount.deleteAccoountDisclosure2') }}</strong>
      </p>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button class="destroy" @click.prevent="deleteAccount">{{ $t('alerts.deleteButton') }}</ui-button>
    </ui-dialog-actions>
  </ui-dialog>
</template>
<script>
import { useUserStore } from "@/stores/user";
import { useValidator, helpers } from "balm-ui";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import i18n from "@/i18n";
import userApi from "@/api/UserApi";
import currencies from "@/utils/currencies";

const validations = [
  {
    key: "email",
    label: "Email",
    validator: "required",
  },
  {
    key: "nombres",
    label: i18n.global.t('myAccount.name'),
    validator: "required",
  },
  {
    key: "apellidos",
    label: i18n.global.t('myAccount.lastname'),
    validator: "required",
  },
  {
    key: "passwordActual",
    label: i18n.global.t('myAccount.currentPassword'),
    validator: "requiredIfEnabled",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.cambiarPwd && helpers.isEmpty(value)) return false;
        return true;
      },
      message: "%s " + i18n.global.t('errors.required'),
    },
  },
  {
    key: "passwordNuevo",
    label: i18n.global.t('myAccount.newPassword'),
    validator: "requiredIfEnabled, passConditions",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.cambiarPwd && helpers.isEmpty(value)) return false;
        return true;
      },
      message: "%s " + i18n.global.t('errors.required'),
    },
    passConditions: {
      validate(value, data) {
        if (data.cambiarPwd) {
          var result = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/.test(value);
          return result;
        }
        return true;
      },
      message:
        "%s " + i18n.global.t('errors.invalidPassword'),
    },
  },
  {
    key: "passwordConfirmar",
    label: i18n.global.t('myAccount.passwordConfirm'),
    validator: "requiredIfEnabled, repasswordRule",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.cambiarPwd && helpers.isEmpty(value)) return false;
        return true;
      },
      message: "%s " + i18n.global.t('errors.required'),
    },
    repasswordRule: {
      validate(value, data) {
        if (data.cambiarPwd && value !== data.passwordNuevo) return false;
        return true;
      },
      message: i18n.global.t('errors.mismatchPassword'),
    },
  },
];

export default {
  name: "UserData",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      balmUI: useValidator(),
      validations,
      validMsg: {},
      formData: {
        email: "",
        nombres: "",
        apellidos: "",
        cambiarPwd: false,
        passwordActual: "",
        passwordNuevo: "",
        passwordConfirmar: "",
      },
      currencyOptions: [...currencies.getSupportedCurrencies()],
      preferredCurrency: 0,
      formLoading: false,
      errorUpdate: false,
      errorMessage: "",
      openConfirmDeletion: false,
    };
  },
  async mounted() {
    await this.getUserData();
  },
  methods: {
    async getUserData() {

      //console.log("getting user data")

      const userStore = useUserStore();

      this.formLoading = true;
      try {
        const payload = {
          token: userStore.userLogged.token,
        };

        const userData = await userApi.getUserData(payload);
        this.formData.email = userData.correo;
        this.formData.nombres = userData.nombre;
        this.formData.apellidos = userData.apellidos;
        this.preferredCurrency = userData.preferredCurrency === 'mxn' ? 0 : 1
        this.formLoading = false;
      } catch (error) {
        this.formLoading = false;
        this.errorMessage = error.mensaje;
        this.errorUpdate = true;
      }
    },
    async updateUser() {
      const userStore = useUserStore();
      let result = this.balmUI.validate(this.formData);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {
        this.formLoading = true;
        this.errorUpdate = false;

        try {

          let success = true;

          if (this.formData.cambiarPwd) {
            success = await userApi.checkPassword({
              correo: userStore.userLogged.correo,
              password: this.formData.passwordActual,
              token: userStore.userLogged.token,
            });
          }

          if (success) {
            await userStore.updateUser({
              preferredCurrency: this.preferredCurrency === 0 ? 'mxn' : 'usd',
              correo: userStore.userLogged.correo,
              nombre: this.formData.nombres,
              password: this.formData.passwordNuevo,
              apellidos: this.formData.apellidos,
              token: userStore.userLogged.token,
            });
            this.formLoading = false;
            this.$toast(this.$t('myAccount.successUpdate'));

            if (this.formData.cambiarPwd) {
              this.formData.cambiarPwd = false
              this.formData.passwordActual = ""
              this.formData.passwordNuevo = ""
              this.formData.passwordConfirmar = ""
            }
          }
        } catch (error) {
          this.formLoading = false;
          this.errorMessage = error.mensaje;
          this.errorUpdate = true;
          //console.log(error);
        }
      }
    },
    openConfirmDialog() {
      this.openConfirmDeletion = true;
    },
    async deleteAccount() {
      //console.log("Delete");

      this.openConfirmDeletion = false;
      this.formLoading = true;
      this.errorUpdate = false;

      const userStore = useUserStore();

      try {

        await userStore.deleteUser({
          token: userStore.userLogged.token,
        });

        this.$toast(this.$t('myAccount.successDeletion'));

        this.$router.push("/");

      } catch (error) {

        //console.log(error);
        this.formLoading = false;
        this.errorMessage = error.mensaje;
        this.errorUpdate = true;

      }
    },
  },
};
</script>
<style scoped>
#user-form-container {
  max-width: 480px;
  margin: auto;
}

#card-container {
  padding: 1.2em;
  margin-top: 3em;
}

button.destroy {
  color: #D32F2F;
}
</style>