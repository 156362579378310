<template>
    <ui-card outlined>
        <ui-card-content>
            <div class="container">
                <h2 class="container-element">{{ $t('events.location') }}</h2>
                <p class="container-element" v-if="location">
                    {{location.name}}
                </p>
                <iframe class="map" v-if="location" frameborder="0" style="border:0"
                    referrerpolicy="no-referrer-when-downgrade" :src="mapUrl" allowfullscreen>
                </iframe>
            </div>
        </ui-card-content>
    </ui-card>
</template>

<script>

export default {
    name: "TdLocation",
    props: {
        location: Object
    },
    computed: {
        mapUrl() {
            if (this.location) {
                return `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}&q=${encodeURIComponent(this.location.address)}&center=${this.location.latitude},${this.location.longitude}&zoom=16`
            }
            return ""
        }
    }
}
</script>

<style scoped>
.container {
    position: relative;
    min-height: 450px;
}

.container-element {
    margin: 10px 30px;
}

.map {
    width: 100%;
    height: 390px;
}
</style>