<template>
    <div>
        <video ref="videoPlayer" class="video-js"></video>
    </div>
</template>
  
  <script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css'
  require('videojs-youtube'); // add any plugin first and then
  
  export default {
      name: 'VideoPlayer',
      props: {
          options: {
              type: Object,
              default() {
                  return {};
              }
          }
      },
      data() {
          return {
              player: null
          }
      },
      mounted() {
          this.player = videojs(this.$refs.videoPlayer, this.options, () => {
              //this.player.log('onPlayerReady', this);
          });
      },
      beforeUnmount() {
          if (this.player) {
              this.player.dispose();
          }
      },
  }
  </script>