<template>
    <div v-shape class="container">
        <ui-grid>
            <ui-grid-cell columns="1" class="grid-cell-centered">
                <ui-icon-button :icon="toogleIcon" @click.prevent="toggleDescription">
                </ui-icon-button>
            </ui-grid-cell>
            <ui-grid-cell columns="2">
                <img :src="imageUrl" />
            </ui-grid-cell>
            <ui-grid-cell columns="3" class="grid-cell-centered">
                <p class="ticket-name">
                    {{ ticket.ticketName }}
                </p>
            </ui-grid-cell>
            <ui-grid-cell columns="2" class="grid-cell-centered">
                <ui-select v-model="quantity" :options="ticketQuantityOptions" class="quantity-select">
                    {{ $t('events.ticketQuantity') }}
                </ui-select>
            </ui-grid-cell>
            <ui-grid-cell columns="2" class="grid-cell-centered">
                <div>
                    <div>
                        <h2> {{ ticketTotal }} </h2>
                    </div>
                    <preview-total-details :is-ticket="true" :price="ticket.ticketPrice" :commission="ticket.comision"
                        :currency="ticket.currency">
                    </preview-total-details>

                </div>
            </ui-grid-cell>
            <ui-grid-cell columns="2" class="grid-cell-right">
                <div>
                    <div>
                        <ui-button class="select" @click.prevent="goToShoppingCart">
                            {{ $t('common.select') }}
                        </ui-button>
                    </div>
                    <label v-show="ticket.msiEnabled" class="msi">
                        {{ $t('plans.msiAvailable') }}
                    </label>
                </div>
            </ui-grid-cell>
        </ui-grid>
        <div class="ticket-description" v-show="descriptionVisible">
            <p>
                {{ $t('events.ticketIncludes') }}
            </p>
            <p v-html="ticket.ticketDescription">
            </p>
        </div>
    </div>
</template>

<script>
import { SELECT_VALUES } from '@/utils/constants'
import numberUtils from '@/utils/numberUtils'
import PreviewTotalDetails from '@/components/common/PreviewTotalDetails.vue'

const ticketQuantityOptions = [...SELECT_VALUES.TICKETS_QUANTITY]

export default {
    name: "TicketSelection",
    props: {
        imageUrl: String,
        ticket: Object,
        eventId: String
    },
    components: {
        PreviewTotalDetails
    },
    data() {
        return {
            descriptionVisible: false,
            ticketQuantityOptions,
            quantity: 1,
        }
    },
    computed: {
        toogleIcon() {
            return this.descriptionVisible ? "remove" : "add"
        },
        ticketTotal() {
            let price = this.ticket.ticketPrice
            let comision = this.ticket.comision
            let total = (price + comision) * this.quantity
            let totalStr = numberUtils.getCurrencyFromNumber(total, this.ticket.currency)
            return this.$t('events.ticketTotal', [totalStr])
        },
    },
    methods: {
        toggleDescription() {
            this.descriptionVisible = !this.descriptionVisible
        },
        goToShoppingCart() {
            this.$router.push(`/shopping-cart?ticketId=${this.ticket.ticketId}&numTickets=${this.quantity}&eventId=${this.eventId}`)
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 95%;
    margin: auto;
    background: white;
}

.container img {
    width: 140px;
    height: 130px;
}

.grid-cell-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-cell-right {
    display: flex;
    justify-content: right;
    align-items: center;
}

.ticket-name {
    font-weight: bolder;
    font-size: x-large;
}

.quantity-select {
    width: 120px;
}

button.select {
    color: white;
    background-color: #3156d5;
    width: 185px;
}

.ticket-description {
    padding: 20px;
}

.msi {
    font-size: small;
    font-weight: bold;
    color: #3156d5;
}
</style>
